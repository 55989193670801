import { gql } from "@apollo/client";
import { CondensedTableCurrentLevelCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { MEDIUM_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableCurrentLevelCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const label =
    employee.subject.activeEmployment?.position?.level.toString() ?? "-";

  return (
    <AssembleTruncatedTypography align="center" title={label}>
      {label}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentLevelCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentLevelCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          position {
            id
            level
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedPosition {
            id
          }
        }
      }
    }
  `,
  position: gql`
    fragment CondensedTableCurrentLevelCell_position on Position {
      id
      level
    }
  `,
};

CondensedTableCurrentLevelCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_LEVEL
);
CondensedTableCurrentLevelCell2.id = ColumnIds.CURRENT_LEVEL;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentLevelCell2,
  Header: CondensedTableCurrentLevelCell2.Header,
  id: CondensedTableCurrentLevelCell2.id,
  width: MEDIUM_COL_WIDTH,
};
CondensedTableCurrentLevelCell2.column = column;
