import { plainDateToUTCString } from "@asmbl/shared/time";
import { useSnackbar } from "notistack";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  ProgressTableHeader_phase as CurrentPhase,
  ProgressPageLoadingBoundary_participant as Participant,
  ProgressTable_phase as Phase,
  ProgressTable_phaseAssignment as PhaseAssignment,
  PhaseTimelineStatus,
} from "src/__generated__/graphql";
import { useUpdateCompCyclePhases } from "src/mutations/CompCyclePhase";

export type ColumnCell = PhaseAssignment & ReviewerStats;
export type ReviewerMap = Map<number, ColumnCell>;
export type ReviewerStats = {
  requested: number;
  completed: number;
  isSkipped: boolean;
  skippedName: string | null;
  reviewerName: string | null;
  rowSpan: number;
};

type CompletionFilter = "All" | "Submitted" | "Incomplete" | "Skipped" | null;

type Props = {
  compCycleId: number;
  phases: Phase[];
  currentPhase: CurrentPhase | null;
  hoveredEmployeeIds: Set<number> | null;
  handleCellClick: (ids: number[] | null) => void;
  showSubmissionFlow: boolean;
  setShowSubmissionFlow: () => void;
  currentReviewers: Set<number>;
  reviewerMap: ReviewerMap;
  searchQuery: string;
  completionFilter: CompletionFilter;
  setSearchQuery: (displayName: string) => void;
  setCompletionFilter: (filter: CompletionFilter) => void;
  isCellHighlighted: (id: number | null) => boolean;
  handleHover: (ids: number[] | null) => void;
  handleAdvanceToNextPhase: () => Promise<boolean>;
};

export const ProgressPageContext = createContext<Props>({
  compCycleId: 0,
  phases: [],
  currentPhase: null,
  hoveredEmployeeIds: null,
  handleCellClick: () => {
    // empty default
  },
  showSubmissionFlow: false,
  setShowSubmissionFlow: () => {
    // empty default
  },
  currentReviewers: new Set(),
  reviewerMap: new Map(),
  searchQuery: "",
  completionFilter: "All",
  setSearchQuery: () => {
    // empty default
  },
  setCompletionFilter: () => {
    // empty default
  },
  isCellHighlighted: () => false,
  handleHover: () => {
    // empty default
  },
  handleAdvanceToNextPhase: async () => await Promise.resolve(false),
});

export const ProgressPageContextProvider = ({
  children,
  reviewers,
  participants,
  phases,
  currentPhase,
  compCycleId,
}: {
  children: ReactNode;
  reviewers: PhaseAssignment[];
  participants: Participant[];
  phases: Phase[];
  currentPhase: CurrentPhase | null;
  compCycleId: number;
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  // Toggle Interactions
  const [showSubmissionFlow, setShowSubmissionFlow] = useState(false);
  const [reviewerHighlighted, setReviewerHighlighted] =
    useState<boolean>(false);
  const [hoveredEmployeeIds, setHoveredEmployeeIds] =
    useState<Set<number> | null>(null);

  // Button Interactions
  const [currPhase, setCurrPhase] = useState<CurrentPhase | null>(currentPhase);
  const [currPhases, setCurrPhases] = useState<Phase[]>(phases);

  // Filtering
  const [completionFilter, setCompletionFilter] =
    useState<CompletionFilter>("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredReviewers, setFilteredReviewers] = useState(reviewers);

  const reviewerMap = useMemo(() => {
    const map = new Map<number, PhaseAssignment & ReviewerStats>();
    reviewers.forEach((reviewer) => {
      map.set(reviewer.employee.id, {
        ...reviewer,
        requested: reviewer.recommendees.length + reviewer.reviewees.length,
        isSkipped: false,
        completed: 0,
        skippedName: null,
        reviewerName: reviewer.employee.displayName.split(" ").at(0) ?? null,
        // this gets updated in the getOrderPhaseColumns util for ProgressTable
        rowSpan: 1,
      });
    });
    // go through participants and update reviewer stats
    participants.forEach((participant) => {
      const compRec = participant.compRecommendation;
      const phaseTimeline = participant.phaseTimeline;
      phaseTimeline.forEach((phaseAssignment, index) => {
        const reviewer = map.get(phaseAssignment.assigneeId);
        const status = phaseAssignment.status;
        // if we're in the participant's first phase, the reviewer made intial rec and not a review
        if (reviewer) {
          // a reviewer is only assigned to one phase, but if they are skipped for any participant, we want to note that
          // we are not using SKIPPED status directly as it's not being calculated correctly
          const isSkipped = reviewer.isSkipped;
          let skippedName: string | null = reviewer.skippedName;
          if (!isSkipped) {
            // index === 0 means curr reviewer should have left initial rec
            if (
              index === 0 &&
              compRec?.latestSubmittedItems.at(0)?.author.id !==
                reviewer.employee.userId
            ) {
              skippedName =
                compRec?.latestSubmittedItems.at(0)?.author.name ?? skippedName;
            }
          } else if (
            skippedName === null &&
            compRec?.latestSubmittedReviews.at(0)?.author.id !==
              reviewer.employee.userId
          ) {
            skippedName =
              compRec?.latestSubmittedReviews.at(0)?.author.name ?? skippedName;
          }

          map.set(phaseAssignment.assigneeId, {
            ...reviewer,
            completed:
              reviewer.completed +
              (status === PhaseTimelineStatus.REVIEWED ? 1 : 0),
            isSkipped: skippedName !== null,
            skippedName,
          });
        }
      });
    });
    return map;
  }, [participants, reviewers]);

  const handleFilter = useCallback(
    (filter: CompletionFilter, query = searchQuery) => {
      setCompletionFilter(filter);
      const reviewersWithFilter =
        filter === "All"
          ? reviewers
          : reviewers.filter((rev) => {
              const reviewer = reviewerMap.get(rev.employee.id);
              const isCompleted = Boolean(
                reviewer && reviewer.completed === reviewer.requested
              );
              const isSkipped = Boolean(reviewer?.isSkipped);
              switch (filter) {
                case "Submitted":
                  return isCompleted;
                case "Skipped":
                  return isSkipped;
                case "Incomplete":
                  return !isCompleted;
                default:
                  return true;
              }
            });

      setFilteredReviewers(filterReviewersBySearch(query, reviewersWithFilter));
    },
    [reviewerMap, reviewers, searchQuery]
  );

  const handleSearch = useCallback(
    (searchQuery: string) => {
      setSearchQuery(searchQuery);

      handleFilter(completionFilter, searchQuery);
    },
    [completionFilter, handleFilter]
  );

  const handleToggle = useCallback(() => {
    setShowSubmissionFlow((prev) => !prev);
    if (showSubmissionFlow === false) {
      setHoveredEmployeeIds(null);
      setReviewerHighlighted(false);
    }
  }, [showSubmissionFlow]);

  const handleCellClick = useCallback(
    (ids: number[] | null) => {
      if (showSubmissionFlow) {
        const reviewerIds = ids?.filter((id) => reviewerMap.has(id));
        if (
          reviewerHighlighted &&
          hoveredEmployeeIds &&
          Array.from(hoveredEmployeeIds.values()).at(0) === ids?.at(0)
        ) {
          setHoveredEmployeeIds(null);
          setReviewerHighlighted(false);
        } else {
          setHoveredEmployeeIds(new Set(reviewerIds));
          setReviewerHighlighted(true);
        }
      } else {
        setHoveredEmployeeIds(null);
        setReviewerHighlighted(false);
      }
    },
    [hoveredEmployeeIds, reviewerHighlighted, reviewerMap, showSubmissionFlow]
  );

  const isCellHighlighted = useCallback(
    (id: number | null) => {
      if (id === null) return false;
      return showSubmissionFlow ? hoveredEmployeeIds?.has(id) ?? false : false;
    },
    [hoveredEmployeeIds, showSubmissionFlow]
  );

  const handleHover = useCallback(
    (ids: number[] | null) => {
      const reviewerIds = ids?.filter((id) => reviewerMap.has(id));
      if (showSubmissionFlow && !reviewerHighlighted) {
        setHoveredEmployeeIds(ids ? new Set(reviewerIds) : null);
      }
    },
    [reviewerHighlighted, reviewerMap, showSubmissionFlow]
  );

  const getCurrentReviewers = useCallback(() => {
    return new Set(filteredReviewers.map((reviewer) => reviewer.employee.id));
  }, [filteredReviewers])();

  const updateCompCyclePhases = useUpdateCompCyclePhases();

  const handleAdvanceToNextPhase = async () => {
    // button should be disabled, but if not -
    // return if the current phase is the last phase or if there are no phases
    const noAdvancement =
      (currentPhase?.id != null &&
        currentPhase.id === phases[phases.length - 1]?.id) ||
      phases.length === 0;
    if (noAdvancement) {
      enqueueSnackbar("No comp cycle phase to advance to.", {
        variant: "warning",
      });
      return false;
    }

    enqueueSnackbar("Advancing to next phase...", {
      variant: "info",
    });

    const updatedPhases = phases.map((phase) => ({
      id: phase.id,
      startDate: phase.startDate,
    }));
    let nextPhaseIndex = 0;
    const newStartDate = plainDateToUTCString(new Date());
    // phases haven't started or cycle is over
    if (currentPhase?.id === undefined) {
      const nextPhase = updatedPhases[nextPhaseIndex];
      // if phase 1 start date is in past, cycle has ended
      if (new Date(nextPhase.startDate).getTime() < new Date().getTime()) {
        return false;
      }
      updatedPhases[nextPhaseIndex] = {
        ...nextPhase,
        startDate: newStartDate,
      };
    } else {
      nextPhaseIndex =
        updatedPhases.findIndex((phase) => phase.id === currentPhase.id) + 1;
      const nextPhase = updatedPhases[nextPhaseIndex];
      updatedPhases[nextPhaseIndex] = {
        ...nextPhase,
        startDate: newStartDate,
      };
    }
    try {
      // the mutation returns "CompCyclePhase" type instead of "CompCyclePhase2"
      // so we have to cast the return value and track the phases in context
      const newPhases = await updateCompCyclePhases(compCycleId, updatedPhases);
      const newCurrPhase = newPhases.find(
        (phase) => phase.id === updatedPhases[nextPhaseIndex].id
      );
      if (newCurrPhase) {
        setCurrPhase({
          ...newCurrPhase,
          __typename: "CompCyclePhase2",
        });
        setCurrPhases((currPhases) =>
          currPhases.map((phase) => {
            const newPhase = newPhases.find((p) => p.id === phase.id);
            return {
              ...phase,
              startDate: newPhase?.startDate ?? phase.startDate,
            };
          })
        );
        enqueueSnackbar("Successfully advanced to the next phase.", {
          variant: "success",
        });
        return true;
      } else {
        enqueueSnackbar(
          "Failed to advance to the next phase. Please try again.",
          {
            variant: "error",
          }
        );
        return false;
      }
    } catch (e) {
      enqueueSnackbar(
        "Failed to advance to the next phase. Please try again.",
        {
          variant: "error",
        }
      );
      return false;
    }
  };

  return (
    <ProgressPageContext.Provider
      value={{
        compCycleId,
        phases: currPhases,
        currentPhase: currPhase,
        showSubmissionFlow,
        setShowSubmissionFlow: handleToggle,
        currentReviewers: getCurrentReviewers,
        reviewerMap,
        searchQuery,
        completionFilter,
        setSearchQuery: handleSearch,
        setCompletionFilter: handleFilter,
        hoveredEmployeeIds,
        handleCellClick,
        isCellHighlighted,
        handleHover,
        handleAdvanceToNextPhase,
      }}
    >
      {children}
    </ProgressPageContext.Provider>
  );
};

export const useProgressPageContext = (): Props => {
  return useContext(ProgressPageContext);
};
function filterReviewersBySearch(
  searchQuery: string,
  filteredReviewers: PhaseAssignment[]
): PhaseAssignment[] {
  if (searchQuery.length === 0) return filteredReviewers;
  const search = searchQuery.toLowerCase().trim();
  return filteredReviewers.filter((reviewer) => {
    const reviewerName = reviewer.employee.displayName.toLowerCase().split(" ");
    return reviewerName.some((name) => name.startsWith(search));
  });
}
