import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Money, zero } from "@asmbl/shared/money";
import {
  CondensedTableNewTargetCommissionCell2_participant as Participant,
  RecItemType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

function getCommission(employee: Participant): Money | undefined {
  return (
    employee.compRecommendation?.latestSubmittedItems.find(
      (item) => item.recommendationType === RecItemType.TARGET_COMMISSION
    )?.recommendedCashValue ?? undefined
  );
}

export function CondensedTableNewTargetCommissionCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();

  const commission = getCommission(employee);

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        commission ??
          zero(
            (employee.subject.activeEmployment?.payCurrencyCode ??
              defaultCurrencyCode) as CurrencyCode
          )
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewTargetCommissionCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewTargetCommissionCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          payCurrencyCode
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableNewTargetCommissionCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_TARGET_COMMISSION
);
CondensedTableNewTargetCommissionCell2.id = ColumnIds.NEW_TARGET_COMMISSION;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewTargetCommissionCell2,
  Header: CondensedTableNewTargetCommissionCell2.Header,
  id: CondensedTableNewTargetCommissionCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableNewTargetCommissionCell2.column = column;
