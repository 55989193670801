import { ApolloClient, ApolloProvider, from } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createHttpLink } from "@apollo/client/link/http";
import { AuthError, AuthErrorMessage } from "@asmbl/shared/auth";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Button, styled } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Theme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import { GraphQLError } from "graphql";
import {
  MaterialDesignContent,
  SnackbarProvider,
  useSnackbar,
} from "notistack";
import { createRef, useEffect, useMemo } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import {
  Navigate,
  Route,
  Routes,
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { Redirect } from "src/components/Redirect";
import { Noun } from "../__generated__/graphql";
import { useInitTrack } from "../analytics";
import { AccessBoundary } from "../components/AccessBoundary";
import { AuthProvider, useAuth } from "../components/Auth/AuthContext";
import {
  BearerTokenProvider,
  useBearerToken,
} from "../components/Auth/BearerContext";
import { CompStructureProvider } from "../components/CompStructureContext";
import { CurrenciesProvider } from "../components/CurrenciesContext";
import { CustomerDataWarningBanner } from "../components/CustomerDataWarningBanner";
import {
  NoFeatureAccess,
  NoOrgAccess,
} from "../components/EmptyState/EmptyState";
import { NoRouteFound } from "../components/EmptyState/NoRouteFound";
import {
  FeatureFlagProvider,
  PreviewFeature,
} from "../components/FeatureContext";
import { GlobalWarningBanner } from "../components/GlobalWarningBanner";
import { NavigationBar } from "../components/Layout/NavigationBar/NavigationBar";
import { LocationsProvider } from "../components/LocationsContext";
import { OfferEditLoadingBoundary } from "../components/OfferGeneration/OfferEditLoadingBoundary";
import { OfferNewLoadingBoundary } from "../components/OfferGeneration/OfferNewLoadingBoundary";
import { RefreshPrompt } from "../components/RefreshPrompt";
import { EditBenefitsPackagePage } from "../components/Settings/Benefits/EditBenefitsPackagePage";
import { UserAccessControlLoadingBoundary } from "../components/Settings/UserAccessControl/UserAccessControlLoadingBoundary";
import { UserInvitationAccessControlLoadingBoundary } from "../components/Settings/UserAccessControl/UserInvitationAccessControlLoadingBoundary";
import {
  envName,
  graphqlURL,
  intercomAppId,
  segmentWriteKey,
  sentryDSN,
} from "../env";
import "../firebase";
import { GRAY_6, GREEN_2, theme } from "../theme";
import { AuthOrRedirect } from "./AuthOrRedirect";
import {
  Authentication,
  AuthenticationFormState,
  displaySsoOnlyToast,
} from "./Authentication/Authentication";
import { AuthenticationRouter } from "./Authentication/AuthenticationRouter";
import { CompCyclesRouter } from "./CompCycle/CompCyclesRouter";
import { InsightsRouter } from "./Compare/InsightsRouter";
import { DepartmentDetail } from "./DepartmentDetail/DepartmentDetail";
import { Foundations } from "./Foundations";
import { Home } from "./Home";
import { LadderDetailLoadingBoundary } from "./LadderDetail/LadderDetailLoadingBoundary";
import { OfferDisplay } from "./OfferDisplay/OfferDisplay";
import { OfferList } from "./OfferList/OfferList";
import { OfferOutcomeCalculator } from "./OfferOutcomeCalculator";
import { PayEquity } from "./PayEquity/PayEquity";
import { PeoplePageRouter } from "./People/PeoplePageRouter";
import { Philosophy } from "./Philosophy/Philosophy";
import { PortalRouter } from "./Portal/PortalRouter";
import { PositionDetailEditLoadingBoundary } from "./PositionDetail/PositionDetailEditLoadingBoundary";
import { PositionDetailLoadingBoundary } from "./PositionDetail/PositionDetailLoadingBoundary";
import { Profile } from "./Profile";
import { Settings } from "./Settings";
import { UploadHRIS } from "./Settings/UploadHRIS";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: GREEN_2,
  },
}));

export const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<App />} ErrorBoundary={Sentry.ErrorBoundary} />
  )
);

function App(): JSX.Element {
  initializeSentryReporting();

  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: string | number) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <Sentry.ErrorBoundary showDialog>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          ref={notistackRef}
          action={(key) => (
            <Button
              size="small"
              variant="contained"
              onClick={onClickDismiss(key)}
            >
              Dismiss
            </Button>
          )}
          Components={{
            success: StyledMaterialDesignContent,
          }}
        >
          <CookiesProvider>
            <BearerTokenProvider>
              <ApolloApp />
            </BearerTokenProvider>
          </CookiesProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

interface FetchOptions {
  body: string;
  headers: {
    "Operation-Name"?: string;
    tracing?: string;
  };
}

interface RequestJson {
  operationName: string;
}

function ApolloApp() {
  const { enqueueSnackbar } = useSnackbar();
  const { getToken } = useBearerToken();
  const environment = envName();
  const [cookies] = useCookies(["logging"]);

  const client = useMemo(() => {
    const customFetch = (uri: URL | RequestInfo, options: FetchOptions) => {
      try {
        options.headers["Operation-Name"] = (
          JSON.parse(options.body) as RequestJson
        ).operationName;
        switch (options.headers["Operation-Name"]) {
          case "RefreshQuery":
          case "GetGlobalWarningInfo":
            options.headers.tracing = "disable";
            break;
          default:
            options.headers.tracing = "enable";
        }
      } catch (error) {
        options.headers.tracing = "enable";
      }
      return fetch(uri, options);
    };
    const httpLink = createHttpLink({
      uri: graphqlURL(),
      fetch: customFetch,
    });
    const cache = new InMemoryCache({
      typePolicies: {
        AdjustedCashBandPoint: {
          keyFields: false,
        },
        AdjustedEquityBandPoint: {
          keyFields: false,
        },
        AuthSubject: {
          merge: true,
        },
        CashCompensation: {
          keyFields: false,
        },
        CompCycleBudget: {
          keyFields: ["compCycleId", "employeeId"],
        },
        CompCycleBudgetDraft: {
          keyFields: ["compCycleId", "employeeId"],
        },
        CompCyclePhaseAssignment: {
          keyFields: ["id", "phaseId", "status"],
        },
        CompCyclePhaseAssignment2: {
          keyFields: ["id", "phaseId", "status"],
        },
        CompCycleParticipant: {
          keyFields: ["compCycleId", "subjectId"],
        },
        CompCyclePhaseAssignmentSubjectParticipant: {
          merge: true,
        },
        CompRecommendation: {
          keyFields: ["compCycleId", "subjectId"],
        },
        CompRecommendation2: {
          keyFields: ["compCycleId", "subjectId"],
        },
        CompStructure: {
          fields: {
            cashBandTypes: {
              merge: false,
            },
          },
        },
        Employment: {
          keyFields: ["id"],
        },
        LocationGroup: {
          fields: {
            locationAdjustments: {
              merge: false,
            },
          },
        },
        MinimalEmployee: {
          keyFields: false,
        },
        OrgDomain: {
          keyFields: false,
        },
        Query: {
          fields: {
            User: {
              merge: false,
            },
          },
        },
        TotalCostToMoveAnalysis: {
          keyFields: false,
          merge: false,
        },
        UserInvitationAccessGrants: {
          keyFields: ["id", "role"],
        },
      },
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getToken();

      return {
        headers: {
          ...(headers as Record<string, unknown>),
          authorization: token != null ? `Bearer ${token}` : "",

          // Enable Prisma query performance logging on server when the
          // logging cookie is set to "query".
          logging: cookies.logging as string | undefined,
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        // eslint-disable-next-line no-console
        console.error(`[Network error]: ${networkError.message}`);
      }
      if (!graphQLErrors || graphQLErrors.length === 0) return;

      const isUnauthenticated = graphQLErrors.some(
        (e) => e.extensions.code === AuthError.UNAUTHENTICATED
      );

      // If there's an unauthenticated error, we need to refresh the page to
      // retrigger an AuthContext refresh
      if (isUnauthenticated) {
        window.location.reload();
        return;
      }

      graphQLErrors.forEach((error) => {
        switch (error.extensions.code) {
          // Don't show snackbar for unauthenticated or forbidden errors.
          case AuthError.UNAUTHENTICATED:
          case AuthError.FORBIDDEN:
            return;
          case AuthError.SSO_ONLY:
            displaySsoOnlyToast(enqueueSnackbar);
            break;
          case AuthError.NO_USER:
            enqueueSnackbar(AuthErrorMessage[AuthError.NO_USER], {
              variant: "error",
              autoHideDuration: 8_000,
            });
            break;
          default:
            enqueueSnackbar(error.message, { variant: "error" });
        }
        logGraphqlError(error);
      });
    });

    return new ApolloClient({
      connectToDevTools: environment !== "production",
      assumeImmutableResults: true,
      link: from([authLink, errorLink, httpLink]),
      cache,
      name: "web-app",
      defaultOptions: {
        watchQuery: {
          // By default, when components mount we want them to use the cache
          // while still fetching new data from the server just in case.
          fetchPolicy: "cache-and-network",

          // When further mutations update the cache, currently active queries
          // (those of mounted components) can trust the cache.
          // This function is borrowed from the Apollo docs.
          nextFetchPolicy(currentFetchPolicy) {
            if (
              currentFetchPolicy === "network-only" ||
              currentFetchPolicy === "cache-and-network"
            ) {
              // Demote network policies (except "no-cache") to "cache-first"
              // after the first request.
              return "cache-first";
            }
            // Leave all other fetch policies unchanged.
            return currentFetchPolicy;
          },
        },
      },
    });
  }, [environment, getToken, cookies.logging, enqueueSnackbar]);

  return (
    <ApolloProvider client={client}>
      <IntercomProvider appId={intercomAppId()}>
        <AuthProvider client={client}>
          <FeatureFlagProvider>
            <AnalyticsApp />
          </FeatureFlagProvider>
        </AuthProvider>
      </IntercomProvider>
    </ApolloProvider>
  );
}

function AnalyticsApp() {
  const location = useLocation();

  useEffect(() => {
    // During hot reloads in dev, the load function can be undefined.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.analytics.load?.(segmentWriteKey());
  }, []);

  useEffect(() => {
    window.analytics.page();
  }, [location]);

  const Track = useInitTrack();

  return (
    <Track>
      <Routes>
        <Route path="/calculator" element={<OfferOutcomeCalculator />} />
        <Route path="/authentication/*" element={<AuthenticationRouter />} />
        <Route
          path="/*"
          element={
            <AuthOrRedirect>
              <PermissionedApp />
            </AuthOrRedirect>
          }
        />
      </Routes>
    </Track>
  );
}

function PermissionedApp() {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      height: "100vh",
      alignItems: "stretch",
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(10),
      minWidth: "1278px",
      "@media print": {
        padding: 0,
      },
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
    sidebar: {
      boxShadow: `1px 0px 0px ${GRAY_6}`,
      flexShrink: 0,
      height: "100%",
      marginRight: "1px",
      position: "fixed",
      zIndex: 10,
      "@media print": {
        width: 0,
        display: "none",
      },
    },
  }));

  const { isLoading, permissions } = useAuth();
  const classes = useStyles();

  if (isLoading) return <div></div>;

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <NavigationBar />
      </div>
      <main className={classes.content}>
        <CustomerDataWarningBanner />
        <GlobalWarningBanner />
        {permissions.hasAnyPermissions() ? (
          <AuthorizedApp />
        ) : (
          <NoOrgAccessApp />
        )}
      </main>
      <RefreshPrompt />
    </div>
  );
}

function AuthorizedApp() {
  const location = useLocation();
  const { permissions } = useAuth();

  if (!permissions.canViewFoundations() && location.pathname === "/") {
    return <Navigate to="/portal" replace />;
  }

  return (
    <CompStructureProvider>
      <LocationsProvider>
        <CurrenciesProvider>
          <ScrollRestoration />
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route
              path="/"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.JobStructure]}
                  fallback={<NoFeatureAccess feature="Compensation" />}
                >
                  <Foundations />
                </AccessBoundary>
              }
            />
            <Route
              path="/departments/:id"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.JobStructure]}
                  fallback={<NoFeatureAccess feature="Compensation" />}
                >
                  <DepartmentDetail />
                </AccessBoundary>
              }
            />
            <Route
              path="/ladders/:id"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.JobStructure]}
                  fallback={<NoFeatureAccess feature="Compensation" />}
                >
                  <LadderDetailLoadingBoundary />
                </AccessBoundary>
              }
            />
            <Route path="/positions/:id">
              <Route
                index
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.JobStructure]}
                    fallback={<NoFeatureAccess feature="Compensation" />}
                  >
                    <PositionDetailLoadingBoundary />
                  </AccessBoundary>
                }
              />
              <Route
                path="edit"
                element={
                  <AccessBoundary
                    verb="edit"
                    scope="any"
                    every={[Noun.JobStructure]}
                    fallback={<NoFeatureAccess feature="Compensation" />}
                  >
                    <PositionDetailEditLoadingBoundary />
                  </AccessBoundary>
                }
              />
            </Route>
            <Route path="/offers">
              <Route
                index
                element={
                  <PreviewFeature
                    flag={FeatureFlag.OffersModule}
                    fallback={<NoFeatureAccess feature="Offers" />}
                  >
                    <AccessBoundary
                      verb="view"
                      scope="any"
                      every={[Noun.Offer]}
                      fallback={<NoFeatureAccess feature="Offers" />}
                    >
                      <OfferList />
                    </AccessBoundary>
                  </PreviewFeature>
                }
              />
              <Route
                path="new"
                element={
                  <PreviewFeature
                    flag={FeatureFlag.OffersModule}
                    fallback={<NoFeatureAccess feature="Offers" />}
                  >
                    <AccessBoundary
                      verb="view"
                      scope="any"
                      every={[Noun.Offer, Noun.CompStructure]}
                      fallback={<NoFeatureAccess feature="create Offers" />}
                    >
                      <OfferNewLoadingBoundary />
                    </AccessBoundary>
                  </PreviewFeature>
                }
              />
              <Route path=":id">
                <Route
                  index
                  element={
                    <PreviewFeature
                      flag={FeatureFlag.OffersModule}
                      fallback={<NoFeatureAccess feature="Offers" />}
                    >
                      <AccessBoundary
                        verb="view"
                        scope="any"
                        every={[Noun.Offer]}
                        fallback={<NoFeatureAccess feature="Offers" />}
                      >
                        <OfferDisplay />
                      </AccessBoundary>
                    </PreviewFeature>
                  }
                />
                <Route
                  path="edit"
                  element={
                    <PreviewFeature
                      flag={FeatureFlag.OffersModule}
                      fallback={<NoFeatureAccess feature="Offers" />}
                    >
                      <AccessBoundary
                        verb="view"
                        scope="any"
                        every={[Noun.Offer]}
                        fallback={<NoFeatureAccess feature="Offers" />}
                      >
                        <OfferEditLoadingBoundary />
                      </AccessBoundary>
                    </PreviewFeature>
                  }
                />
              </Route>
            </Route>
            <Route path="/compare" element={<Redirect to="/insights" />} />
            <Route path="/insights/*" element={<InsightsRouter />} />
            <Route
              path="/philosophy"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.Philosophy]}
                  fallback={<NoFeatureAccess feature="Philosophy" />}
                >
                  <Philosophy />
                </AccessBoundary>
              }
            />
            <Route path="/people/*" element={<PeoplePageRouter />} />
            <Route
              path="/portal/*"
              element={
                <PreviewFeature
                  flag={FeatureFlag.EmployeePortal}
                  fallback={<NoRouteFound />}
                >
                  <PortalRouter />
                </PreviewFeature>
              }
            />
            <Route
              path="/comp-cycles/*"
              element={
                <PreviewFeature flag={FeatureFlag.AdHocCompRecommendations}>
                  <CompCyclesRouter />
                </PreviewFeature>
              }
            />
            <Route
              path="/pay-equity"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[
                    Noun.Employee,
                    Noun.EmployeeCashCompensation,
                    Noun.SensitiveData,
                  ]}
                  fallback={<NoFeatureAccess feature="Pay Equity" />}
                >
                  <PayEquity />
                </AccessBoundary>
              }
            />
            <Route
              path="/settings/permissions/users/:id"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.AccessControl]}
                  fallback={<NoFeatureAccess feature="People Permissions" />}
                >
                  <UserAccessControlLoadingBoundary />
                </AccessBoundary>
              }
            />
            <Route
              path="/settings/permissions/user-invitations/:id"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.AccessControl]}
                  fallback={<NoFeatureAccess feature="People Permissions" />}
                >
                  <UserInvitationAccessControlLoadingBoundary />
                </AccessBoundary>
              }
            />
            <Route
              path="/settings/benefits/:id/copy"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.Offer]}
                  fallback={<NoFeatureAccess feature="Offer" />}
                >
                  <EditBenefitsPackagePage mode="copy" />
                </AccessBoundary>
              }
            />
            <Route
              path="/settings/benefits/new"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.Offer]}
                  fallback={<NoFeatureAccess feature="Offer" />}
                >
                  <EditBenefitsPackagePage mode="create" />
                </AccessBoundary>
              }
            />
            <Route
              path="/settings/benefits/:id"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.Offer]}
                  fallback={<NoFeatureAccess feature="Offer" />}
                >
                  <EditBenefitsPackagePage mode="edit" />
                </AccessBoundary>
              }
            />
            <Route
              path="/settings/data/upload-hris"
              element={
                <AccessBoundary
                  verb="view"
                  scope="any"
                  every={[Noun.Integration]}
                  fallback={<NoFeatureAccess feature="Integrations" />}
                >
                  <UploadHRIS />
                </AccessBoundary>
              }
            />
            <Route path="/settings/*" element={<Settings />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/profile/change-password"
              element={
                <Authentication
                  formState={AuthenticationFormState.ChangePassword}
                  token={null}
                  userInvitation={null}
                />
              }
            />
            <Route path="*" element={<NoRouteFound />} />
          </Routes>
        </CurrenciesProvider>
      </LocationsProvider>
    </CompStructureProvider>
  );
}

function NoOrgAccessApp() {
  return (
    <Routes>
      <Route path="/profile" element={<Profile />} />
      <Route path="*" element={<NoOrgAccess />} />
    </Routes>
  );
}

function logGraphqlError({ message, locations, path }: GraphQLError) {
  // eslint-disable-next-line no-console
  console.error(
    `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
      locations,
      null,
      "\t"
    )}, Path: ${path?.toString() ?? "undefined"}`
  );
}

function initializeSentryReporting(): void {
  const dsn = sentryDSN();
  const environment = envName();

  if (dsn === undefined || environment === undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      "Sentry is not initalized because the dsn and/or environment were not defined"
    );
  } else {
    Sentry.init({
      dsn,
      environment,
      integrations: [Sentry.browserTracingIntegration()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      enabled: process.env.NODE_ENV !== "development",
    });
  }
}
