import { gql } from "@apollo/client";
import { getTenure } from "@asmbl/shared/employee";
import { formatElapsedTime } from "@asmbl/shared/time";
import { CondensedTableTenureCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { MEDIUM_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableTenureCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const tenure = getTenure(employee.subject);
  const formattedTenure =
    tenure !== undefined ? formatElapsedTime(tenure, { short: true }) : "-";
  return (
    <AssembleTruncatedTypography lines={2}>
      {formattedTenure}
    </AssembleTruncatedTypography>
  );
}

CondensedTableTenureCell2.fragments = {
  participant: gql`
    fragment CondensedTableTenureCell2_participant on CompCycleParticipant {
      subject {
        activeAt
      }
    }
  `,
};

CondensedTableTenureCell2.Header = ColumnIdsToHeaders.get(ColumnIds.TENURE);
CondensedTableTenureCell2.id = ColumnIds.TENURE;
const column: ColumnComponent2["column"] = {
  Cell: CondensedTableTenureCell2,
  Header: CondensedTableTenureCell2.Header,
  id: CondensedTableTenureCell2.id,
  width: MEDIUM_COL_WIDTH,
};
CondensedTableTenureCell2.column = column;
