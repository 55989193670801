import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { TableCell } from "@material-ui/core";
import { useEffect, useState } from "react";
import { MatrixPerfRatingOption } from "src/__generated__/graphql";
import { PreviewFeature } from "../FeatureContext";
import { SelectField } from "../Form/SelectField";

type Props = {
  perfRatingOptions: MatrixPerfRatingOption[];
  perfRating?: string;
  setRevisedPerfRating: (perfRating: string) => unknown;
};

export function PerfRatingCell({
  perfRatingOptions = [],
  perfRating,
  setRevisedPerfRating,
}: Props): JSX.Element {
  const [rating, setRating] = useState(perfRating);
  useEffect(() => {
    setRating(perfRating);
  }, [perfRating]);
  return (
    <PreviewFeature
      flag={FeatureFlag.PerformanceRatingDropdown}
      fallback={
        <TableCell role="gridcell" align="center">
          {rating ?? "-"}
        </TableCell>
      }
    >
      <TableCell role="gridcell" align="center">
        {perfRatingOptions.length === 0 ? (
          rating ?? ""
        ) : (
          <SelectField
            id="perf-rating-cell-select"
            defaultValue={rating}
            value={rating}
            onChange={(perfRatingOption) => {
              setRating(perfRatingOption as string);
              setRevisedPerfRating(perfRatingOption as string);
            }}
            options={perfRatingOptions
              .slice(0)
              .sort((a, b) => (a.rank < b.rank ? -1 : 1))
              .map((option) => ({
                label: option.name,
                value: option.name,
              }))}
            size="small"
          />
        )}
      </TableCell>
    </PreviewFeature>
  );
}
