import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import {
  NoPhaseReviewerData,
  NoSearchResults,
} from "src/components/EmptyState/EmptyState";
import { useHotKey } from "src/hooks/useHotKey";
import { GRAY_6, WHITE } from "src/theme";
import { CalibrationCell } from "./CalibrationCell";
import { useProgressPageContext } from "./Context/ProgressPageContext";
import { ProgressCell } from "./ProgressCell";
import { ProgressTableHeaderCell } from "./ProgressTableHeaderCell";
import { getOrderedColumns } from "./util";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: WHITE,
    overflowX: "scroll",
    height: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 100%",
  },
  header: {
    padding: ".5rem 1rem",
    border: `solid 1px ${GRAY_6}`,
    display: "flex",
    justifyContent: "space-between",
  },
  headerText: {
    display: "flex",
    alignItems: "center",
  },
});

export function ProgressTable() {
  const classes = useStyles();
  const {
    currentReviewers,
    reviewerMap,
    setShowSubmissionFlow,
    phases,
    currentPhase,
    searchQuery,
    completionFilter,
  } = useProgressPageContext();

  const currentPhaseOrder = currentPhase?.phaseOrder ?? 0;

  const columns = getOrderedColumns(reviewerMap, phases);
  useHotKey({
    keyCombo: ["Alt"],
    onKeyDown: () => setShowSubmissionFlow(),
    onKeyUp: () => setShowSubmissionFlow(),
  });

  if (currentReviewers.size === 0) {
    const completionFilterText =
      completionFilter === "Submitted"
        ? "has submitted"
        : completionFilter === "Incomplete"
          ? "is incomplete"
          : "has been skipped";
    return searchQuery.length > 0 ? (
      <NoSearchResults />
    ) : (
      <NoPhaseReviewerData
        message={completionFilter !== "All" ? completionFilterText : undefined}
      />
    );
  }

  return (
    <div className={classes.row}>
      {phases.map((phase) => {
        const currentPhaseColumns = [...(columns.get(phase.phaseOrder) ?? [])];
        // calibration phase has no assigned reviewers === no column cell values
        const isCalibrationPhase = currentPhaseColumns.every(
          (c) => c.length === 0
        );
        return (
          <ProgressTableHeaderCell
            phase={phase}
            key={`phase-column-${phase.phaseOrder}`}
            isCalibrationPhase={isCalibrationPhase}
          >
            {isCalibrationPhase
              ? [<CalibrationCell key={`emptyCell-${phase.phaseOrder}`} />]
              : currentPhaseColumns.map((column, index) => {
                  return (
                    <div
                      key={`phase-column-${phase.phaseOrder}-${index}`}
                      className={classes.column}
                    >
                      {column.map((assignment) => {
                        if (
                          !assignment.employee.id ||
                          !currentReviewers.has(assignment.employee.id)
                        )
                          return null;

                        return (
                          <ProgressCell
                            key={`reviewer-cell-${assignment.id}`}
                            reviewer={assignment}
                            isPastPhase={phase.phaseOrder < currentPhaseOrder}
                          />
                        );
                      })}
                    </div>
                  );
                })}
          </ProgressTableHeaderCell>
        );
      })}
    </div>
  );
}

const phaseAssignmentFragment = gql`
  fragment ProgressTable_phaseAssignment on CompCyclePhaseAssignment2 {
    id
    phaseId # needed for caching
    status # needed for caching
    phase {
      id
      phaseOrder
    }
    employee {
      id
      displayName
      managerIds
      managerId
      directReportIds
      totalReportsCount
      activeEmployment {
        id
        jobTitle
      }
      userId
    }
    reviewees {
      id
      type
      participant {
        subjectId
        compCycleId
      }
    }
    recommendees {
      id
      type
      participant {
        subjectId
        compCycleId
      }
    }
  }
`;

ProgressTable.fragments = {
  phases: gql`
    ${phaseAssignmentFragment}
    fragment ProgressTable_phase on CompCyclePhase2 {
      id
      phaseOrder
      startDate
      compCyclePhaseAssignments {
        ...ProgressTable_phaseAssignment
      }
    }
  `,
};
