import { Input, makeStyles, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { BoldArrowRightIcon } from "src/components/AssembleIcons/Small/BoldArrowRightIcon";
import {
  useDeleteGuidanceRange,
  useUpdateGuidanceRange,
} from "src/mutations/Matrix";
import { GRAY_4 } from "src/theme";
import { MatrixEditButton } from "./MatrixEditButton";
import { CellLocation } from "./MeritAndBudgetMatrices";
import { useTableStyles } from "./tableStyles";

const useStyles = makeStyles(() => ({
  rangeCell: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  rangeInput: {
    maxWidth: "4rem",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

type Props = {
  idx: number;
  orderedRangesCount: number;
  range: { id: number; rangeStart: number };
  hoveredCell: CellLocation;
  setHoveredCell: (location: CellLocation) => void;
  refreshMetrics: () => void;
};

export function FocusableHeaderCell({
  idx,
  orderedRangesCount,
  range,
  hoveredCell,
  setHoveredCell,
  refreshMetrics,
}: Props) {
  const inputRef = useRef();
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const updateGuidanceRange = useUpdateGuidanceRange();
  const deleteGuidanceRange = useDeleteGuidanceRange();
  const { enqueueSnackbar } = useSnackbar();

  const showError = (error: string) => {
    console.error(error);
    enqueueSnackbar("Something went wrong. Please try again.", {
      variant: "error",
    });
  };

  const onUpdateGuidanceRange = (id: number, rangeStart: number) => {
    updateGuidanceRange(id, rangeStart).then(refreshMetrics).catch(showError);
  };

  const onDeleteGuidanceRange = (id: number) => {
    deleteGuidanceRange(id).then(refreshMetrics).catch(showError);
  };

  const handleCellFocus = () => {
    (inputRef.current as unknown as HTMLElement | null)?.focus();
  };

  return (
    <TableCell
      className={clsx(
        [
          tableClasses.cell,
          tableClasses.muiCellOverride,
          tableClasses.withTopBorder,
          tableClasses.shortCell,
          tableClasses.smallFont,
        ],
        {
          [tableClasses.roundTopLeft]: idx === 0,
          [tableClasses.roundBottomLeft]: idx === 0,
          [tableClasses.roundTopRight]: idx === orderedRangesCount - 1,
          [tableClasses.roundBottomRight]: idx === orderedRangesCount - 1,
          [tableClasses.withLeftBorder]: idx === 0,
          [tableClasses.cursorText]: idx !== 0,
        }
      )}
      key={`${range.id}-${idx}-header`}
      onMouseEnter={() => setHoveredCell({ y: -1, x: idx, table: null })}
      onClick={handleCellFocus}
    >
      <span className={classes.rangeCell}>
        {idx === 0 ? (
          range.rangeStart
        ) : (
          <>
            {(hoveredCell.y === -1 && hoveredCell.x === idx) ||
            (hoveredCell.y !== -1 && hoveredCell.x === idx + 1) ? (
              <MatrixEditButton
                actionType="remove"
                position="top"
                onClick={() => onDeleteGuidanceRange(range.id)}
              />
            ) : null}
            <Input
              className={clsx(tableClasses.smallFont, classes.rangeInput)}
              defaultValue={range.rangeStart}
              disableUnderline
              onBlur={(e) => {
                if (parseFloat(e.target.value) !== range.rangeStart) {
                  onUpdateGuidanceRange(range.id, parseFloat(e.target.value));
                }
              }}
              inputRef={inputRef}
              onFocus={(e) => e.target.select()}
              type="number"
              id={`rangeStart-${idx}`}
            />
          </>
        )}
        <BoldArrowRightIcon width={12} height={12} color={GRAY_4} />
      </span>
    </TableCell>
  );
}
