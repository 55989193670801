import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { CondensedTableView_compCycle as CompCycle } from "../../../../__generated__/graphql";
import { ActionBar } from "../../../../components/Layout/ActionBar";
import CompCycleFilterBar from "../CompCycleFilterBar";
import { CompCycleSubmitChangesButtonLoadingBoundary } from "../CompCycleSubmitChangesButtonLoadingBoundary";
import { ColumnOrderProvider } from "../Contexts/ColumnOrderContext";
import { PaginationProvider } from "../Contexts/PaginationContext";
import { TableFilterProvider } from "../Contexts/TableFilterContext";
import { PhaseProgressBar } from "../PhaseProgressBar";
import { CurrencyCodeWithAll } from "./CondensedTableCurrencyPicker";
import { CondensedTableHeader2 } from "./CondensedTableHeader";
import { CondensedTablePeopleLoadingBoundary } from "./CondensedTablePeopleLoadingBoundary";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 5rem)", // Don't scroll, let the table do it
  },
}));

type Props = {
  compCycle: CompCycle;
  isAdmin: boolean;
};

export function CondensedTableView({ compCycle, isAdmin }: Props): JSX.Element {
  const classes = useStyles();

  const [nameSearch, setNameSearch] = useState<string | null>(null);

  const [selectedCurrency, setSelectedCurrency] =
    useState<CurrencyCodeWithAll>("all");

  return (
    <TableFilterProvider>
      <PaginationProvider shouldSyncURLParams>
        <ColumnOrderProvider cycleSettings={compCycle}>
          <div className={classes.root}>
            {compCycle.phases.length > 0 && (
              <PhaseProgressBar compCycle={compCycle} />
            )}
            <ActionBar
              slotLeft={<CompCycleFilterBar compCycleId={compCycle.id} />}
              slotRight={
                <CompCycleSubmitChangesButtonLoadingBoundary
                  compCycleId={compCycle.id}
                  nameSearch={nameSearch}
                />
              }
            />
            <CondensedTableHeader2
              onSearchTermChange={setNameSearch}
              selectedCurrency={selectedCurrency}
              onChangeSelectedCurrency={setSelectedCurrency}
              compCycle={compCycle}
              nameSearch={nameSearch}
            />
            <CondensedTablePeopleLoadingBoundary
              compCycleId={compCycle.id}
              isAdmin={isAdmin}
              nameSearch={nameSearch}
              selectedCurrency={selectedCurrency}
              compCycle={compCycle}
            />
          </div>
        </ColumnOrderProvider>
      </PaginationProvider>
    </TableFilterProvider>
  );
}

CondensedTableView.fragments = {
  compCycle: gql`
    ${PhaseProgressBar.fragments.compCycle}
    ${CondensedTableHeader2.fragments.compCycle2}
    fragment CondensedTableView_compCycle on CompCycle2 {
      id
      ...PhaseProgressBar_compCycle
      ...CondensedTableHeader_compCycle2
      allowSalary
      allowSalaryMarket
      allowSalaryMerit
      allowSalaryPromotion
      allowBonus
      allowEquity
      allowActualRecurringBonus
      allowTargetCommission
      allowTargetRecurringBonus
    }
  `,
};
