import { gql } from "@apollo/client";
import { Input, makeStyles, TableCell, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { useRef, useState } from "react";
import { MatrixEditButton } from "../../MeritGuidance/MatrixEditButton";
import { useMultipleMeritMatrices } from "../useMultipleMatrices";
import { useTableStyles } from "./tableStyles";

type Props = {
  cells: { label: string | number; id?: number | string }[][];
  cellIdx: number;
  rowIdx: number;
  cellRowLength: number;
  cell: { label: string | number; id?: number | string };
  matrixId: number;
};

const useStyles = makeStyles((theme) => ({
  editableCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
  inputWithoutStepper: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  justifyLeft: {
    justifyContent: "flex-start",
  },
}));

export function MatrixCell({
  cells,
  cellIdx,
  rowIdx,
  cellRowLength,
  cell,
  matrixId,
}: Props) {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const inputRef = useRef(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleCellFocus = () => {
    (inputRef.current as HTMLElement | null)?.focus();
  };

  const {
    onUpdateMatrixGuide,
    onDeletePerfRatingOption,
    onUpdatePerfRatingOption,
  } = useMultipleMeritMatrices();

  return (
    <TableCell
      align={cellIdx === 0 ? "left" : "right"}
      className={clsx(
        [
          tableClasses.cell,
          tableClasses.muiCellOverride,
          tableClasses.cursorText,
        ],
        {
          [tableClasses.roundTopRight]:
            rowIdx === 0 && cellIdx === cellRowLength - 1,
          [tableClasses.roundBottomLeft]:
            rowIdx === cells.length - 1 && cellIdx === 0,
          [tableClasses.roundBottomRight]:
            rowIdx === cells.length - 1 && cellIdx === cellRowLength - 1,
          [tableClasses.withLeftBorder]: cellIdx === 0,
          [tableClasses.withTopBorder]: rowIdx === 0,
          [tableClasses.roundTopLeft]: rowIdx === 0 && cellIdx === 0,
        }
      )}
      key={`cell-${rowIdx}-${cellIdx}`}
      onClick={handleCellFocus}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {cellIdx === 0 ? (
        <Tooltip title="Performance ratings and compa-ratios are shared across Merit and Budget">
          <span className={clsx(classes.editableCell, classes.justifyLeft)}>
            {isHovered && cells.length > 1 && (
              <MatrixEditButton
                position="left"
                actionType="remove"
                onClick={async () => {
                  if (cell.id != null) {
                    try {
                      await onDeletePerfRatingOption(cell.id);
                    } catch (e) {
                      console.error(e);
                    }
                  }
                }}
              />
            )}
            <Input
              className={classes.inputWithoutStepper}
              defaultValue={cell.label}
              disableUnderline
              inputRef={inputRef}
              onBlur={async (e) => {
                if (cell.id != null && e.target.value !== cell.label) {
                  await onUpdatePerfRatingOption(cell.id, e.target.value);
                }
              }}
              placeholder={
                cells.length === 1
                  ? "All Performance Ratings"
                  : "Performance Rating Name"
              }
              onFocus={(e) => e.target.select()}
              fullWidth
            />
          </span>
        </Tooltip>
      ) : (
        <span className={classes.editableCell}>
          <Input
            className={classes.inputWithoutStepper}
            defaultValue={cell.label}
            disableUnderline
            inputProps={{
              style: { textAlign: "right", maxWidth: "5rem" },
            }}
            inputRef={inputRef}
            endAdornment="%"
            type="number"
            onBlur={async (e) => {
              await onUpdateMatrixGuide({
                newValue: e.target.value,
                currentValue: cell.label,
                matrixGuideId: cell.id,
                matrixId,
              });
            }}
            onFocus={(e) => e.target.select()}
          />
        </span>
      )}
    </TableCell>
  );
}

MatrixCell.fragments = {
  perfRatingOption: gql`
    fragment MatrixCard_matrixPerfRatingOption on MatrixPerfRatingOption {
      id
      name
      rank
      organizationId
    }
  `,
};
