import { Employee } from "@prisma/client";
import { CurrencyCode } from "./constants";
import { Currency, exchangeFromTo } from "./currency";
import { isZero, Money, multiply, ratio, zero } from "./money";
import { CompUnit, RecItemType } from "./types";
import { formatNumeral, mapMaybe } from "./utils";

export type Item = {
  recommendationType: RecItemType;
  recommendedCashValue?: Money | null;
  recommendedPercentValue?: number | null;
  cashEquivalent?: Money | null;
  unitType?: CompUnit | null;
};

export type SalaryCashComp = {
  annualCashEquivalent: Money;
  hourlyCashEquivalent: Money;
} | null;

export function findRecommendedPosition<P extends { id: number }>(
  recommendationItems: {
    recommendationType: RecItemType;
    recommendedPositionId?: number | null;
    recommendedPosition?: { id: number } | null;
  }[],
  positions: P[]
): P | null {
  if (recommendationItems.length === 0) {
    return null;
  }

  const positionRecItem = recommendationItems.find(
    (item) => item.recommendationType === RecItemType.PROMOTION
  );

  const recommnededPositionId =
    positionRecItem?.recommendedPosition?.id ??
    positionRecItem?.recommendedPositionId;

  if (recommnededPositionId == null) {
    return null;
  }

  return positions.find((p) => p.id === recommnededPositionId) ?? null;
}

export function getItemCurrency(items: Item[]): CurrencyCode | undefined {
  return mapMaybe(items, (item) => item.recommendedCashValue?.currency).at(0);
}

export function getRecommendedCashValue(
  items: Item[],
  type: RecItemType,
  salary: SalaryCashComp,
  defaultCurrencyCode: CurrencyCode,
  isHourly: boolean
): Money {
  const item = items.find((item) => item.recommendationType === type);
  if (item?.recommendedPercentValue && salary) {
    return multiply(
      isHourly ? salary.hourlyCashEquivalent : salary.annualCashEquivalent,
      item.recommendedPercentValue / 100
    );
  }
  const itemCurrency = getItemCurrency(items) ?? defaultCurrencyCode;
  return item?.recommendedCashValue ?? zero(itemCurrency);
}

export function getMeritBonus(
  items: Item[],
  salary: SalaryCashComp,
  defaultCurrencyCode: CurrencyCode
): Money {
  return getRecommendedCashValue(
    items,
    RecItemType.MERIT_BONUS,
    salary,
    defaultCurrencyCode,
    false // bonuses can only be saved as annual values
  );
}

export function getPercentageChange(
  newValue: Money,
  currentValue: Money
): string {
  return formatNumeral(ratio(newValue, currentValue), {
    maximumFractionDigits: 2,
    style: "percent",
  });
}

export function getPercentChangeBonusToSalary(
  items: Item[],
  currentSalary: SalaryCashComp,
  currencies: Map<CurrencyCode, Currency>
): string {
  if (!currentSalary || isZero(currentSalary?.annualCashEquivalent)) {
    return "0%";
  }

  const { annualCashEquivalent } = currentSalary;

  const increase = getMeritBonus(
    items,
    currentSalary,
    annualCashEquivalent.currency
  );

  if (increase.value === 0) {
    return "0%";
  }

  if (increase.currency === annualCashEquivalent.currency) {
    return getPercentageChange(increase, annualCashEquivalent);
  }

  const increaseCurrency = currencies.get(increase.currency);
  const currentSalaryCurrency = currencies.get(annualCashEquivalent.currency);

  // if either currency is missing, there's an error
  if (!increaseCurrency || !currentSalaryCurrency) {
    return "-";
  }

  const convertedIncrease = exchangeFromTo(
    increase,
    increaseCurrency,
    currentSalaryCurrency
  );

  return getPercentageChange(convertedIncrease, annualCashEquivalent);
}

/**
 *
 * @param item
 * @param salary
 * @param employee - if included, it will return the employee in the result
 *
 * @returns RecItemInput
 * Returns a cash recItem. If item is of a PERCENT_OF_SALARY unit type it gets
 * converted using the salary param. Else, it returns the recItem as is since it
 * is already a cash item.
 */
export function getCashRecItem<I extends Item>(item: I, salary: Money): I;
export function getCashRecItem<I extends Item>(
  item: I,
  salary: Money,
  employee: Employee
): I & { subject: Employee };
export function getCashRecItem<I extends Item>(
  item: I,
  salary: Money,
  employee?: Employee
): I | (I & { subject: Employee }) {
  if (item.unitType === CompUnit.CASH || item.unitType == null) {
    return { ...item, subject: employee };
  }

  return {
    ...item,
    unitType: CompUnit.CASH,
    recommendedCashValue: multiply(
      salary,
      (item.recommendedPercentValue ?? 0) / 100
    ),
    subject: employee,
  };
}
