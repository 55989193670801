import { gql } from "@apollo/client";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import {
  BudgetAllocationTable_compCycleBudget as RootBudget,
  BudgetAllocationTable_compCycleBudgetDraft as RootBudgetDraft,
} from "../../../../__generated__/graphql";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { CompCycleGrouping } from "../../../../components/CompCycle/types";
import {
  AllocationUnit,
  budgetAllocationFactory,
  CompComponent,
  CompComponentDisplay,
  CompSubComponent,
  CompSubComponentDisplay,
  isCompSubComponent,
} from "../../../../models/Budget";
import { DraftBudgetInput } from "../../../../mutations/CompCycleBudget";
import { GRAY_4, GRAY_6, WHITE } from "../../../../theme";
import { useAllocateBudgetsContext } from "../Context/AllocateBudgetsContext";
import { ReportingTier } from "./ReportingTier";

const useStyles = makeStyles(() => ({
  table: {
    height: "1px",

    "& td, & th": {
      borderRight: `1px solid ${GRAY_6}`,

      "&:last-child": {
        borderRight: 0,
      },
    },
  },
  headerCell: {
    backgroundColor: WHITE,
    height: "2rem",
    borderTop: `1px solid ${GRAY_6}`,

    textTransform: "uppercase",
    color: GRAY_4,
    fontWeight: 700,
    fontSize: "0.625rem",
  },
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  groupedHeader: {
    "th&": {
      borderRight: 0,
    },
  },
  scrollContainer: {
    overflowX: "auto",
    zIndex: 0,
    height: "100%",
  },
}));

type Props = {
  compComponent: CompCycleGrouping;
  rootBudgetDraft: RootBudgetDraft;
  rootBudget: RootBudget | null;
  equityDisplay: AllocationUnit;
  onSaveDraft: (
    employeeId: number | null,
    budget: DraftBudgetInput
  ) => Promise<unknown>;
};

export function BudgetAllocationTable({
  compComponent,
  rootBudgetDraft,
  rootBudget,
  equityDisplay,
  onSaveDraft,
}: Props): JSX.Element {
  const classes = useStyles();

  const { cycleSettings, valuation } = useAllocateBudgetsContext();

  const budgetAllocation = budgetAllocationFactory({
    compComponent: compComponent === "all" ? "salary" : compComponent,
    compCycle: cycleSettings,
    equityDisplay,
    valuation,
  })(rootBudgetDraft);

  const subComponents = budgetAllocation.allocations.filter((x) =>
    isCompSubComponent(x.compComponent)
  );
  const parentBudgets = budgetAllocation.allocations.filter(
    (x) => !isCompSubComponent(x.compComponent)
  );
  const subComponentCount = subComponents.length;
  // columns should take up 6 cols i.e. half the table width and add one for
  // total column
  const columnWidth = `${50 / (parentBudgets.length + subComponentCount)}%`;

  return (
    <div className={classes.scrollContainer}>
      <Table className={classes.table}>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell
              className={classes.headerCell}
              variant="head"
              width="30%"
            >
              <AssembleTypography variant="productTableHeader">
                Name
              </AssembleTypography>
            </TableCell>

            <TableCell
              className={clsx(classes.groupedHeader, classes.headerCell)}
              variant="head"
              width="10%"
            >
              <AssembleTypography variant="productTableHeader">
                Direct
              </AssembleTypography>
            </TableCell>
            <TableCell
              className={classes.headerCell}
              variant="head"
              width="10%"
            >
              <AssembleTypography variant="productTableHeader">
                Indirect
              </AssembleTypography>
            </TableCell>

            {subComponents.map(({ compComponent }, i) => (
              <TableCell
                key={compComponent}
                variant="head"
                className={clsx(classes.headerCell, {
                  // All but the last skip their right border
                  [classes.groupedHeader]: i !== subComponentCount - 1,
                })}
                width={columnWidth}
              >
                <AssembleTypography variant="productTableHeader">
                  {CompSubComponentDisplay[compComponent as CompSubComponent]}
                </AssembleTypography>
              </TableCell>
            ))}

            {parentBudgets.map(({ compComponent }) => (
              <TableCell
                key={compComponent}
                className={classes.headerCell}
                variant="head"
                width={columnWidth}
              >
                <AssembleTypography variant="productTableHeader">
                  {CompComponentDisplay[compComponent as CompComponent]}
                </AssembleTypography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <ReportingTier
            budget={rootBudget}
            budgetDraft={rootBudgetDraft}
            onSaveDraft={onSaveDraft}
            indentation={[]}
            compComponent={compComponent}
            equityDisplay={equityDisplay}
          />
        </TableBody>
      </Table>
    </div>
  );
}

BudgetAllocationTable.fragments = {
  compCycleBudgetDraft: gql`
    ${ReportingTier.fragments.compCycleBudgetDraft}
    fragment BudgetAllocationTable_compCycleBudgetDraft on CompCycleBudgetDraft {
      ...ReportingTier_compCycleBudgetDraft
    }
  `,
  compCycleBudget: gql`
    ${ReportingTier.fragments.compCycleBudget}
    fragment BudgetAllocationTable_compCycleBudget on CompCycleBudget {
      ...ReportingTier_compCycleBudget
    }
  `,
  compCycleSettings: gql`
    ${ReportingTier.fragments.compCycleSettings}
    fragment BudgetAllocationTable_compCycleSettings on CompCycle {
      ...ReportingTier_compCycleSettings
    }
  `,
  valuation: gql`
    ${ReportingTier.fragments.valuation}
    fragment BudgetAllocationTable_valuation on Valuation {
      ...ReportingTier_valuation
    }
  `,
};
