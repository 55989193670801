import { gql } from "@apollo/client";
import { collapseRule, EmailRule } from "@asmbl/shared/eligibility";
import { contramap } from "@asmbl/shared/sort";
import { mapify } from "@asmbl/shared/utils";
import {
  Chip,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Clear, Info } from "@material-ui/icons";
import { Autocomplete, AutocompleteCloseReason } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { ClipboardEvent, useMemo, useState } from "react";
import { EmailRuleEditor_employee as Employee } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { DeleteIcon } from "src/components/AssembleIcons/Brand/DeleteIcon";
import { AssemblePopper } from "src/components/AssemblePopper";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_4, GRAY_6, PURPLE_2, WHITE } from "src/theme";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: "1rem",
    width: "1rem",
    marginLeft: theme.spacing(1.5),
    padding: "0px",
  },
  box: {
    width: "100%",
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "8px",
    minHeight: "7rem",
    height: "fit-content",
    paddingBottom: "1rem",
  },
  title: {
    margin: "8px 12px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteButton: {
    width: 32,
    height: 32,
    padding: 8,
    color: GRAY_4,
  },
  selectors: {
    display: "flex",
    gap: "1rem",
    width: "100%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  selector: {
    flex: "1 0 0",
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
  },
  input: {
    height: "2rem",
  },
  headerWithInfo: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  infoIcon: {
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
  },
}));

type Props = {
  rule: EmailRule;
  employees: Employee[];
  onChange: (rule: EmailRule) => void;
  onDelete?: () => void;
};

const COMMA_OR_SPACE = /[, ]/;

export function EmailRuleEditor({
  rule,
  employees,
  onChange,
  onDelete,
}: Props): JSX.Element {
  const classes = useStyles();
  const collapsed = collapseRule(rule);
  const [included, setIncluded] = useState<number[]>(
    collapsed.alwaysIncludeIds ?? []
  );
  const [excluded, setExcluded] = useState<number[]>(
    collapsed.alwaysExcludeIds ?? []
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleInputPaste = (
    e: ClipboardEvent<HTMLInputElement>,
    includedOrExcluded: "included" | "excluded"
  ) => {
    const employeeEmails = e.clipboardData.getData("text");
    if (COMMA_OR_SPACE.test(employeeEmails)) {
      const employeeIds = employeeEmails
        .split(COMMA_OR_SPACE)
        .map(
          (email) =>
            employees.find(
              (e) => e.email.trim().toLowerCase() === email.trim().toLowerCase()
            )?.id
        )
        .filter((id): id is number => id !== undefined);

      if (!employeeIds.length) return;
      // This prevents the input from actually being populated, which we want
      // since the chips will appear immediately
      e.stopPropagation();
      e.preventDefault();

      const setStateFn =
        includedOrExcluded === "included" ? setIncluded : setExcluded;
      const updatedState = Array.from(
        new Set(
          (includedOrExcluded === "included"
            ? included.slice(0)
            : excluded.slice(0)
          ).concat(employeeIds)
        )
      );

      setStateFn(updatedState);
      includedOrExcluded === "included"
        ? updateRule(updatedState, excluded)
        : updateRule(included, updatedState);

      enqueueSnackbar(
        <>
          {employeeIds.length} people added to the {includedOrExcluded} list.
        </>,
        {
          autoHideDuration: 6000,
          variant: "success",
          action: (
            <AssembleButton
              size="small"
              variant="outlined"
              onClick={() =>
                setStateFn((prev) =>
                  prev.filter((id) => !employeeIds.includes(id))
                )
              }
              label="Undo?"
            />
          ),
        }
      );
    }
  };

  const idToEmail = mapify(employees, "id", "email");
  const idToName = mapify(employees, "id", "displayName");
  const idToEmployee = mapify(employees, "id");

  const updateRule = (includes: number[], excludes: number[]) =>
    onChange([{ alwaysIncludeIds: includes }, { alwaysExcludeIds: excludes }]);

  const handleClose = (_: unknown, reason: AutocompleteCloseReason) => {
    if (reason === "toggleInput") return;
    updateRule(included, excluded);
  };

  const sortedEmployees = useMemo(
    () => employees.slice().sort(contramap((e) => e.displayName)),
    [employees]
  );

  return (
    <div className={classes.box}>
      <div className={classes.title}>
        <AssembleTypography
          variant="productEyebrowSmall"
          className={classes.headerWithInfo}
        >
          Filter By Email{" "}
          <Tooltip title="To add several people at once, paste in a list of their emails separated by commas">
            <span className={classes.infoIcon}>
              <Info fontSize="inherit" />
            </span>
          </Tooltip>
        </AssembleTypography>
        <Tooltip title="Delete">
          <IconButton
            className={classes.deleteButton}
            onClick={() => onDelete && onDelete()}
          >
            <DeleteIcon color={GRAY_4} hoverColor={PURPLE_2} />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.selectors}>
        <div className={classes.selector}>
          <AssembleTypography variant="productSmallBold">
            Include specific people
          </AssembleTypography>
          <Autocomplete
            value={included}
            options={sortedEmployees.map(({ id }) => id)}
            onChange={(_, value) => {
              setIncluded(value);
              updateRule(value, excluded);
            }}
            onClose={handleClose}
            multiple
            disableCloseOnSelect
            forcePopupIcon={false}
            PopperComponent={AssemblePopper}
            getOptionLabel={(e) => idToEmail.get(e) as string}
            filterOptions={(options, { inputValue }) => {
              const search = inputValue.toLowerCase();

              return options.filter((option) => {
                const findByEmail =
                  idToEmail.get(option)?.toLowerCase().includes(search) ===
                  true;
                const findByName =
                  idToName.get(option)?.toLowerCase().includes(search) === true;

                return findByEmail || findByName;
              });
            }}
            renderInput={(params) => (
              <TextField
                ref={params.InputProps.ref}
                {...params}
                margin="dense"
                variant="outlined"
                placeholder={
                  included.length === 0 ? "Search by name or email" : undefined
                }
                inputProps={{
                  onPaste: (e: ClipboardEvent<HTMLInputElement>) =>
                    handleInputPaste(e, "included"),
                  ...params.inputProps,
                }}
              />
            )}
            renderOption={(option) => (
              <DropdownOption employee={idToEmployee.get(option) as Employee} />
            )}
            noOptionsText={
              <AssembleTypography>
                We can't find any employee with that name or email.
              </AssembleTypography>
            }
            limitTags={2}
            renderTags={(values: number[], getTagProps) =>
              values.map((option, index) => (
                <Chip
                  size="small"
                  key={option}
                  {...getTagProps({ index })}
                  label={idToEmail.get(option)}
                  deleteIcon={<Clear />}
                />
              ))
            }
          />
        </div>
        <div className={classes.selector}>
          <AssembleTypography variant="productSmallBold">
            Exclude specific people
          </AssembleTypography>
          <Autocomplete
            value={excluded}
            options={sortedEmployees.map(({ id }) => id)}
            onChange={(_, value) => {
              setExcluded(value);
              updateRule(included, value);
            }}
            onClose={handleClose}
            multiple
            disableCloseOnSelect
            forcePopupIcon={false}
            PopperComponent={AssemblePopper}
            getOptionLabel={(e) => idToEmail.get(e) as string}
            renderInput={(params) => (
              <TextField
                ref={params.InputProps.ref}
                {...params}
                margin="dense"
                variant="outlined"
                placeholder={
                  excluded.length === 0 ? "Search by name or email" : undefined
                }
                inputProps={{
                  onPaste: (e: ClipboardEvent<HTMLInputElement>) =>
                    handleInputPaste(e, "excluded"),
                  ...params.inputProps,
                }}
              />
            )}
            renderOption={(option) => (
              <DropdownOption employee={idToEmployee.get(option) as Employee} />
            )}
            noOptionsText={
              <AssembleTypography>
                We can't find any employee with that name or email.
              </AssembleTypography>
            }
            limitTags={2}
            renderTags={(values: number[], getTagProps) =>
              values.map((option, index) => (
                <Chip
                  key={option}
                  size="small"
                  {...getTagProps({ index })}
                  label={idToEmail.get(option)}
                  deleteIcon={<Clear />}
                />
              ))
            }
          />
        </div>
      </div>
    </div>
  );
}

function DropdownOption({ employee }: { employee: Employee }): JSX.Element {
  return (
    <div>
      <AssembleTypography>{employee.displayName}</AssembleTypography>
      <AssembleTypography textColor={GRAY_4}>
        {employee.email}
      </AssembleTypography>
    </div>
  );
}

EmailRuleEditor.fragments = {
  employee: gql`
    fragment EmailRuleEditor_employee on Employee {
      id
      displayName
      email
    }
  `,
};
