import { gql } from "@apollo/client";
import { zero } from "@asmbl/shared/money";
import { CondensedTableCurrentEquityValueCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableCurrentEquityValueCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { selectedCurrency } = useTableData();
  const { defaultCurrencyCode } = useCurrencies();

  const { totalGrossHoldingValue } = employee.subject.equityHoldings;
  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        totalGrossHoldingValue ??
          zero(
            selectedCurrency === "all" ? defaultCurrencyCode : selectedCurrency
          )
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentEquityValueCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentEquityValueCell2_participant on CompCycleParticipant {
      subject {
        id
        equityHoldings {
          id
          totalGrossHoldingValue
        }
      }
    }
  `,
};

CondensedTableCurrentEquityValueCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_EQUITY_VALUE
);
CondensedTableCurrentEquityValueCell2.id = ColumnIds.CURRENT_EQUITY_VALUE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentEquityValueCell2,
  Header: CondensedTableCurrentEquityValueCell2.Header,
  id: CondensedTableCurrentEquityValueCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableCurrentEquityValueCell2.column = column;
