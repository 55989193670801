import { gql } from "@apollo/client";
import { formatNumeral } from "@asmbl/shared/utils";
import { CondensedTableCurrentEquityUnitsCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableCurrentEquityUnitsCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const totalUnits = employee.subject.equityHoldings.totalUnitCount ?? 0;
  return (
    <AssembleTruncatedTypography align="right">
      {formatNumeral(totalUnits)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentEquityUnitsCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentEquityUnitsCell2_participant on CompCycleParticipant {
      subject {
        id
        equityHoldings {
          id
          totalUnitCount
        }
      }
    }
  `,
};

CondensedTableCurrentEquityUnitsCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_EQUITY_UNITS
);
CondensedTableCurrentEquityUnitsCell2.id = ColumnIds.CURRENT_EQUITY_UNITS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentEquityUnitsCell2,
  Header: CondensedTableCurrentEquityUnitsCell2.Header,
  id: CondensedTableCurrentEquityUnitsCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableCurrentEquityUnitsCell2.column = column;
