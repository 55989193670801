import { gql } from "@apollo/client";
import { Card, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { uniqBy } from "lodash";
import { useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { ChevronRightIcon } from "src/components/AssembleIcons/Brand/ChevronRightIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_4, GRAY_6 } from "src/theme";
import { UseMatrixMatrix } from "../useMultipleMatrices";
import { buildMatrix } from "../utils";
import { Matrix } from "./Matrix";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),

    boxShadow: "none",
    border: `1px solid ${GRAY_6}`,

    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardHeaderGroupInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(0.5),
  },
  cardHeaderGroupAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1.5),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  button: {
    "&:hover": {
      border: "1px solid transparent",
      boxShadow: "none",
    },
  },
  pointer: {
    cursor: "pointer",
  },
}));

type Props = {
  label: string;
  matrix: UseMatrixMatrix;
  defaultOpen?: boolean;
};

export function MatrixCard({ label, matrix, defaultOpen = true }: Props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  if (matrix.id == null || matrix.matrixGuides == null) return null;

  const orderedRanges = uniqBy(
    matrix.matrixGuides.map((matrixGuide) => matrixGuide.matrixRange),
    "id"
  ).sort((a, b) => (a.rangeStart < b.rangeStart ? -1 : 1));

  return (
    <Card
      className={clsx(classes.card, { [classes.pointer]: !isOpen })}
      onClick={() => {
        // Only want the whole card clickable if it's closed
        if (!isOpen) {
          setIsOpen(true);
        }
      }}
    >
      <div className={classes.cardHeader}>
        <div className={classes.cardHeaderGroupInfo}>
          <AssembleButton
            className={classes.button}
            size="small"
            variant="text"
            startIcon={
              isOpen ? (
                <ChevronDownIcon color={GRAY_4} />
              ) : (
                <ChevronRightIcon color={GRAY_4} />
              )
            }
            onClick={() => setIsOpen(!isOpen)}
          />
          <AssembleTypography variant="productSectionHeader">
            {label}
          </AssembleTypography>
        </div>
      </div>
      {isOpen ? (
        <Matrix
          cells={buildMatrix(matrix.matrixGuides)}
          matrixId={matrix.id}
          orderedRanges={orderedRanges}
          matrixType={matrix.type}
        />
      ) : null}
    </Card>
  );
}

MatrixCard.fragments = {
  matrix: gql`
    ${Matrix.fragments.guide}
    fragment GuidanceDetailedView_matrix on Matrix {
      name
      eligibilityRules
      eligibleParticipants
      matrixGuides {
        id
        ...MatrixCard_matrixGuide
      }
    }
  `,
};
