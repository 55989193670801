import { makeStyles } from "@material-ui/core";
import { ProgressTable_phase as Phase } from "src/__generated__/graphql";
import { PushPinIcon } from "src/components/AssembleIcons/Small/PushPinIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_2, GRAY_4, GRAY_6 } from "src/theme";
import { useProgressPageContext } from "./Context/ProgressPageContext";

const useStyles = makeStyles({
  header: {
    padding: ".5rem 1rem",
    border: `solid 1px ${GRAY_6}`,
    borderLeft: "none",
    display: "flex",
    justifyContent: "space-between",
    minWidth: 250,
  },
  headerText: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "column",
  },
  childrenContainer: {
    display: "flex",
    flex: "1 1 100%",
  },
});

type Props = {
  phase: Phase;
  children: JSX.Element[];
  isCalibrationPhase: boolean;
};

export function ProgressTableHeaderCell({
  phase,
  children,
  isCalibrationPhase,
}: Props) {
  const { currentPhase } = useProgressPageContext();
  const currentPhaseOrder = currentPhase?.phaseOrder ?? 0;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AssembleTypography
          variant="productEyebrowMedium"
          textColor={currentPhaseOrder === phase.phaseOrder ? GRAY_2 : GRAY_4}
          className={classes.headerText}
        >
          {isCalibrationPhase
            ? "Calibration Phase"
            : `Phase ${phase.phaseOrder}`}
          {currentPhaseOrder === phase.phaseOrder ? (
            <PushPinIcon color="#FF385C" height="12px" />
          ) : null}
        </AssembleTypography>
        <AssembleTypography
          variant="productEyebrowMedium"
          textColor={currentPhaseOrder === phase.phaseOrder ? GRAY_2 : GRAY_4}
        >
          {new Date(phase.startDate).toDateString().slice(4, 10)}
        </AssembleTypography>
      </div>
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  );
}
