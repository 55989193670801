import { gql } from "@apollo/client";
import {
  BENEFITS_PACKAGE_FIELDS,
  COMP_STRUCTURE_FIELDS,
  OFFER_CONFIG_FIELDS,
  POSITION_FIELDS_MINIMAL,
  VALUATION_FIELDS,
} from "./fragments";
import { NOUN_SCOPES_FIELDS } from "./mutations/User";

export const DEPARTMENTS_QUERY = gql`
  query GetDepartments {
    organization {
      id
      name
    }
    departments {
      id
      name
      ladders {
        id
        name
        positions {
          id
          level
          name
        }
      }
    }
  }
`;

export const DEPARTMENT_QUERY = gql`
  query GetDepartment($departmentId: Int!) {
    department(id: $departmentId) {
      id
      name
      description
      organization {
        id
        name
        departments {
          id
          name
        }
      }
      ladders {
        id
        name
        description
        positions {
          id
          level
        }
      }
    }
  }
`;

export const GET_OFFER = gql`
  ${BENEFITS_PACKAGE_FIELDS}
  ${OFFER_CONFIG_FIELDS}
  query GetOffer($offerId: Int!) {
    offer(id: $offerId) {
      id
      candidate {
        candidateName
      }
      cash {
        data {
          type
          money
        }
      }
      message
      closingMessage
      outcomeDescription
      offeredAt
      equity {
        data {
          type
          money
          units
        }
      }
      position {
        id
        name
        level
        type
      }
      organization {
        id
        name
      }
      title
      benefitsPackage {
        ...BenefitsPackageFields
      }
    }
    compStructure {
      id
      showPercentOwnership
      showValuation
      vestingCliff
      vestingMonths
      companyDescription
      equityGrantTypes
      equityGrantMethod
      employmentHoursPerWeek
      employmentWeeksPerYear
    }
    valuation {
      id
      activeAt
      estimatedDilution
      financingStage
      fdso
      strikePrice
      valuation
    }
    offerConfig {
      ...OfferConfigFields
    }
  }
`;

export const GET_OFFERS = gql`
  query GetOffers {
    offers {
      id
      candidate {
        candidateName
      }
      isLegacy
      offeredAt
      updatedAt
      expiredAt
      position {
        id
        name
        level
      }
      title
    }
  }
`;

export const GET_OFFER_CONFIG = gql`
  ${OFFER_CONFIG_FIELDS}
  query GetOfferConfig {
    offerConfig {
      ...OfferConfigFields
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query GetOrganization {
    organization {
      id
      name
    }
  }
`;

export const COMP_STRUCTURE_QUERY = gql`
  ${COMP_STRUCTURE_FIELDS}
  query GetCompStructure {
    compStructure {
      ...CompStructureFields
    }
  }
`;

export const LADDERS_AND_POSITIONS_QUERY = gql`
  query GetLaddersAndPositions {
    compStructure {
      id
      bandPointTypes
      cashBandTypes
      equityBandTypes
      vestingMonths
      allBandTypes
    }
    ladders {
      id
      name
      department {
        id
        name
      }
      positions {
        id
        name
        description
        level
        areCashBandsAuthorized
        areEquityBandsAuthorized
      }
    }
  }
`;

export const GET_VALUATION = gql`
  ${VALUATION_FIELDS}
  query GetValuation {
    valuation {
      ...ValuationFields
    }
  }
`;

export const GET_USERS_WITH_ACCESS_CONTROLS = gql`
  query GetUsersWithAccessControls {
    userRecords {
      id
      email
      name
      createdAt
      photoURL
      isDisabled
      userAccessGrant {
        id
        roleName
      }
      employee {
        id
        isManager
        reports {
          id
        }
      }
    }
    organization {
      id
      name
      permissionSettings {
        id
        teamCompensationAccess
      }
    }
  }
`;

export const GET_USER_WITH_ACCESS_CONTROLS = gql`
  ${POSITION_FIELDS_MINIMAL}
  ${NOUN_SCOPES_FIELDS}
  query GetUserWithAccessControls($userId: Int!) {
    userRecord(id: $userId) {
      id
      name
      email
      photoURL
      isDisabled
      createdAt
      userAccessGrant {
        id
        roleName
        nounScopes {
          ...NounScopesFields
        }
      }
      employee {
        id
        isManager
        reports {
          id
          displayName
          activeEmployment {
            id
            position {
              ...PositionFieldsMinimal
            }
          }
        }
      }
    }
    organization {
      id
      permissionSettings {
        id
        teamCompensationAccess
      }
    }
  }
`;

export const CURRENT_USER_PERMISSIONS = gql`
  ${NOUN_SCOPES_FIELDS}
  query GetCurrentUserPermissions {
    whoami {
      id
      uid
      email
      employeeId
      displayName
      createdAt
      photoURL
      emailVerified
      organization {
        id
        name
        hasActiveCompCycle
        permissionSettings {
          id
          teamCompensationAccess
        }
      }

      # Permissions V2
      userAccessGrant {
        roleName
        nounScopes {
          ...NounScopesFields
        }
      }
    }
  }
`;

export const GET_BUSINESS_STRUCTURE_SETTINGS = gql`
  query GetBusinessStructureSettings {
    organization {
      id
      name
    }
    compStructure {
      id
      businessStructure
    }
  }
`;

export const GET_EQUITY_SETTINGS = gql`
  ${OFFER_CONFIG_FIELDS}
  query GetEquitySettings {
    valuation {
      id
      activeAt
      estimatedDilution
      financingStage
      fdso
      strikePrice
      valuation
    }
    organization {
      id
      name
      defaultCurrency
    }
    compStructure {
      id
      bandPointTypes
      cashBandTypes
      equityBandTypes
      availableCashBandTypes
      availableEquityBandTypes
      businessStructure
      companyDescription
      equityGrantTypes
      showPercentOwnership
      showValuation
      vestingCliff
      vestingMonths
      equityGrantMethod
      refreshGrantValue
    }
    offerConfig {
      ...OfferConfigFields
    }
  }
`;

export const GET_PHILOSOPHY = gql`
  query GetPhilosophy {
    philosophy {
      id
      json
      author {
        id
        name
        email
      }
      isActive
      createdAt
    }
  }
`;

export const GET_CURRENCIES = gql`
  query GetCurrencies {
    currencies {
      id
      code
      exchangeRate
      isDefault
    }
  }
`;
export const GET_CURRENCIES_SETTINGS = gql`
  query GetCurrenciesSettings {
    currencies {
      id
      code
      exchangeRate
      isDefault
    }
    organization {
      id
      name
    }
  }
`;

export const GET_MERGE_CONFIGS = gql`
  query GetMergeConfigs {
    mergeConnectionConfigs {
      id
      integrationName
      integrationType
      imageUrl
      lastSyncAt
      originId
      isManualUpload
      author {
        id
        name
        photoURL
      }
    }
  }
`;

export const GET_NEEDS_MAPPING_COUNTS = gql`
  query GetNeedsMappingCounts {
    countEmployeesNeedingLeveling
    countLocationsNeedingMapping
  }
`;

export const GET_EMPLOYEE_ANALYTICS_COUNTS_AND_TIMESERIES = gql`
  query GetEmployeeAnalytics(
    $groupByFilterOpts: GroupByFilterOptions!
    $allTimeSeriesOpts: TimeSeriesOptions!
    $lastYearTimeSeriesOpts: TimeSeriesOptions!
  ) {
    employeeAnalytics {
      count(options: $groupByFilterOpts) {
        label
        value
      }
      allTimeTimeSeries: timeSeries(options: $allTimeSeriesOpts) {
        label
        values {
          label
          value
        }
      }
      lastYearTimeSeries: timeSeries(options: $lastYearTimeSeriesOpts) {
        label
        values {
          label
          value
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_ANALYTICS_QUARTILES = gql`
  query GetEmployeeAnalyticsQuartiles(
    $groupByFilterOpts: GroupByFilterOptions!
  ) {
    employeeAnalytics {
      quartiles(options: $groupByFilterOpts) {
        label
        values
        sampleSize
      }
    }
  }
`;

export const GET_BENEFITS_PACKAGES = gql`
  ${BENEFITS_PACKAGE_FIELDS}
  query GetBenefitsPackages {
    benefitsPackages {
      ...BenefitsPackageFields
    }
  }
`;

export const GET_ORG_PERMISSION_SETTINGS = gql`
  query GetPermissionSettings {
    organization {
      id
      permissionSettings {
        id
        canRecruitersViewAnonymizedStatistics
        teamCompensationAccess
        anonymizedCompensationStatisticsAccess
        anonymizedCompensationEmployeeThreshold
        automatedManagerAccess
        employeeBandAccess
      }
    }
  }
`;

export const GET_RECOMMENDATIONS_CSV_EXPORT = gql`
  query GetCompRecommendationsCsvExport($compCycleId: Int!) {
    exportCompRecommendationsToCsv: requestCompCycleRecsCSV(
      compCycleId: $compCycleId
    ) {
      data
    }
  }
`;

export const GET_EMPLOYEE_RECOMMENDATIONS_CSV_EXPORT = gql`
  query GetEmployeeRecommendationsCsvExport(
    $compCycleId: Int!
    $employeeIds: [Int!]
  ) {
    exportEmployeeRecommendationsToCsv: requestEmployeeRecsCSV(
      compCycleId: $compCycleId
      employeeIds: $employeeIds
    ) {
      data
    }
  }
`;

export const GET_ACTIVE_COMP_CYCLES = gql`
  query GetActiveCompCycles {
    activeCompCycles {
      id
      endedAt
    }
  }
`;

export const GET_COMP_CYCLES = gql`
  query GetCompCycles {
    compCycles {
      id
      name
      endedAt
      deletedAt
      createdAt
    }
  }
`;

export const GET_COMP_CYCLE = gql`
  query GetCompCycle($id: Int!) {
    compCycle(id: $id) {
      id
      name
      endedAt
    }
  }
`;
