import { FetchResult, gql, useMutation } from "@apollo/client";
import { enqueueSnackbar } from "notistack";
import { useCallback } from "react";
import {
  AddGuidanceRange,
  AddGuidanceRangeVariables,
  AddPerfRatingOption,
  AddPerfRatingOptionVariables,
  ApplyGuidanceToAll,
  ApplyGuidanceToAllVariables,
  BuildEmptyMatrix,
  BuildEmptyMatrixVariables,
  CalculatePotentialParticipants,
  CalculatePotentialParticipantsVariables,
  DeleteGuidanceRange,
  DeleteGuidanceRangeVariables,
  DeleteMatrix,
  DeleteMatrixVariables,
  DeletePerfRatingOption,
  DeletePerfRatingOptionVariables,
  PotentialParticipantsInput,
  SaveMatrix,
  SaveMatrixVariables,
  UpdateGuidanceRange,
  UpdateGuidanceRangeVariables,
  UpdateMatrixEligibilityRules,
  UpdateMatrixEligibilityRulesInput,
  UpdateMatrixEligibilityRulesVariables,
  UpdateMatrixGuide,
  UpdateMatrixGuideVariables,
  UpdateMatrixInput,
  UpdateMatrixName,
  UpdateMatrixNameInput,
  UpdateMatrixNameVariables,
  UpdatePerfRatingOption,
  UpdatePerfRatingOptionVariables,
  ValidatePotentialParticipants,
  ValidatePotentialParticipantsInput,
  ValidatePotentialParticipantsVariables,
} from "src/__generated__/graphql";

const DELETE_PERF_RATING_OPTION = gql`
  mutation DeletePerfRatingOption($data: DeleteGuidanceItemInput!) {
    deletePerfRatingOption(data: $data)
  }
`;

export function useDeletePerfRatingOption(): (id: number) => Promise<unknown> {
  const [deletePerfRatingOption] = useMutation<
    DeletePerfRatingOption,
    DeletePerfRatingOptionVariables
  >(DELETE_PERF_RATING_OPTION);

  return useCallback(
    (id: number) => deletePerfRatingOption({ variables: { data: { id } } }),
    [deletePerfRatingOption]
  );
}

const DELETE_GUIDANCE_RANGE = gql`
  mutation DeleteGuidanceRange($data: DeleteGuidanceItemInput!) {
    deleteGuidanceRange(data: $data)
  }
`;

export function useDeleteGuidanceRange(): (id: number) => Promise<unknown> {
  const [deleteGuidanceRange] = useMutation<
    DeleteGuidanceRange,
    DeleteGuidanceRangeVariables
  >(DELETE_GUIDANCE_RANGE);

  return useCallback(
    (id: number) => deleteGuidanceRange({ variables: { data: { id } } }),
    [deleteGuidanceRange]
  );
}

const NEW_MATRIX_OPTION = gql`
  fragment NewMatrix_matrixPerfRatingOption on MatrixPerfRatingOption {
    id
    name
    rank
    organizationId
  }
`;

const NEW_MATRIX_RANGE = gql`
  fragment NewMatrix_matrixRange on MatrixRange {
    id
    rangeStart
    organizationId
  }
`;

const NEW_MATRIX_GUIDE = gql`
  ${NEW_MATRIX_OPTION}
  ${NEW_MATRIX_RANGE}
  fragment NewMatrix_matrixGuide on MatrixGuide {
    id
    percent
    matrixRange {
      id
      ...NewMatrix_matrixRange
    }
    matrixPerfRatingOption {
      id
      ...NewMatrix_matrixPerfRatingOption
    }
    matrixId
    matrixPerfRatingOptionId
    matrixRangeId
    organizationId
  }
`;

const NEW_MATRIX = gql`
  ${NEW_MATRIX_GUIDE}
  fragment NewMatrix_matrix on Matrix {
    id
    siblingMatrixId
    type
    name
    eligibilityRules
    eligibleParticipants
    matrixGuides {
      id
      ...NewMatrix_matrixGuide
    }
  }
`;

const BUILD_EMPTY_MATRIX = gql`
  ${NEW_MATRIX}
  mutation BuildEmptyMatrix($data: BuildEmptyMatrixInput!) {
    buildEmptyMatrix(data: $data) {
      id
      ...NewMatrix_matrix
    }
  }
`;

export function useBuildEmptyMatrix(
  compCycleId: number
): () => Promise<FetchResult<BuildEmptyMatrix>> {
  const [buildEmptyMatrix] = useMutation<
    BuildEmptyMatrix,
    BuildEmptyMatrixVariables
  >(BUILD_EMPTY_MATRIX);

  return useCallback(
    () => buildEmptyMatrix({ variables: { data: { compCycleId } } }),
    [buildEmptyMatrix, compCycleId]
  );
}

const APPLY_GUIDANCE_TO_ALL = gql`
  mutation ApplyGuidanceToAll($data: ApplyGuidanceToAllInput!) {
    applyGuidanceToAll(data: $data) {
      participants {
        subjectId
        compCycleId
      }
    }
  }
`;

export function useApplyGuidanceToAll(
  compCycleId: number
): () => Promise<boolean> {
  const [applyGuidanceToAll] = useMutation<
    ApplyGuidanceToAll,
    ApplyGuidanceToAllVariables
  >(APPLY_GUIDANCE_TO_ALL);

  return useCallback(
    () =>
      applyGuidanceToAll({ variables: { data: { compCycleId } } })
        .then(() => true)
        .catch((err: string) => {
          enqueueSnackbar(err, { variant: "error" });
          return false;
        }),
    [compCycleId, applyGuidanceToAll]
  );
}

const UPDATE_MATRIX_NAME = gql`
  mutation UpdateMatrixName($data: UpdateMatrixNameInput!) {
    updateMatrixName(data: $data) {
      id
      name
    }
  }
`;

export function useUpdateMatrixName(): (
  data: UpdateMatrixNameInput
) => Promise<FetchResult<UpdateMatrixName>> {
  const [updateMatrixName] = useMutation<
    UpdateMatrixName,
    UpdateMatrixNameVariables
  >(UPDATE_MATRIX_NAME);

  return useCallback(
    (data) => updateMatrixName({ variables: { data } }),
    [updateMatrixName]
  );
}

const UPDATE_MATRIX_ELIGIBILITY_RULES = gql`
  mutation UpdateMatrixEligibilityRules(
    $data: UpdateMatrixEligibilityRulesInput!
  ) {
    updateMatrixEligibilityRules(data: $data)
  }
`;

export function useUpdateMatrixEligibilityRules(): (
  data: UpdateMatrixEligibilityRulesInput
) => Promise<FetchResult<UpdateMatrixEligibilityRules>> {
  const [updateMatrixEligibilityRules] = useMutation<
    UpdateMatrixEligibilityRules,
    UpdateMatrixEligibilityRulesVariables
  >(UPDATE_MATRIX_ELIGIBILITY_RULES);

  return useCallback(
    (data) => updateMatrixEligibilityRules({ variables: { data } }),
    [updateMatrixEligibilityRules]
  );
}

const DELETE_MATRIX = gql`
  mutation DeleteMatrix($matrixId: Int!) {
    deleteMatrix(matrixId: $matrixId)
  }
`;

export function useDeleteMatrix(): (
  matrixId: number
) => Promise<FetchResult<{ deleteMatrix: boolean }>> {
  const [deleteMatrix] = useMutation<DeleteMatrix, DeleteMatrixVariables>(
    DELETE_MATRIX
  );

  return useCallback(
    (matrixId) => deleteMatrix({ variables: { matrixId } }),
    [deleteMatrix]
  );
}

const UPDATE_MATRIX_GUIDANCE = gql`
  mutation UpdateMatrixGuide($data: UpdateMatrixGuideInput!) {
    updateMatrixGuide(data: $data) {
      id
      percent
    }
  }
`;

export function useUpdateMatrixGuide(): (
  id: number,
  percent: number,
  matrixId: number
) => Promise<unknown> {
  const [updateMatrixGuide] = useMutation<
    UpdateMatrixGuide,
    UpdateMatrixGuideVariables
  >(UPDATE_MATRIX_GUIDANCE);

  return useCallback(
    (id: number, percent: number, matrixId: number) =>
      updateMatrixGuide({
        variables: { data: { id, percent, matrixId } },
      }),
    [updateMatrixGuide]
  );
}

const MATRIX = gql`
  fragment updateData_matrix on Matrix {
    id
    siblingMatrixId
    type
    name
    eligibilityRules
    eligibleParticipants
    matrixGuides {
      id
      percent
      matrixRange {
        id
        rangeStart
        organizationId
      }
      matrixPerfRatingOption {
        id
        name
        rank
        organizationId
      }
      matrixId
      matrixPerfRatingOptionId
      matrixRangeId
      organizationId
    }
  }
`;

const ADD_PERF_RATING_OPTION = gql`
  ${MATRIX}
  mutation AddPerfRatingOption($data: AddGuidanceInput!) {
    addPerfRatingOption(data: $data) {
      id
      ...updateData_matrix
    }
  }
`;

export function useAddPerfRatingOption(
  matrixId: number
): () => Promise<unknown> {
  const [addPerfRatingOption] = useMutation<
    AddPerfRatingOption,
    AddPerfRatingOptionVariables
  >(ADD_PERF_RATING_OPTION);

  return useCallback(
    () =>
      addPerfRatingOption({
        variables: { data: { matrixId } },
      }),
    [addPerfRatingOption, matrixId]
  );
}

const ADD_GUIDANCE_RANGE = gql`
  ${MATRIX}
  mutation AddGuidanceRange($data: AddGuidanceInput!) {
    addGuidanceRange(data: $data) {
      id
      ...updateData_matrix
    }
  }
`;

export function useAddGuidanceRange(matrixId: number): () => Promise<unknown> {
  const [addGuidanceRange] = useMutation<
    AddGuidanceRange,
    AddGuidanceRangeVariables
  >(ADD_GUIDANCE_RANGE);

  return useCallback(
    () =>
      addGuidanceRange({
        variables: { data: { matrixId } },
      }),
    [addGuidanceRange, matrixId]
  );
}

const UPDATE_PERF_RATING_OPTION = gql`
  mutation UpdatePerfRatingOption($data: UpdateMatrixPerfRatingOptionInput!) {
    updatePerfRatingOption(data: $data) {
      id
      name
    }
  }
`;

export function useUpdatePerfRatingOption(): (
  id: number,
  newName: string
) => Promise<unknown> {
  const [updatePerfRatingOption] = useMutation<
    UpdatePerfRatingOption,
    UpdatePerfRatingOptionVariables
  >(UPDATE_PERF_RATING_OPTION);

  return useCallback(
    (id: number, newName: string) =>
      updatePerfRatingOption({
        variables: { data: { id, name: newName } },
      }),
    [updatePerfRatingOption]
  );
}

const UPDATE_GUIDANCE_RANGE = gql`
  mutation UpdateGuidanceRange($data: UpdateMatrixRangeInput!) {
    updateGuidanceRange(data: $data) {
      id
      rangeStart
    }
  }
`;

export function useUpdateGuidanceRange(): (
  id: number,
  rangeStart: number
) => Promise<unknown> {
  const [updateGuidanceRange] = useMutation<
    UpdateGuidanceRange,
    UpdateGuidanceRangeVariables
  >(UPDATE_GUIDANCE_RANGE);

  return useCallback(
    (id: number, rangeStart: number) =>
      updateGuidanceRange({
        variables: { data: { id, rangeStart } },
      }),
    [updateGuidanceRange]
  );
}

const CALCULATE_POTENTIAL_PARTICIPANTS = gql`
  mutation CalculatePotentialParticipants($data: PotentialParticipantsInput!) {
    calculatePotentialParticipants(data: $data)
  }
`;

export function useCalculatePotentialParticipants(): {
  execute: (
    data: PotentialParticipantsInput
  ) => Promise<FetchResult<CalculatePotentialParticipants>>;
  loading: boolean;
} {
  const [calculatePotentialParticipants, { loading }] = useMutation<
    CalculatePotentialParticipants,
    CalculatePotentialParticipantsVariables
  >(CALCULATE_POTENTIAL_PARTICIPANTS);

  const execute = useCallback(
    (data: PotentialParticipantsInput) =>
      calculatePotentialParticipants({
        variables: {
          data,
        },
      }),
    [calculatePotentialParticipants]
  );

  return { execute, loading };
}

const VALIDATE_POTENTIAL_PARTICIPANTS = gql`
  mutation ValidatePotentialParticipants(
    $data: ValidatePotentialParticipantsInput!
  ) {
    validatePotentialParticipants(data: $data)
  }
`;

export function useValidatePotentialParticipants(): (
  data: ValidatePotentialParticipantsInput
) => Promise<FetchResult<ValidatePotentialParticipants>> {
  const [validatePotentialParticipants] = useMutation<
    ValidatePotentialParticipants,
    ValidatePotentialParticipantsVariables
  >(VALIDATE_POTENTIAL_PARTICIPANTS);

  return useCallback(
    (data) =>
      validatePotentialParticipants({
        variables: {
          data,
        },
      }),
    [validatePotentialParticipants]
  );
}

const SAVE_MATRIX = gql`
  mutation SaveMatrix($data: UpdateMatrixInput!) {
    updateMatrix(data: $data) {
      id
    }
  }
`;

export function useSaveMatrix(): (
  data: UpdateMatrixInput
) => Promise<FetchResult<SaveMatrix>> {
  const [saveMatrix] = useMutation<SaveMatrix, SaveMatrixVariables>(
    SAVE_MATRIX
  );

  return useCallback(
    (data: UpdateMatrixInput) => saveMatrix({ variables: { data } }),
    [saveMatrix]
  );
}
