import { Tooltip } from "@material-ui/core";
import { ErrorColorIcon } from "src/components/AssembleIcons/Small/ErrorColorIcon";
import { SuccessColorIcon } from "src/components/AssembleIcons/Small/SuccessColorIcon";
import { WarningColorIcon } from "src/components/AssembleIcons/Small/WarningColorIcon";
import { ReviewerStats } from "./Context/ProgressPageContext";

type Props = {
  reviewStats: ReviewerStats | undefined;
  isPastPhase: boolean;
};
export function CompletionStatus({ reviewStats, isPastPhase }: Props) {
  if (!reviewStats) {
    return null;
  }
  const { isSkipped, requested, completed, skippedName, reviewerName } =
    reviewStats;
  if (isPastPhase) {
    if (isSkipped) {
      return (
        <Tooltip
          title={`${skippedName !== null ? skippedName : "Another user"} made requests on ${reviewerName}'s behalf`}
        >
          <span>
            <WarningColorIcon />
          </span>
        </Tooltip>
      );
    }
    if (requested === completed) {
      return (
        <Tooltip title="Complete">
          <span>
            <SuccessColorIcon />
          </span>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={`${completed} / ${requested} requests made`}>
        <span>
          <ErrorColorIcon />
        </span>
      </Tooltip>
    );
  }

  return (
    <>
      {completed}/{requested}
    </>
  );
}
