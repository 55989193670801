import { gql } from "@apollo/client";
import { add } from "@asmbl/shared/money";
import { Divider, makeStyles } from "@material-ui/core";
import { BudgetVariableDetail2 } from "src/components/CompCycle/CompCycleBudgetBar/BudgetVariableDetail2";
import { RecItemWithSubject } from "src/models/CompRecommendation";
import {
  BudgetBarComponents2_compCycle,
  BudgetBarComponents2_compCycleBudget,
  BudgetBarComponents2_valuation,
  CashCompType,
  RecItemType,
} from "../../../__generated__/graphql";
import { BudgetType } from "../../../models/Budget";
import { GRAY_4, GRAY_6, GRAY_7, PURPLE_1, WHITE } from "../../../theme";
import { CompCycleGrouping } from "../types";
import { BudgetCashDetail } from "./BudgetCashDetail";
import { BudgetEquityDetail } from "./BudgetEquityDetail";

const useStyles = makeStyles((theme) => ({
  pane: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  root: {
    top: "auto",
    bottom: 0,
    backgroundColor: "rgba(255, 0, 0, 0)",
    boxShadow: "none",
    left: theme.spacing(10.125), // account for navbar and border
  },
  tabContainer: {
    display: "flex",
    overflow: "hidden",
    paddingTop: "5px",
    maxHeight: "2rem",
  },
  tab: {
    backgroundColor: WHITE,
    minWidth: "7.75rem",
    height: "2.5rem",
    boxShadow: `0px 0px 0px 1px ${GRAY_6}`,
    borderRadius: "0px 5px 0px 0px",
    zIndex: 1,
    justifySelf: "flex-start",
  },
  tabButton: {
    padding: theme.spacing(0, 0, 0, 1.5),
  },
  tabText: {
    color: GRAY_4,
    fontWeight: 700,
    fontSize: "0.75rem",
    lineHeight: "140%",
    "&:hover": {
      color: PURPLE_1,
    },
  },
  rotatedIcon: {
    transform: "rotate(180deg)",
  },
  toolbar: {
    height: "5.75rem",
    backgroundColor: WHITE,
    boxShadow: `0px -1px 0px ${GRAY_6}`,
    width: "100%",
  },
  totalBudgetContainer: {
    backgroundColor: GRAY_7,
    borderRadius: "5px",
    height: "3.75rem",
    width: "6.25rem",
    margin: theme.spacing(0, 2, 0, 1.5),
    padding: theme.spacing(1.25, 0, 0, 2),
  },
  totalBudgetText: {
    color: GRAY_4,
    fontSize: "1.125rem",
    letterSpacing: "-0.25px",
    lineHeight: "110%",
    fontWeight: 400,
  },
  subComponentContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    height: "100%",
  },
  verticalDivider: {
    margin: theme.spacing(0, 2),
  },
  toolbarHeight: {
    height: theme.spacing(11.5),
  },
}));

type Props = {
  filter: CompCycleGrouping;
  recommendationItems: RecItemWithSubject[];
  compCycle: BudgetBarComponents2_compCycle;
  budget: BudgetBarComponents2_compCycleBudget | null;
  valuation: BudgetBarComponents2_valuation;
};

export const BudgetBarComponents2 = ({
  filter,
  recommendationItems,
  compCycle,
  budget,
  valuation,
}: Props): JSX.Element => {
  const classes = useStyles();
  const {
    allowSalary,
    allowSalaryMarket,
    allowSalaryMerit,
    allowSalaryPromotion,
    allowActualRecurringBonus,
    allowTargetRecurringBonus,
    allowTargetCommission,
    allowBonus,
    allowEquity,
    budgetSpends,
  } = compCycle;

  return (
    <div className={classes.subComponentContainer}>
      {/* salary pane components */}
      {allowSalaryPromotion && filter === "salary" && (
        <>
          <BudgetCashDetail
            valueUsed={budgetSpends[RecItemType.PROMOTION]}
            compCycleBudget={budget?.salaryPromotion ?? undefined}
            budgetType={BudgetType.PROMOTION}
            items={recommendationItems.filter(
              (i) => i.recommendationType === RecItemType.PROMOTION
            )}
          />
          <VerticalDivider />
        </>
      )}
      {allowSalaryMarket && filter === "salary" && (
        <>
          <BudgetCashDetail
            valueUsed={budgetSpends[RecItemType.MARKET]}
            compCycleBudget={budget?.salaryMarket}
            budgetType={BudgetType.MARKET}
            items={recommendationItems.filter(
              (i) => i.recommendationType === RecItemType.MARKET
            )}
          />
          <VerticalDivider />
        </>
      )}
      {allowSalaryMerit && filter === "salary" && (
        <>
          <BudgetCashDetail
            valueUsed={budgetSpends[RecItemType.MERIT_INCREASE]}
            compCycleBudget={budget?.salaryMerit ?? undefined}
            budgetType={BudgetType.MERIT}
            items={recommendationItems.filter(
              (i) => i.recommendationType === RecItemType.MERIT_INCREASE
            )}
          />
          <VerticalDivider />
        </>
      )}
      {/* totals components */}
      {allowSalary && (filter === "salary" || filter === "all") && (
        <>
          <BudgetCashDetail
            valueUsed={add(
              budgetSpends[RecItemType.MARKET],
              add(
                budgetSpends[RecItemType.PROMOTION],
                budgetSpends[RecItemType.MERIT_INCREASE]
              )
            )}
            items={recommendationItems.filter(
              (i) =>
                i.recommendationType === RecItemType.MARKET ||
                i.recommendationType === RecItemType.PROMOTION ||
                i.recommendationType === RecItemType.MERIT_INCREASE
            )}
            compCycleBudget={budget?.salary ?? undefined}
            budgetType={BudgetType.TOTAL_SALARY}
          />
          <VerticalDivider />
        </>
      )}
      {allowTargetCommission && (filter === "target" || filter === "all") && (
        <>
          <BudgetVariableDetail2
            valueUsed={budgetSpends[RecItemType.TARGET_COMMISSION]}
            incrementalValueUsed={budgetSpends["TARGET_COMMISSION_INCREMENTAL"]}
            compCycleBudget={budget?.targetCommission ?? undefined}
            items={recommendationItems.filter(
              (i) => i.recommendationType === RecItemType.TARGET_COMMISSION
            )}
            budgetType={BudgetType.TARGET_COMMISSION}
            variableCompType={CashCompType.COMMISSION}
          />
          <VerticalDivider />
        </>
      )}
      {allowTargetRecurringBonus &&
        (filter === "target" || filter === "all") && (
          <>
            <BudgetVariableDetail2
              valueUsed={budgetSpends[RecItemType.TARGET_RECURRING_BONUS]}
              incrementalValueUsed={
                budgetSpends["TARGET_RECURRING_BONUS_INCREMENTAL"]
              }
              compCycleBudget={budget?.targetRecurringBonus ?? undefined}
              items={recommendationItems.filter(
                (i) =>
                  i.recommendationType === RecItemType.TARGET_RECURRING_BONUS
              )}
              budgetType={BudgetType.TARGET_RECURRING_BONUS}
              variableCompType={CashCompType.RECURRING_BONUS}
            />
            <VerticalDivider />
          </>
        )}
      {allowActualRecurringBonus &&
        (filter === "actual" || filter === "all") && (
          <>
            <BudgetCashDetail
              valueUsed={budgetSpends[RecItemType.ACTUAL_RECURRING_BONUS]}
              compCycleBudget={budget?.actualRecurringBonus ?? undefined}
              budgetType={BudgetType.ACTUAL_RECURRING_BONUS}
              items={recommendationItems.filter(
                (item) =>
                  item.recommendationType === RecItemType.ACTUAL_RECURRING_BONUS
              )}
            />
            <VerticalDivider />
          </>
        )}
      {allowBonus && (filter === "actual" || filter === "all") && (
        <>
          <BudgetCashDetail
            valueUsed={budgetSpends[RecItemType.MERIT_BONUS]}
            compCycleBudget={budget?.bonus ?? undefined}
            budgetType={BudgetType.ONE_TIME_BONUS}
            items={recommendationItems.filter(
              (item) => item.recommendationType === RecItemType.MERIT_BONUS
            )}
          />
          <VerticalDivider />
        </>
      )}
      {allowEquity && (filter === "equity" || filter === "all") && (
        <>
          <BudgetEquityDetail
            valueUsed={budgetSpends[RecItemType.EQUITY_GRANT]}
            compCycleBudget={budget?.equity ?? undefined}
            items={recommendationItems.filter(
              (item) => item.recommendationType === RecItemType.EQUITY_GRANT
            )}
            budgetType={BudgetType.EQUITY}
            valuation={valuation}
          />
          <VerticalDivider />
        </>
      )}
    </div>
  );
};

function VerticalDivider(): JSX.Element {
  const classes = useStyles();
  return (
    <Divider
      orientation="vertical"
      flexItem
      className={classes.verticalDivider}
    />
  );
}

BudgetBarComponents2.fragments = {
  compCycle: gql`
    fragment BudgetBarComponents2_compCycle on CompCycle2 {
      id
      allowSalary
      allowSalaryMarket
      allowSalaryMerit
      allowSalaryPromotion
      allowActualRecurringBonus
      allowTargetCommission
      allowTargetRecurringBonus
      allowEquity
      allowBonus
      budgetSpends(input: $filter) {
        ACTUAL_RECURRING_BONUS
        EQUITY_GRANT
        MARKET
        MERIT_BONUS
        MERIT_INCREASE
        PROMOTION
        TARGET_COMMISSION
        TARGET_COMMISSION_INCREMENTAL
        TARGET_RECURRING_BONUS
        TARGET_RECURRING_BONUS_INCREMENTAL
      }
    }
  `,
  compCycleBudget: gql`
    fragment BudgetBarComponents2_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      salary
      salaryMarket
      salaryMerit
      salaryPromotion
      bonus
      equity
      targetRecurringBonus
      targetCommission
      actualRecurringBonus
    }
  `,
  valuation: gql`
    ${BudgetEquityDetail.fragments.valuation}
    fragment BudgetBarComponents2_valuation on Valuation {
      ...BudgetEquityDetail_valuation
    }
  `,
};
