import { gql } from "@apollo/client";
import { money } from "@asmbl/shared/money";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import fastDeepEqual from "fast-deep-equal";
import { useState } from "react";
import { EmployeeAllocationRow_employee as Employee } from "../../../../__generated__/graphql";
import { CompCycleGrouping } from "../../../../components/CompCycle/types";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import {
  Allocation,
  allocationToNumber,
  AllocationUnit,
  BudgetAllocation,
  CompComponent,
  CompSubComponent,
  convertAllocationToCash,
  isCompSubComponent,
} from "../../../../models/Budget";
import { DraftBudgetInput } from "../../../../mutations/CompCycleBudget";
import { useAllocateBudgetsContext } from "../Context/AllocateBudgetsContext";
import { BudgetNameCell } from "./BudgetNameCell";
import { BudgetNumberInput } from "./BudgetNumberInput";
import { IndentationCells } from "./Connectors";
import { LazyReportingTier } from "./LazyReportingTier";

const useStyles = makeStyles(() => ({
  reportCell: {
    width: "6rem",
  },
}));

type Props = {
  budgetAllocation: BudgetAllocation;
  publishedAllocation: BudgetAllocation | null;
  employee: Employee;
  compComponent: CompCycleGrouping;
  equityDisplay: AllocationUnit;
  onSaveDraft: (
    employeeId: number | null,
    budget: DraftBudgetInput
  ) => Promise<unknown>;
  indentation: IndentationCells;
  isLastRow: boolean;
  expandable?: boolean;
};

export function EmployeeAllocationRow({
  budgetAllocation,
  publishedAllocation,
  employee,
  compComponent,
  equityDisplay,
  onSaveDraft,
  indentation,
  isLastRow,
  expandable,
}: Props): JSX.Element {
  const classes = useStyles();
  const { valuation } = useAllocateBudgetsContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const { defaultCurrencyCode } = useCurrencies();

  const reportingIndentation: IndentationCells =
    indentation.length > 0 && isLastRow
      ? [...indentation.slice(0, -1), "hidden", "visible"]
      : indentation.concat("visible");

  const handleValueChange =
    (name: CompComponent | CompSubComponent, oldAllocation: Allocation) =>
    (newValue: number | null) => {
      const newAllocation: Allocation =
        oldAllocation.unit === AllocationUnit.CASH
          ? {
              unit: AllocationUnit.CASH,
              value:
                newValue !== null ? money(newValue, defaultCurrencyCode) : null,
            }
          : { unit: AllocationUnit.UNIT, value: newValue };

      return onSaveDraft(employee.id, {
        [name]: convertAllocationToCash(newAllocation, valuation),
      });
    };

  const subComponents = budgetAllocation.allocations.filter((x) =>
    isCompSubComponent(x.compComponent)
  );
  const parentBudgets = budgetAllocation.allocations.filter(
    (x) => !isCompSubComponent(x.compComponent)
  );

  const publishedSubComponents = publishedAllocation?.allocations.filter((x) =>
    isCompSubComponent(x.compComponent)
  );
  const publishedParentBudgets = publishedAllocation?.allocations.filter(
    (x) => !isCompSubComponent(x.compComponent)
  );

  return (
    <>
      <TableRow data-cy="employee-allocation-row">
        <BudgetNameCell
          compCycleId={budgetAllocation.compCycleId}
          employee={employee}
          expandable={expandable}
          indentation={indentation}
          expanded={isExpanded}
          setExpanded={setIsExpanded}
          isLastRowCell={isLastRow}
        />

        <TableCell align="right" className={classes.reportCell} width="10%">
          {employee.directReportsCount}
        </TableCell>

        <TableCell align="right" className={classes.reportCell} width="10%">
          {employee.indirectReportsCount}
        </TableCell>

        {subComponents.map((allocation, idx) => {
          const blankState =
            allocation.value === null && publishedAllocation === null;

          const hasUnpublishedChanges =
            !blankState &&
            !fastDeepEqual(allocation, publishedSubComponents?.[idx]);

          return (
            <BudgetNumberInput
              key={`${allocation.compComponent}-${
                allocationToNumber(allocation) ?? "null"
              }`}
              defaultValue={allocationToNumber(allocation)}
              onValueChange={handleValueChange(
                allocation.compComponent,
                allocation
              )}
              inputVariant={allocation.unit}
              hasUnpublishedChanges={hasUnpublishedChanges}
              testTag={`budget-input-${allocation.compComponent}`}
            />
          );
        })}
        {parentBudgets.map((allocation, idx) => {
          const blankState =
            allocation.value === null && publishedAllocation === null;

          const hasUnpublishedChanges =
            !blankState &&
            !fastDeepEqual(allocation, publishedParentBudgets?.[idx]);

          return (
            <BudgetNumberInput
              key={`${allocation.compComponent}-${
                allocationToNumber(allocation) ?? "null"
              }`}
              defaultValue={allocationToNumber(allocation)}
              onValueChange={handleValueChange(
                allocation.compComponent,
                allocation
              )}
              inputVariant={allocation.unit}
              hasUnpublishedChanges={hasUnpublishedChanges}
              testTag={`budget-input-${allocation.compComponent}`}
            />
          );
        })}
      </TableRow>
      {isExpanded && (
        <LazyReportingTier
          compCycleId={budgetAllocation.compCycleId}
          employeeId={employee.id}
          compComponent={compComponent}
          equityDisplay={equityDisplay}
          onSaveDraft={onSaveDraft}
          indentation={reportingIndentation}
          subComponentCount={subComponents.length}
        />
      )}
    </>
  );
}

EmployeeAllocationRow.fragments = {
  employee: gql`
    ${BudgetNameCell.fragments.employee}
    fragment EmployeeAllocationRow_employee on MinimalEmployee {
      id
      directReportsCount
      indirectReportsCount
      ...BudgetNameCell_employee
    }
  `,
  valuation: gql`
    fragment EmployeeAllocationRow_valuation on Valuation {
      id
      fdso
      valuation
    }
  `,
};
