import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ListIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 8H7.74994H3Z" fill="white" />
      <path
        d="M3 8H7.74994"
        stroke="#425466"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 4L13 4L3 4Z" fill="white" />
      <path
        d="M3 4L13 4"
        stroke="#425466"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12H10.75"
        stroke="#425466"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
