import { gql } from "@apollo/client";
import { mapify, round } from "@asmbl/shared/utils";
import uniqBy from "lodash/uniqBy";
import {
  groupMatrices_matrix as Matrix,
  MatrixGuide,
  MatrixTypeEnum,
  UpdateMatrixInput,
} from "src/__generated__/graphql";
import { Matrix as MatrixComponent } from "./Content/Matrix";
import {
  GuidanceEligibilityPool,
  GuidancePopulation,
  GuideDraft,
} from "./useMultipleMatrices";

export function groupMatrices(matrices: Matrix[]): GuidancePopulation[] {
  const result: GuidancePopulation[] = [];
  const processed = new Set<number>();

  const matrixIdToMatrixMap = mapify(matrices, "id");

  for (const matrix of matrices) {
    const {
      id,
      siblingMatrixId,
      name,
      matrixGuides,
      eligibilityRules,
      eligibleParticipants,
    } = matrix;

    const hasSiblingBeenProcessed =
      siblingMatrixId != null && processed.has(siblingMatrixId);
    const hasBeenProcessed = processed.has(id);

    if (hasBeenProcessed || hasSiblingBeenProcessed) {
      continue;
    }

    const ids = [id];

    if (siblingMatrixId !== null) {
      ids.push(siblingMatrixId);
      processed.add(siblingMatrixId);
    }

    const guidanceId = ids.find(
      (id) => matrixIdToMatrixMap.get(id)?.type === MatrixTypeEnum.MERIT
    );

    const budgetId = ids.find(
      (id) => matrixIdToMatrixMap.get(id)?.type === MatrixTypeEnum.BUDGET
    );

    const siblingMatrix = matrices.find((m) => m.id === matrix.siblingMatrixId);

    // eventually we'll need to use the current matrix + the sibling matrix
    // to fill in the guidance and budget values
    result.push({
      name,
      selected: false,
      eligibilityRules,
      eligibleParticipants,
      edited: false,
      eligibilityValidations: {
        duplicates: [],
      },
      guidance: {
        id: guidanceId,
        type: MatrixTypeEnum.MERIT,
        matrixGuides:
          matrix.type === MatrixTypeEnum.MERIT
            ? matrixGuides
            : siblingMatrix?.matrixGuides,
      },
      budget: {
        id: budgetId,
        type: MatrixTypeEnum.BUDGET,
        matrixGuides:
          matrix.type === MatrixTypeEnum.BUDGET
            ? matrixGuides
            : siblingMatrix?.matrixGuides,
      },
    });

    processed.add(id);
  }

  return result.map((group, index) => ({ ...group, selected: index === 0 }));
}

export const buildMatrix = (
  guides?: (MatrixGuide | GuideDraft)[]
): { label: string | number; id?: string | number }[][] => {
  if (guides == null) {
    return [[]];
  }

  const orderedPerfRatings = uniqBy(
    guides.map((guide) => guide.matrixPerfRatingOption),
    "id"
  ).sort((a, b) => (a.rank < b.rank ? -1 : 1));

  const orderedRanges = uniqBy(
    guides.map((guide) => guide.matrixRange),
    "id"
  ).sort((a, b) => (a.rangeStart < b.rangeStart ? -1 : 1));

  const matrix: { label: string; id?: number | string }[][] = [];

  orderedPerfRatings.forEach((rating, ratingIdx) => {
    matrix[ratingIdx] = [{ label: rating.name, id: rating.id }];
    orderedRanges.forEach((range) => {
      const guide = guides.find(
        (guide) =>
          guide.matrixPerfRatingOption.id === rating.id &&
          guide.matrixRange.id === range.id
      );
      if (guide != null) {
        matrix[ratingIdx].push({
          label: round(guide.percent * 100, 3),
          id: guide.id,
        });
      } else {
        matrix[ratingIdx].push({ label: "-" });
      }
    });
  });

  return matrix;
};

/**
 * given an employeeId, find the guidance population that the employee
 * belongs to
 * @param matrices
 * @param employeeId
 */
export function findMatrixByDuplicateTargetEmployeeId(
  matrices: GuidancePopulation[],
  employeeId: number
): GuidancePopulation[] {
  const result: GuidancePopulation[] = [];

  matrices.forEach((matrix) => {
    if (matrix.eligibilityValidations.duplicates.includes(employeeId)) {
      result.push(matrix);
    }
  });

  return result;
}

export function getUnassignedParticipants(
  allEligible: GuidanceEligibilityPool[],
  matrices: GuidancePopulation[]
): number[] {
  const allAssigned: number[] = [];
  const allUnassigned: number[] = [];

  matrices.forEach((matrix) => {
    matrix.eligibleParticipants?.forEach((participant) => {
      allAssigned.push(participant);
    });
  });

  allEligible.forEach((employee) => {
    if (!allAssigned.includes(employee.subjectId)) {
      allUnassigned.push(employee.subjectId);
    }
  });

  return allUnassigned;
}

export const matrixToUpdateArgs = ({
  id,
  matrixGuides,
}: {
  id?: number;
  matrixGuides?: (MatrixGuide | GuideDraft)[];
}): UpdateMatrixInput => ({
  id: id as number,
  matrixGuides:
    matrixGuides?.map((guide) => ({
      id: (guide.id as number | string).toString(),
      percent: guide.percent,
      matrixId: guide.matrixId,
      matrixPerfRatingOptionId: guide.matrixPerfRatingOptionId.toString(),
      matrixRangeId: guide.matrixRangeId.toString(),
      matrixPerfRatingOption: {
        id: guide.matrixPerfRatingOptionId.toString(),
        rank: guide.matrixPerfRatingOption.rank,
        name: guide.matrixPerfRatingOption.name,
      },
      matrixRange: {
        id: guide.matrixRangeId.toString(),
        rangeStart: guide.matrixRange.rangeStart,
      },
    })) ?? [],
});

groupMatrices.fragments = {
  matrix: gql`
    ${MatrixComponent.fragments.guide}
    fragment groupMatrices_matrix on Matrix {
      id
      siblingMatrixId
      type
      name
      eligibilityRules
      eligibleParticipants
      matrixGuides {
        id
        ...MatrixCard_matrixGuide
      }
    }
  `,
};
