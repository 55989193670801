import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { forwardRef, ReactNode, Ref, useCallback, useState } from "react";
import { EditorStatus } from "../../constants";
import {
  GRAY_1,
  GRAY_10,
  GRAY_2,
  GREEN_2,
  PURPLE_1,
  PURPLE_2,
  RED,
  WHITE,
} from "../../theme";
import { SaveIcon } from "../AssembleIcons/Brand/SaveIcon";
import { WarningAlertIcon } from "../AssembleIcons/Brand/WarningAlertIcon";
import { CircleCheckIcon } from "../AssembleIcons/Small/CircleCheckIcon";

const uniformStyle = (color: string, opacity = 1.0) => ({
  background: color,
  border: `1px solid ${color}`,
  opacity: opacity,
});

const saveButtonColors = new Map([
  [EditorStatus.VIEWING, null],
  [
    EditorStatus.EDITING,
    {
      default: uniformStyle(PURPLE_1),
      hover: {
        background: PURPLE_2,
        border: `1px solid ${GRAY_2}`,
        opacity: 1.0,
      },
      disabled: uniformStyle(`${GRAY_10}`),
    },
  ],
  [
    EditorStatus.SAVING,
    {
      default: uniformStyle(GRAY_1),
      disabled: uniformStyle(GRAY_1),
    },
  ],
  [
    EditorStatus.SAVED,
    {
      default: uniformStyle(GREEN_2),
      disabled: uniformStyle(GREEN_2),
    },
  ],
  [
    EditorStatus.ERROR,
    {
      default: uniformStyle(RED),
      disabled: uniformStyle(RED),
    },
  ],
]);

const useSaveStyles = makeStyles(() => ({
  saveButton: ({ editorStatus }: { editorStatus: EditorStatus }) => {
    const colors = saveButtonColors.get(editorStatus);
    return {
      backgroundColor: colors?.default.background,
      border: colors?.default.border,
      opacity: colors?.default.opacity,
      borderRadius: "5px",
      color: WHITE,
      padding: "7px 22px",
      transition:
        "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover, &:focus": {
        backgroundColor:
          colors?.hover?.background ?? colors?.default.background,
        border: colors?.hover?.border ?? colors?.default.border,
        opacity: colors?.hover?.opacity ?? colors?.default.opacity,
        color: WHITE,
      },
      "&:disabled": {
        backgroundColor: colors?.disabled.background,
        border: colors?.disabled.border,
        opacity: colors?.disabled.opacity,
        color: WHITE,
      },
      width: "114px",

      "& .MuiButton-endIcon": {
        height: "22px",
        marginLeft: "0px",
        marginRight: "0px",
      },
    };
  },
}));

interface SaveButtonProps extends SaveProps {
  onSave: (() => Promise<boolean>) | (() => Promise<void>);
  cooldown?: number;
}

interface ControlledSaveButtonProps extends SaveProps {
  onSave: () => unknown;
  editorStatus: EditorStatus;
}
interface SaveProps {
  disabled?: boolean;
  labels?: {
    default?: ReactNode;
    processing?: ReactNode;
    success?: ReactNode;
  };
  hideEndIcon?: boolean;
  className?: string;
  onAfterSave?: () => void;
}

export const ControlledSaveButton = forwardRef(function ControlledSaveButton(
  {
    editorStatus,
    disabled,
    onSave,
    labels,
    className = "",
    hideEndIcon = false,
  }: ControlledSaveButtonProps,
  ref: Ref<HTMLButtonElement>
): JSX.Element | null {
  const classes = useSaveStyles({ editorStatus });

  switch (editorStatus) {
    case EditorStatus.SAVING:
      return (
        <Button
          ref={ref}
          className={`${className} ${classes.saveButton}`}
          disabled={true}
          endIcon={
            !hideEndIcon && <CircularProgress size={20} color="inherit" />
          }
        >
          {labels?.processing ?? "Saving..."}
          {!hideEndIcon && <>&nbsp;</>}
        </Button>
      );
    case EditorStatus.SAVED:
      return (
        <Button
          ref={ref}
          className={`${className} ${classes.saveButton}`}
          disabled={true}
          endIcon={
            !hideEndIcon && (
              <CircleCheckIcon color={WHITE} height="24px" width="24px" />
            )
          }
        >
          {labels?.success ?? "Saved"}
          {!hideEndIcon && <>&nbsp;</>}
        </Button>
      );
    case EditorStatus.EDITING:
      return (
        <Button
          ref={ref}
          className={`${className} ${classes.saveButton}`}
          endIcon={
            !hideEndIcon && (
              <SaveIcon color={WHITE} width="24px" height="24px" />
            )
          }
          onClick={onSave}
          disabled={disabled}
        >
          {labels?.default ?? "Save"}
          {!hideEndIcon && <>&nbsp;</>}
        </Button>
      );
    case EditorStatus.ERROR:
      return (
        <Button
          ref={ref}
          className={`${className} ${classes.saveButton}`}
          disabled={true}
          endIcon={
            !hideEndIcon && (
              <WarningAlertIcon color={WHITE} width="24px" height="24px" />
            )
          }
        >
          {"Error"}
          {!hideEndIcon && <>&nbsp;</>}
        </Button>
      );
    default:
      return null;
  }
});

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function SaveButton({
  onSave,
  disabled,
  cooldown = 3000,
  labels,
  hideEndIcon = false,
  className = "",
  onAfterSave = () => {
    /* By default, just continue editing */
  },
}: SaveButtonProps): JSX.Element {
  const [status, setStatus] = useState<EditorStatus>(EditorStatus.EDITING);

  const handleSave = useCallback(async () => {
    setStatus(EditorStatus.SAVING);

    try {
      const isSuccess = await onSave();
      if (isSuccess) {
        setStatus(EditorStatus.SAVED);
        await sleep(cooldown);
        onAfterSave();
      }
    } catch (e) {
      /* We set it back to editing next anyways */
    }
    setStatus(EditorStatus.EDITING);
  }, [cooldown, onAfterSave, onSave]);

  return (
    <ControlledSaveButton
      onSave={handleSave}
      editorStatus={status}
      disabled={disabled}
      labels={labels}
      hideEndIcon={hideEndIcon}
      className={className}
      onAfterSave={onAfterSave}
    />
  );
}
