import { InfoBanner } from "src/components/InfoBanner";
import { englishifyList } from "src/language";
import { useMultipleMeritMatrices } from "../useMultipleMatrices";
import { findMatrixByDuplicateTargetEmployeeId } from "../utils";

export function ConflictBanner(): JSX.Element | null {
  const {
    matrices: { selectedMatrix, meritMatrices },
  } = useMultipleMeritMatrices();

  if (selectedMatrix === null) return null;

  const conflicts = selectedMatrix.eligibilityValidations.duplicates;
  const conflictingMatrices = new Set(
    conflicts
      .flatMap((employeeId) =>
        findMatrixByDuplicateTargetEmployeeId(meritMatrices, employeeId)
      )
      .map((m) => m.name)
  );

  if (conflicts.length === 0) return null;

  return (
    <InfoBanner fullWidth level="error">
      We found {conflicts.length} {conflicts.length === 1 ? "person" : "people"}{" "}
      in {englishifyList(Array.from(conflictingMatrices))}
    </InfoBanner>
  );
}
