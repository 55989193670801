import { makeStyles } from "@material-ui/core";
import connector from "../../../../assets/svgs/timeline-connector/connector.svg";
import firstConnector from "../../../../assets/svgs/timeline-connector/first-connector.svg";
import lastConnector from "../../../../assets/svgs/timeline-connector/last-connector.svg";
import { GRAY_1, GRAY_3, GRAY_4, GRAY_6 } from "../../../../theme";

export const useRecItemRowStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-body": {
      border: "none",
    },
  },
  bandVisualizationCell: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  textCell: {
    borderTop: `1px solid ${GRAY_6} !important`,
    paddingBlock: theme.spacing(1),
  },
  noteTextCell: {
    paddingBlock: theme.spacing(1),
    textAlign: "right",
    borderTop: `1px solid ${GRAY_6} !important`,
  },
  rowLabel: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    color: GRAY_1,
  },
  extraRowText: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    color: GRAY_4,
    display: "inline-block",
    paddingRight: "1rem",
  },
  newPosition: {
    height: theme.spacing(0.875),
    fontSize: "0.8125rem",
    letterSpacing: "-0.25px",
    lineHeight: "1rem",
    display: "inline-flex",
    alignItems: "center",
  },
  guidanceLabel: {
    color: GRAY_3,
    float: "right",
  },
  inline: {
    display: "inline",
  },
  canduBar: {
    float: "right",
    margin: 0,
  },
  applyElement: {
    height: "24px",
    marginLeft: "4px",
  },
  noGuidance: {
    border: "none !important",
    paddingTop: "1px !important",

    "&> span > span": {
      color: `${GRAY_3} !important`,
    },
  },
}));

/**
 * HERE THERE BE DRAGONS
 * This is a monstrosity of exact-pixel placements that completely rely on the
 * exact-pixel values in the SVGs. Modifying these are a pain. Change at your
 * own risk.
 */
export const useConnectorStyles = makeStyles(() => ({
  line: {
    height: "43px",
    marginTop: "-7px",
    background: `50% 0% no-repeat url(${connector})`,
  },
  lastConnector: {
    height: "17px",
    marginTop: "-9px",
    background: `50% 0% no-repeat url(${lastConnector})`,
  },
  firstConnector: {
    height: "64px",
    marginTop: "-45px",
    background: `50% 0% no-repeat url(${firstConnector})`,
  },
}));
