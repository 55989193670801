import { toInteger } from "lodash";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useTrack } from "src/analytics";
import { ColumnIds } from "./ColumnOrderContext";

enum URL_PARAMS {
  PAGE = "page",
  PAGE_SIZE = "pageSize",
  SORT_BY = "sortBy",
  SORT_DIR = "sortDir",
}

const paramsToObject = (params: URLSearchParams): Record<string, string> => {
  const entries = params.entries();
  const result: Record<string, string> = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const validSortDirs = new Set(["asc", "desc"]);

type PaginationType = {
  currentPage: number;
  pageSize: number;
  setCurrentPage: (page: number) => void;
  totalCount: number;
  setTotalCount: (count: number) => void;
  setPageSize: (size: number) => void;
  sortBy: string;
  sortDir: "asc" | "desc";
  setSortBy: (property: string) => void;
  setSortDir: (direction: "asc" | "desc") => void;
  setSortParams: (property: string) => void;
  currentEmployeeIds: number[];
  setCurrentEmployeeIds: (ids: number[]) => void;
};

export const PaginationContext = createContext<PaginationType>({
  currentEmployeeIds: [],
  setCurrentEmployeeIds: (_ids: number[]) => {
    // empty default
  },
  currentPage: 0,
  pageSize: 0,
  setCurrentPage: (_page: number) => {
    // empty default
  },
  totalCount: 0,
  setTotalCount: (_count: number) => {
    // empty default
  },
  setPageSize: (_size: number) => {
    // empty default
  },
  sortBy: ColumnIds.NAME,
  sortDir: "asc",
  setSortBy: (_property: string) => {
    // empty default
  },
  setSortDir: (_direction: "asc" | "desc") => {
    // empty default
  },
  setSortParams: (_field: string) => {
    // empty default
  },
});

export const PaginationProvider = ({
  children,
  shouldSyncURLParams = false,
}: {
  children: ReactNode;
  shouldSyncURLParams?: boolean;
}): JSX.Element => {
  const [params, setParams] = useSearchParams();
  const { trackEvent } = useTrack();
  const [currentEmployeeIds, setCurrentEmployeeIds] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    toInteger(params.get(URL_PARAMS.PAGE) ?? 0)
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(
    toInteger(params.get(URL_PARAMS.PAGE_SIZE) ?? 25)
  );
  const [sortBy, setSortBy] = useState<string>(
    params.get(URL_PARAMS.SORT_BY) ?? ColumnIds.NAME
  );
  const [sortDir, setSortDir] = useState<"asc" | "desc">(
    validSortDirs.has(params.get(URL_PARAMS.SORT_DIR) ?? "N/A")
      ? (params.get(URL_PARAMS.SORT_DIR) as "asc" | "desc")
      : "asc"
  );

  useEffect(() => {
    if (shouldSyncURLParams) {
      setParams((currParams) => ({
        ...paramsToObject(currParams),
        [URL_PARAMS.PAGE]: String(currentPage),
        [URL_PARAMS.PAGE_SIZE]: String(pageSize),
        [URL_PARAMS.SORT_BY]: sortBy,
        [URL_PARAMS.SORT_DIR]: sortDir,
      }));
    }
  }, [currentPage, pageSize, sortBy, sortDir, shouldSyncURLParams, setParams]);

  const setSortParams = (field: string) => {
    trackEvent({
      object: "Pagination Sort Params",
      action: "Sort By",
      field,
    });
    if (field === sortBy) {
      setSortDir(sortDir === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortDir("asc");
    }
  };

  return (
    <PaginationContext.Provider
      value={{
        currentEmployeeIds,
        setCurrentEmployeeIds,
        pageSize,
        currentPage,
        setCurrentPage,
        totalCount,
        setTotalCount,
        setPageSize,
        sortBy,
        setSortBy,
        sortDir,
        setSortDir,
        setSortParams,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = (): PaginationType => {
  return useContext(PaginationContext);
};
