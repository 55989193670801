import { makeStyles } from "@material-ui/core";

import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_3, GRAY_6, theme } from "src/theme";

const useStyles = makeStyles({
  container: {
    minWidth: 250,
    flex: "1 1 100%",
    height: "100%",
    border: `solid 1px ${GRAY_6}`,
    borderRight: `solid 0.5px ${GRAY_6}`,
    borderTop: "none",
    padding: `11px 16px`,
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: theme.spacing(1),
  },
  textClip: {
    maxWidth: "195px",
  },
});

export function CalibrationCell() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <AssembleTypography
          variant="productParagraphSmall"
          textColor={GRAY_3}
          noWrap
          className={classes.textClip}
        >
          Calibration Phase
        </AssembleTypography>
        <AssembleTypography variant="productExtraSmall" textColor={GRAY_3}>
          All full access users can access this phase to review progress up
          until this point.
        </AssembleTypography>
      </div>
    </div>
  );
}
