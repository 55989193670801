import { gql } from "@apollo/client";
import { getMeritBonus } from "@asmbl/shared/comp-recommendation";
import { getSalaryCashComp } from "@asmbl/shared/compensation";
import { CondensedTableBonusCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableBonusCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const currentPay = getSalaryCashComp(employee.subject.activeCashCompensation);
  const increase = getMeritBonus(
    recItems,
    currentPay,
    currentPay?.annualCashEquivalent.currency ?? defaultCurrencyCode
  );
  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(increase)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableBonusCell2.fragments = {
  participant: gql`
    fragment CondensedTableBonusCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          annualCashEquivalent
          hourlyCashEquivalent
          type
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableBonusCell2.Header = ColumnIdsToHeaders.get(ColumnIds.BONUS);
CondensedTableBonusCell2.id = ColumnIds.BONUS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableBonusCell2,
  Header: CondensedTableBonusCell2.Header,
  id: CondensedTableBonusCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableBonusCell2.column = column;
