import { gql } from "@apollo/client";
import {
  makeStyles,
  Switch,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import {
  MatrixCard_matrixRange,
  MatrixTypeEnum,
} from "src/__generated__/graphql";
import { AssembleTypography } from "src/components/AssembleTypography";
import { noop } from "src/test-helpers";
import { GRAY_1, GRAY_3, GRAY_5, GRAY_6, WHITE } from "src/theme";
import { MatrixEditButton } from "../../MeritGuidance/MatrixEditButton";
import { RangeDraft, useMultipleMeritMatrices } from "../useMultipleMatrices";
import { MatrixCell } from "./MatrixCell";
import { MatrixRangeCell } from "./MatrixRangeCell";
import { useTableStyles } from "./tableStyles";

type Props = {
  cells: { label: string | number; id?: number | string }[][];
  matrixId: number;
  orderedRanges: (MatrixCard_matrixRange | RangeDraft)[];
  matrixType?: MatrixTypeEnum;
};

const useStyles = makeStyles((theme) => ({
  table: {
    borderSpacing: 0,
  },
  tableTitle: {
    color: GRAY_3,
    marginBottom: theme.spacing(1),
  },
  titleCell: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.25)}px 0`,
  },
  relative: {
    position: "relative",
  },
  tableHead: {
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px 5px 0 0",
    display: "block",
    height: "30px",
    marginTop: theme.spacing(5),
    marginBottom: "-1px",
    width: "100%",
  },
  perfRatingHeader: {
    marginBottom: theme.spacing(-2),
  },
  perfSubcopy: {
    marginTop: theme.spacing(2),
  },
  spacer: {
    height: theme.spacing(2),
  },
  prefillContainer: {
    backgroundColor: WHITE,
    border: `solid 1px ${GRAY_5}`,
    borderRadius: 5,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  noSidePadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  rightAlign: {
    textAlign: "right",
  },
  prefillCell: {
    padding: `${theme.spacing(3)}px 0 0 0`,
    cursor: "pointer",
  },
}));

export function Matrix({ cells, matrixId, orderedRanges, matrixType }: Props) {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const [showAddRow, setShowAddRow] = useState<boolean>(false);
  const {
    onAddPerfRatingOption,
    onAddGuidanceRange,
    onToggleShouldApplyGuidance,
    shouldApplyGuidance,
    meta: { loading },
  } = useMultipleMeritMatrices();

  return (
    <table className={classes.table}>
      <TableRow>
        <TableCell className={tableClasses.muiCellOverride} />
        <TableCell
          className={tableClasses.muiCellOverride}
          colSpan={cells.at(0)?.length ?? 1}
        >
          <AssembleTypography
            variant="productParagraphLarge"
            textColor={GRAY_1}
          >
            Compa-ratio Range
          </AssembleTypography>
          <AssembleTypography variant="productSmall">
            If a compa-ratio falls on a specific value in a column, it will be
            included in that column.
          </AssembleTypography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={clsx(tableClasses.muiCellOverride, tableClasses.shortCell)}
        >
          <AssembleTypography
            variant="productParagraphLarge"
            className={classes.perfRatingHeader}
          >
            Performance Rating
          </AssembleTypography>
          <AssembleTypography
            variant="productSmall"
            className={classes.perfSubcopy}
          >
            Ratings used in this cycle.
          </AssembleTypography>
        </TableCell>
        {orderedRanges.map((range, idx) => (
          <MatrixRangeCell
            key={`matrix-range-cell-${range.id}`}
            range={range}
            idx={idx}
            orderedRangesCount={orderedRanges.length}
            nextRangeId={orderedRanges[idx + 1]?.id}
          />
        ))}
      </TableRow>
      <TableRow className={classes.spacer} />
      {cells.length ? (
        <TableBody className={classes.relative}>
          {cells.map((cellRow, rowIdx) => (
            <TableRow
              key={cellRow[0].label}
              className={classes.relative}
              onMouseEnter={
                rowIdx === cells.length - 1 ? () => setShowAddRow(true) : noop
              }
              onMouseLeave={
                rowIdx === cells.length - 1 ? () => setShowAddRow(false) : noop
              }
            >
              {cellRow.map((cell, cellIdx) => (
                <MatrixCell
                  key={`matrix-cell-${cell.id}`}
                  cellIdx={cellIdx}
                  rowIdx={rowIdx}
                  cell={cell}
                  cells={cells}
                  cellRowLength={cellRow.length}
                  matrixId={matrixId}
                />
              ))}
              {showAddRow && rowIdx === cells.length - 1 ? (
                <MatrixEditButton
                  position="bottom"
                  actionType="add"
                  onClick={onAddPerfRatingOption}
                  disabled={loading}
                />
              ) : null}
            </TableRow>
          ))}
          <MatrixEditButton
            position="right"
            actionType="add"
            onClick={onAddGuidanceRange}
          />
          <TableRow>
            <TableCell
              className={clsx(
                tableClasses.muiCellOverride,
                tableClasses.shortCell
              )}
            />
          </TableRow>
          {matrixType === MatrixTypeEnum.MERIT && (
            <TableRow>
              <TableCell className={tableClasses.muiCellOverride} />
              <TableCell
                onClick={() =>
                  onToggleShouldApplyGuidance(!shouldApplyGuidance)
                }
                className={clsx(
                  tableClasses.muiCellOverride,
                  classes.prefillCell
                )}
                colSpan={orderedRanges.length}
              >
                <div className={classes.prefillContainer}>
                  Pre-fill merit adjustments with provided guidance
                  <Switch checked={shouldApplyGuidance} />
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      ) : (
        "No data to display"
      )}
    </table>
  );
}

Matrix.fragments = {
  guide: gql`
    ${MatrixRangeCell.fragments.range}
    ${MatrixCell.fragments.perfRatingOption}
    fragment MatrixCard_matrixGuide on MatrixGuide {
      id
      percent
      matrixRange {
        id
        ...MatrixCard_matrixRange
      }
      matrixPerfRatingOption {
        id
        ...MatrixCard_matrixPerfRatingOption
      }
      matrixId
      matrixPerfRatingOptionId
      matrixRangeId
      organizationId
    }
  `,
};
