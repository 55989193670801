import { gql, useQuery } from "@apollo/client";
import { ErrorView } from "src/components/ErrorView";
import {
  GetBudgetReports,
  GetBudgetsAndRequestDataAndSettings,
  GetManager,
} from "../../../__generated__/graphql";
import { useCurrencies } from "../../../components/CurrenciesContext";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { BudgetsVsRequests } from "./BudgetsVsRequests";

type Props = {
  compCycleId: number;
  employeeId: number | null;
};

export function BudgetsVsRequestsLoadingBoundary({
  compCycleId,
  employeeId,
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();

  const { data, loading } = useQuery<GetBudgetsAndRequestDataAndSettings>(
    BudgetsVsRequestsLoadingBoundary.query,
    {
      variables: {
        compCycleId,
        employeeId,
        managerIds: employeeId === null ? null : [employeeId],
        currencyCode: defaultCurrencyCode,
      },
    }
  );

  const { data: manager, loading: managerLoading } = useQuery<GetManager>(
    BudgetsVsRequestsLoadingBoundary.managerQuery,
    {
      variables: {
        employeeId,
        compCycleId,
      },
      skip: employeeId === null,
    }
  );

  const { data: reports, loading: reportsLoading } = useQuery<GetBudgetReports>(
    BudgetsVsRequestsLoadingBoundary.reportsQuery,
    {
      variables: {
        employeeId,
        compCycleId,
        currencyCode: defaultCurrencyCode,
      },
    }
  );

  if (
    !data ||
    !data.compCycle ||
    !data.valuation ||
    // only need to check the manager data if we are fetching it, and we only
    // fetch the manager data when the employeeId field is populated
    (!manager && employeeId !== null)
  ) {
    return loading || managerLoading || reportsLoading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView />
    );
  }

  if (loading || managerLoading || reportsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <BudgetsVsRequests
      manager={manager?.employee ?? null}
      reports={reports?.employees ?? []}
      compCycleBudget={data.compCycleBudget}
      compCycleData={data.compCycle}
      currentValuation={data.valuation}
    />
  );
}

BudgetsVsRequestsLoadingBoundary.managerQuery = gql`
  ${BudgetsVsRequests.fragments.manager}
  query GetManager($compCycleId: Int!, $employeeId: Int!) {
    employee(id: $employeeId) {
      id
      ...BudgetsVsRequests_manager
    }
  }
`;

BudgetsVsRequestsLoadingBoundary.reportsQuery = gql`
  ${BudgetsVsRequests.fragments.reports}
  query GetBudgetReports(
    $compCycleId: Int!
    $currencyCode: CurrencyCode!
    $employeeId: Int
  ) {
    employees(managerId: $employeeId) {
      id
      ...BudgetsVsRequests_reports
    }
  }
`;

BudgetsVsRequestsLoadingBoundary.query = gql`
  ${BudgetsVsRequests.fragments.compCycleBudget}
  ${BudgetsVsRequests.fragments.compCycleData}
  ${BudgetsVsRequests.fragments.valuation}
  query GetBudgetsAndRequestDataAndSettings(
    $compCycleId: Int!
    $employeeId: Int
  ) {
    compCycleBudget(compCycleId: $compCycleId, employeeId: $employeeId) {
      compCycleId
      employeeId
      ...BudgetsVsRequests_compCycleBudget
    }
    compCycle(id: $compCycleId) {
      id
      ...BudgetsVsRequests_compCycleData
    }
    valuation {
      id
      ...BudgetsVsRequests_valuation
    }
  }
`;
