import {
  BandPlacement,
  GetParticipantsInput,
  GetParticipantsInputReports,
  RecItemType,
  RelativeCompRecStatus,
} from "src/__generated__/graphql";
import {
  FilterParam,
  getNumericParams,
  getStringParams,
  NumericParam,
} from "src/models/FilterParams";
import { ImmutableURLSearchParams } from "src/models/URLSearchParams";

/**
 * Transform URL params into a list of numbers.
 */
function parseNumericParams(params: Set<NumericParam>): number[] | undefined {
  return params.has("all")
    ? undefined
    : Array.from(params.values()).filter(
        // We can allow null once we support null as a valid filter option
        (p): p is number => p !== "all" && p !== "null"
      );
}

function reportsParamsToInput(
  reports: string[] | null
): GetParticipantsInputReports | undefined {
  if (reports === null) return undefined;
  const hasDirect = reports.includes(GetParticipantsInputReports.direct);
  const hasIndirect = reports.includes(GetParticipantsInputReports.indirect);
  const hasAll =
    (hasDirect && hasIndirect) ||
    reports.includes(GetParticipantsInputReports.all);

  if (hasAll) {
    return GetParticipantsInputReports.all;
  }
  if (hasDirect) {
    return GetParticipantsInputReports.direct;
  }
  if (hasIndirect) {
    return GetParticipantsInputReports.indirect;
  }
}

export function parseFilterFromUrl(
  urlSearchParams: ImmutableURLSearchParams
): GetParticipantsInput {
  const managerIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.MANAGER)
  );
  const locationGroupIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.LOCATION)
  );
  const departmentIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.DEPARTMENT)
  );
  const ladderIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.LADDER)
  );
  const levels = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.LEVEL)
  );

  const compComponents = getStringParams(urlSearchParams, FilterParam.REC_ITEM);
  const recItems =
    compComponents != null
      ? Object.values(RecItemType).filter((t) => compComponents.includes(t))
      : undefined;

  const statusParams = getStringParams(urlSearchParams, FilterParam.STATUS);
  const statuses =
    statusParams != null
      ? Object.values(RelativeCompRecStatus).filter((t) =>
          statusParams.includes(t)
        )
      : undefined;

  const reportParams = getStringParams(urlSearchParams, FilterParam.REPORTS);
  const reports = reportsParamsToInput(reportParams);

  const bandParams = getStringParams(
    urlSearchParams,
    FilterParam.BAND_PLACEMENT
  );
  const bandPlacement =
    bandParams != null
      ? Object.values(BandPlacement).filter((t) => bandParams.includes(t))
      : undefined;

  const perfRatings =
    getStringParams(urlSearchParams, FilterParam.PERFORMANCE_RATING) ??
    undefined;

  const positionType = (() => {
    const positionTypeStringParam = getStringParams(
      urlSearchParams,
      FilterParam.POSITION_TYPE
    )?.[0];

    if (positionTypeStringParam == null || !positionTypeStringParam.length)
      return undefined;

    return positionTypeStringParam as GetParticipantsInput["positionType"];
  })();

  const guidanceFilters = getStringParams(
    urlSearchParams,
    FilterParam.GUIDANCE_FILTER
  ) as GetParticipantsInput["guidanceFilters"];

  return {
    bandPlacement,
    departmentIds,
    ladderIds,
    levels,
    positionType,
    locationGroupIds,
    managerIds,
    perfRatings,
    recItems,
    reports,
    statuses,
    guidanceFilters,
  };
}

export function fieldToFilterParam(
  field: keyof Omit<GetParticipantsInput, "displayName" | "offset" | "limit">
): FilterParam | null {
  const mapping: Record<
    keyof Omit<GetParticipantsInput, "displayName" | "offset" | "limit">,
    FilterParam
  > = {
    departmentIds: FilterParam.DEPARTMENT,
    ladderIds: FilterParam.LADDER,
    levels: FilterParam.LEVEL,
    locationGroupIds: FilterParam.LOCATION,
    managerIds: FilterParam.MANAGER,
    recItems: FilterParam.REC_ITEM,
    statuses: FilterParam.STATUS,
    reports: FilterParam.REPORTS,
    bandPlacement: FilterParam.BAND_PLACEMENT,
    perfRatings: FilterParam.PERFORMANCE_RATING,
    positionType: FilterParam.POSITION_TYPE,
    guidanceFilters: FilterParam.GUIDANCE_FILTER,
  };
  return mapping[field];
}
