import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { formatNumeral } from "@asmbl/shared/utils";
import { useCompStructure } from "src/components/CompStructureContext";
import {
  CashCompType,
  CondensedTableNewTargetCommissionPercentCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getTotalCashAfterRecommendation } from "../../../../models/CashCompensation";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

function getCommissionPercent(
  defaultCurrencyCode: CurrencyCode,
  employee: Participant,
  workingHoursPerYear: number | undefined
): number {
  const totalCash = getTotalCashAfterRecommendation(
    defaultCurrencyCode,
    employee.subject.activeCashCompensation ?? [],
    employee.compRecommendation?.latestSubmittedItems ?? [],
    workingHoursPerYear
  );
  return (
    totalCash?.subcomponents.find((c) => c.type === CashCompType.COMMISSION)
      ?.percentOfSalary ?? 0
  );
}

export function CondensedTableNewTargetCommissionPercentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { defaultCurrencyCode } = useCurrencies();

  return (
    <AssembleTruncatedTypography align="right">
      {formatNumeral(
        getCommissionPercent(
          defaultCurrencyCode,
          employee,
          compStructure?.workingHoursPerYear
        ) / 100,
        {
          style: "percent",
          maximumFractionDigits: 2,
        }
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewTargetCommissionPercentCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewTargetCommissionPercentCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          unit
          annualCashEquivalent
          hourlyCashEquivalent
          percentOfSalary
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPercentValue
          unitType
        }
      }
    }
  `,
};

CondensedTableNewTargetCommissionPercentCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_TARGET_COMMISSION_PERCENT
);
CondensedTableNewTargetCommissionPercentCell2.id =
  ColumnIds.NEW_TARGET_COMMISSION_PERCENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewTargetCommissionPercentCell2,
  Header: CondensedTableNewTargetCommissionPercentCell2.Header,
  id: CondensedTableNewTargetCommissionPercentCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableNewTargetCommissionPercentCell2.column = column;
