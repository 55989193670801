import { gql } from "@apollo/client";
import { Input, makeStyles, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { useRef, useState } from "react";
import { BoldArrowRightIcon } from "src/components/AssembleIcons/Small/BoldArrowRightIcon";
import { GRAY_4 } from "src/theme";
import { MatrixEditButton } from "../../MeritGuidance/MatrixEditButton";
import { useMultipleMeritMatrices } from "../useMultipleMatrices";
import { useTableStyles } from "./tableStyles";

const useStyles = makeStyles(() => ({
  rangeCell: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  rangeInput: {
    maxWidth: "4rem",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

type Props = {
  idx: number;
  orderedRangesCount: number;
  range: { id: number | string; rangeStart: number };
  nextRangeId?: number | string;
};

export function MatrixRangeCell({
  idx,
  orderedRangesCount,
  range,
  nextRangeId,
}: Props) {
  const inputRef = useRef();
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const { onUpdateGuidanceRange, onDeleteGuidanceRange } =
    useMultipleMeritMatrices();

  const handleCellFocus = () => {
    (inputRef.current as unknown as HTMLElement | null)?.focus();
  };

  return (
    <TableCell
      className={clsx(
        [
          tableClasses.cell,
          tableClasses.muiCellOverride,
          tableClasses.withTopBorder,
          tableClasses.shortCell,
          tableClasses.smallFont,
        ],
        {
          [tableClasses.roundTopLeft]: idx === 0,
          [tableClasses.roundBottomLeft]: idx === 0,
          [tableClasses.roundTopRight]: idx === orderedRangesCount - 1,
          [tableClasses.roundBottomRight]: idx === orderedRangesCount - 1,
          [tableClasses.withLeftBorder]: idx === 0,
          [tableClasses.cursorText]: idx !== 0,
        }
      )}
      key={`${range.id}-${idx}-header`}
      onClick={handleCellFocus}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className={classes.rangeCell}>
        {idx === 0 ? (
          `${range.rangeStart}`
        ) : (
          <>
            {isHovered ? (
              <MatrixEditButton
                actionType="remove"
                position="top"
                onClick={() => onDeleteGuidanceRange(range.id)}
              />
            ) : null}
            <Input
              startAdornment={nextRangeId == null ? ">" : undefined}
              className={clsx(tableClasses.smallFont, classes.rangeInput)}
              defaultValue={range.rangeStart}
              disableUnderline
              onBlur={async (e) => {
                if (parseFloat(e.target.value) !== range.rangeStart) {
                  await onUpdateGuidanceRange(
                    range.id,
                    parseFloat(e.target.value)
                  );
                }
              }}
              inputRef={inputRef}
              onFocus={(e) => e.target.select()}
              type="number"
              key={`rangeStart-${idx}-${range.rangeStart}`}
              id={`rangeStart-${idx}`}
            />
          </>
        )}
        {nextRangeId == null ? null : (
          <BoldArrowRightIcon width={12} height={12} color={GRAY_4} />
        )}
      </span>
    </TableCell>
  );
}

MatrixRangeCell.fragments = {
  range: gql`
    fragment MatrixCard_matrixRange on MatrixRange {
      id
      rangeStart
      organizationId
    }
  `,
};
