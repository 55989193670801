import { gql } from "@apollo/client";
import { getPayCashEquivalent, isHourlyComp } from "@asmbl/shared/compensation";
import { exchangeMoney } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { formatCurrency, isZero, zero } from "@asmbl/shared/money";
import { useMemo } from "react";
import { useCompStructure } from "src/components/CompStructureContext";
import { useCurrencies } from "src/components/CurrenciesContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  ColumnIds,
  ColumnIdsToHeaders,
} from "src/pages/CompCycle/Plan/Contexts/ColumnOrderContext";
import { CondensedTableLastSalaryChangeAmountCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { useTableData } from "../Contexts/TableDataContext2";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableLastSalaryChangeAmountCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const showHourly = Boolean(
    compStructure?.allowHourlyEmployees &&
      isEnabled(FeatureFlag.HourlyEmployeesInCompCycles)
  );
  const isHourly =
    showHourly && isHourlyComp(employee.subject.lastCompChangeAmount?.unitType);
  const lastComp = employee.subject.lastCompChangeAmount
    ? getPayCashEquivalent(employee.subject.lastCompChangeAmount)
    : null;

  const table = useTableData();
  const currencies = useCurrencies();
  const amount = useMemo(() => {
    const selCurrency =
      table.selectedCurrency === "all"
        ? lastComp?.currency ?? currencies.defaultCurrency.code
        : table.selectedCurrency;

    if (lastComp == null || isZero(lastComp)) {
      return zero(selCurrency);
    }

    return exchangeMoney(lastComp, selCurrency, {
      allCurrencies: currencies.currencies,
    });
  }, [lastComp, currencies, table.selectedCurrency]);

  return (
    <AssembleTruncatedTypography lines={2} align="right">
      {lastComp != null
        ? `${formatCurrency(amount, { maximumFractionDigits: 0 })}${
            isHourly ? "/hr" : ""
          }`
        : "-"}
    </AssembleTruncatedTypography>
  );
}

CondensedTableLastSalaryChangeAmountCell2.fragments = {
  participant: gql`
    fragment CondensedTableLastSalaryChangeAmountCell2_participant on CompCycleParticipant {
      subject {
        lastCompChangeAmount(currencyCode: $currencyCode) {
          annualCashEquivalent
          hourlyCashEquivalent
          unitType
        }
      }
    }
  `,
};

CondensedTableLastSalaryChangeAmountCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.LAST_SALARY_CHANGE_AMOUNT
);
CondensedTableLastSalaryChangeAmountCell2.id =
  ColumnIds.LAST_SALARY_CHANGE_AMOUNT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableLastSalaryChangeAmountCell2,
  Header: CondensedTableLastSalaryChangeAmountCell2.Header,
  id: CondensedTableLastSalaryChangeAmountCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableLastSalaryChangeAmountCell2.column = column;
