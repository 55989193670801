import { gql } from "@apollo/client";
import {
  add,
  divide,
  formatCurrency,
  Money,
  multiply,
  subtract,
  unitsOfTotalGrossValue,
} from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Fragment, useState } from "react";
import {
  EquityRow_compCycleData as CompCycleData,
  EquityRow_employee as Employee,
  RecItemType,
  EquityRow_valuation as Valuation,
} from "../../../../__generated__/graphql";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { calculateRequestsFromReports } from "../../../../models/CompRecommendation";
import { DV_RED, GRAY_1, GRAY_7, GRAY_8 } from "../../../../theme";
import { BudgetNameCell } from "../../Budgets/Table/BudgetNameCell";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { Equity } from "../BudgetsVsRequests";
import { LazyReportingEquityTier } from "./LazyReportingEquityTier";

type Props = {
  employee: Employee;
  currentValuation: Valuation;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  equityDisplay: Equity;
  expandable: boolean;
  compCycleData: CompCycleData;
  indentation: IndentationCells;
  isLastRow: boolean;
};

const useStyles = makeStyles(() => ({
  reportCell: { backgroundColor: GRAY_8 },
  reportCellText: {
    color: GRAY_1,
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.55",
    letterSpacing: "-0.25px",
  },
  alertText: { color: DV_RED },
  directAndIndirectReportsCell: { backgroundColor: GRAY_7 },
}));

export function EquityRow({
  employee,
  currentValuation,
  organizationName,
  varianceDisplay,
  equityDisplay,
  expandable,
  compCycleData,
  indentation,
  isLastRow,
}: Props): JSX.Element {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const reportingIndentation: IndentationCells =
    indentation.length > 0 && isLastRow
      ? [...indentation.slice(0, -1), "hidden", "visible"]
      : indentation.concat("visible");

  const reports = [...employee.directReports, ...employee.indirectReports];

  return (
    <>
      <TableRow>
        <BudgetNameCell
          compCycleId={compCycleData.id}
          employee={employee}
          indentation={indentation}
          expanded={isExpanded}
          expandable={expandable}
          setExpanded={setIsExpanded}
          type="compare"
          isLastRowCell={isLastRow}
        />
        <TableCell
          align="right"
          width="5%"
          className={clsx(
            classes.reportCell,
            classes.directAndIndirectReportsCell
          )}
        >
          {employee.directReports.length}
        </TableCell>
        <TableCell
          align="right"
          width="5%"
          className={clsx(
            classes.reportCell,
            classes.directAndIndirectReportsCell
          )}
        >
          {employee.indirectReports.length}
        </TableCell>

        {[
          {
            budgetKey: "equity",
            budget: employee.compCycleBudget?.equity ?? null,
            requests: calculateRequestsFromReports(
              reports,
              RecItemType.EQUITY_GRANT
            ),
          },
        ].map(({ budgetKey, budget, requests }) => {
          const variance =
            budget && requests ? subtract(requests, budget) : null;

          return (
            <Fragment key={budgetKey}>
              {/* budget */}
              <TableCell
                align="right"
                className={classes.reportCell}
                width="20%"
              >
                <Typography className={classes.reportCellText}>
                  {renderEquityBudget(
                    equityDisplay,
                    budget,
                    currentValuation.fdso,
                    currentValuation.valuation
                  )}
                </Typography>
              </TableCell>

              {/* request */}
              <TableCell
                align="right"
                className={classes.reportCell}
                width="20%"
              >
                <Typography className={classes.reportCellText}>
                  {renderEquityRequest(
                    equityDisplay,
                    requests,
                    currentValuation.fdso,
                    currentValuation.valuation
                  )}
                </Typography>
              </TableCell>

              {/* variance */}
              <TableCell
                align="right"
                className={classes.reportCell}
                width="20%"
              >
                <Typography
                  className={clsx(classes.reportCellText, {
                    [classes.alertText]: variance && variance.value > 0,
                  })}
                >
                  {renderEquityVariance(
                    equityDisplay,
                    varianceDisplay,
                    budget,
                    requests,
                    variance,
                    currentValuation.fdso,
                    currentValuation.valuation
                  )}
                </Typography>
              </TableCell>
            </Fragment>
          );
        })}
      </TableRow>

      {isExpanded && (
        <LazyReportingEquityTier
          employee={employee}
          currentValuation={currentValuation}
          organizationName={organizationName}
          compCycleData={compCycleData}
          varianceDisplay={varianceDisplay}
          equityDisplay={equityDisplay}
          indentation={reportingIndentation}
        />
      )}
    </>
  );
}

export function renderEquityBudget(
  equityDisplay: Equity,
  budget: Money | null,
  fdso: number,
  valuation: Money
): string {
  if (budget === null) return "-";

  if (equityDisplay === "units") {
    return formatNumeral(
      Math.round(unitsOfTotalGrossValue(fdso, valuation, budget))
    );
  }

  return formatCurrency(budget, {
    maximumFractionDigits: 0,
  });
}

export function renderEquityRequest(
  equityDisplay: Equity,
  request: Money | null,
  fdso: number,
  valuation: Money
): string {
  if (request === null) return "-";

  if (equityDisplay === "units") {
    return formatNumeral(
      Math.round(unitsOfTotalGrossValue(fdso, valuation, request))
    );
  }

  return formatCurrency(request, {
    maximumFractionDigits: 0,
  });
}

export function renderEquityVariance(
  equityDisplay: Equity,
  varianceDisplay: CompCycleDisplay,
  budget: Money | null,
  request: Money | null,
  variance: Money | null,
  fdso: number,
  valuation: Money
): string {
  if (budget === null || request === null || variance === null) {
    return "-";
  }

  if (varianceDisplay === "absolute") {
    if (equityDisplay === "cash") {
      return formatCurrency(
        Object.is(variance.value, -0) ? multiply(variance, -1) : variance,
        {
          maximumFractionDigits: 0,
        }
      );
    } else {
      // units
      return formatNumeral(
        Math.round(unitsOfTotalGrossValue(fdso, valuation, variance))
      );
    }
  } else {
    // percentage

    return divide(
      add(
        budget,
        Object.is(variance.value, -0) ? multiply(variance, -1) : variance
      ),
      budget.value
    ).value.toLocaleString("en-US", {
      style: "percent",
      maximumFractionDigits: 2,
    });
  }
}

EquityRow.fragments = {
  employee: gql`
    fragment EquityRow_employee on Employee {
      id
      displayName
      user {
        id
        photoURL
      }
      activeEmployment {
        id
        jobTitle
      }
      compCycleBudget(compCycleId: $compCycleId) {
        compCycleId
        employeeId
        equity
      }

      directReports {
        id
        compRecommendation(compCycleId: $compCycleId) {
          subjectId
          compCycleId
          latestSubmittedItems {
            id
            recommendationType
            cashEquivalent(currencyCode: $currencyCode)
          }
        }
      }

      indirectReports {
        id
        compRecommendation(compCycleId: $compCycleId) {
          subjectId
          compCycleId
          latestSubmittedItems {
            id
            recommendationType
            cashEquivalent(currencyCode: $currencyCode)
          }
        }
      }
    }
  `,
  valuation: gql`
    fragment EquityRow_valuation on Valuation {
      id
      valuation
      fdso
    }
  `,
  compCycleData: gql`
    fragment EquityRow_compCycleData on CompCycle {
      id
    }
  `,
};
