import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import {
  GetPotentialParticipants,
  GetPotentialParticipantsVariables,
} from "../../../__generated__/graphql";
import { ErrorView } from "../../../components/ErrorView";
import { usePagination } from "../Plan/Contexts/PaginationContext";
import { EligibleIneligibleTable } from "./EligibleIneligibleTable";

type Props = {
  compCycleId: number;
};

export function ParticipantsLoadingBoundary({
  compCycleId,
}: Props): JSX.Element {
  const [showEligible, setShowEligible] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const { pageSize, currentPage, sortBy, sortDir, setTotalCount } =
    usePagination();

  const { data, loading } = useQuery<
    GetPotentialParticipants,
    GetPotentialParticipantsVariables
  >(ParticipantsLoadingBoundary.query, {
    variables: {
      compCycleId,
      limit: pageSize,
      offset: currentPage * pageSize,
      sort: { sortBy, sortDir },
      input: {
        displayName: searchTerm,
      },
    },
    onCompleted: (data: GetPotentialParticipants) => {
      if (data.compCycle2?.participantEligibility != null) {
        showEligible
          ? setTotalCount(
              data.compCycle2.participantEligibility.counts.eligible
            )
          : setTotalCount(
              data.compCycle2.participantEligibility.counts.ineligible
            );
      }
    },
  });

  if (!data) {
    return loading ? (
      <div style={{ marginBottom: "40rem" }}>
        <CircularProgress />
      </div>
    ) : (
      <ErrorView />
    );
  }

  return (
    <EligibleIneligibleTable
      people={data.compCycle2?.participantEligibility}
      showEligible={showEligible}
      setShowEligible={setShowEligible}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
    />
  );
}

ParticipantsLoadingBoundary.query = gql`
  ${EligibleIneligibleTable.fragments.participant}
  query GetPotentialParticipants(
    $compCycleId: Int!
    $input: GetParticipantsInput
    $limit: Int
    $offset: Int
    $sort: Sort
  ) {
    compCycle2(id: $compCycleId) {
      id
      participantEligibility(
        input: $input
        limit: $limit
        offset: $offset
        sort: $sort
      ) {
        counts {
          eligible
          ineligible
        }
        eligible {
          subjectId
          compCycleId
          ...EligibleIneligibleTable_participant
        }
        ineligible {
          subjectId
          compCycleId
          ...EligibleIneligibleTable_participant
        }
      }
    }
  }
`;
