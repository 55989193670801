import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";

import { CircleArrowRightIcon } from "src/components/AssembleIcons/Brand/CircleArrowRightIcon";
import { ListIcon } from "src/components/AssembleIcons/Small/ListIcon";
import { AssembleLink } from "src/components/AssembleLink";
import { AssembleTypography } from "src/components/AssembleTypography";
import { DV_BLUE, GRAY_1, GRAY_3, GRAY_6, WHITE } from "src/theme";
import { CompletionStatus } from "./CompletionStatus";
import {
  ColumnCell,
  ReviewerStats,
  useProgressPageContext,
} from "./Context/ProgressPageContext";

type Props = {
  reviewer: ColumnCell;
  isPastPhase: boolean;
};

const useStyles = makeStyles({
  container: {
    minWidth: 250,
    flex: "1 1 100%",
    height: ({ rowSpan }: { rowSpan: number }) => 56 * rowSpan,
    border: `solid 1px ${GRAY_6}`,
    borderRight: `solid 0.5px ${GRAY_6}`,
    borderTop: "none",
    padding: `11px 16px`,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  nameAndIconContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  highlighted: {
    borderColor: DV_BLUE,
    backgroundColor: "#34AAFF0F",
    border: `solid 1px ${DV_BLUE}`,
  },
  textClip: {
    maxWidth: "195px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "10px",
  },
  arrow: {
    border: `1px solid ${DV_BLUE}`,
    borderRadius: "50%",
    height: 16,
    width: 16,
    display: "flex",
    alignItems: "center",
    backgroundColor: WHITE,
    zIndex: 1,
    position: "absolute",
    right: "-8px",
  },
});

export function ProgressCell({ reviewer, isPastPhase }: Props) {
  const classes = useStyles({ rowSpan: reviewer.rowSpan });
  const {
    isCellHighlighted,
    handleHover,
    handleCellClick,
    hoveredEmployeeIds,
    compCycleId,
  } = useProgressPageContext();
  const employee = reviewer.employee;

  const isHighlighted = isCellHighlighted(employee.id);
  const isLastHovered = Boolean(
    hoveredEmployeeIds &&
      Array.from(hoveredEmployeeIds.values()).at(-1) === employee.id
  );

  const handleCellEnter = () =>
    handleHover([employee.id, ...(employee.managerIds ?? [])]);

  const handleOnClick = () =>
    handleCellClick([employee.id, ...(employee.managerIds ?? [])]);

  const handleCellLeave = () => handleHover(null);

  return (
    <div
      className={clsx(classes.container, {
        [classes.highlighted]: isHighlighted,
      })}
      onMouseEnter={handleCellEnter}
      onMouseLeave={handleCellLeave}
      onClick={handleOnClick}
    >
      <div className={classes.nameAndIconContainer}>
        <AssembleTypography
          variant="productParagraphSmall"
          textColor={GRAY_1}
          noWrap
          className={classes.textClip}
        >
          {employee.displayName}
        </AssembleTypography>
        <div className={classes.iconContainer}>
          <Tooltip title="View in Plan page" placement="top">
            <span>
              <AssembleLink
                underline="none"
                to={`/comp-cycles/${compCycleId}/requests?manager=${reviewer.employee.id}`}
                newTab
              >
                <ListIcon />
              </AssembleLink>
            </span>
          </Tooltip>
          <div>
            <AssembleTypography
              variant="productParagraphSmall"
              textColor={GRAY_1}
              noWrap
            >
              <CompletionStatus
                reviewStats={reviewer as ReviewerStats}
                isPastPhase={isPastPhase}
              />
            </AssembleTypography>
          </div>
        </div>
      </div>
      <AssembleTypography
        variant="productExtraSmall"
        textColor={GRAY_3}
        noWrap
        className={classes.textClip}
      >
        {employee.activeEmployment?.jobTitle}
      </AssembleTypography>
      {isHighlighted && !isLastHovered && (
        <div className={classes.arrow}>
          <CircleArrowRightIcon color={DV_BLUE} />
        </div>
      )}
    </div>
  );
}
