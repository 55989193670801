import { gql } from "@apollo/client";
import { getCompCalculator } from "@asmbl/shared/compensation";
import { useCompStructure } from "src/components/CompStructureContext";
import { CondensedTableCurrentTCCCompaRatioCell_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableCurrentTCCCompaRatioCell({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { activeCashCompensation, adjustedCashBands } = employee.subject;

  const activeCash =
    activeCashCompensation != null ? activeCashCompensation : undefined;

  const bands = adjustedCashBands?.map((band) => ({
    name: band.name,
    bandPoints: band.bandPoints.map((bp) => ({
      name: bp.name,
      value: bp.annualCashEquivalent.value,
    })),
  }));

  const getPayData = getCompCalculator(
    bands,
    activeCash,
    compStructure ?? { bandPointTypes: [] }
  );

  const compaRatio = getPayData("total").compaRatio;

  return (
    <AssembleTruncatedTypography align="right">
      {compaRatio != null ? `${Number(compaRatio).toFixed()}%` : "-"}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentTCCCompaRatioCell.fragments = {
  compStructure: gql`
    fragment CondensedTableCurrentTCCCompaRatioCell_compStructure on CompStructure {
      id
      bandPointTypes
    }
  `,
  participant: gql`
    fragment CondensedTableCurrentTCCCompaRatioCell_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            name
            annualCashEquivalent
            hourlyCashEquivalent
          }
        }
      }
    }
  `,
};

CondensedTableCurrentTCCCompaRatioCell.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_TCC_COMPA_RATIO
);
CondensedTableCurrentTCCCompaRatioCell.id = ColumnIds.CURRENT_TCC_COMPA_RATIO;
const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentTCCCompaRatioCell,
  Header: CondensedTableCurrentTCCCompaRatioCell.Header,
  id: CondensedTableCurrentTCCCompaRatioCell.id,
  width: LARGE_COL_WIDTH,
  defaultCanSort: false,
};
CondensedTableCurrentTCCCompaRatioCell.column = column;
