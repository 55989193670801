import {
  makeStyles,
  Switch,
  Table,
  TableCell,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Guidance_compCycle as CompCycle } from "src/__generated__/graphql";
import { AssembleTypography } from "src/components/AssembleTypography";
import { getSimpleCashLabel } from "src/models/Currency";
import { useUpdateCompCycleGuidancePreferences } from "src/mutations/CompCycle";
import {
  useAddGuidanceRange,
  useAddPerfRatingOption,
  useDeletePerfRatingOption,
  useUpdateMatrixGuide,
  useUpdatePerfRatingOption,
} from "src/mutations/Matrix";
import { GRAY_1, GRAY_5, WHITE } from "src/theme";
import { FocusableHeaderCell } from "./FocusableHeaderCell";
import { Matrix } from "./Matrix";
import { useTableStyles } from "./tableStyles";
import { buildMatrix, getOrderedRanges } from "./util";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  headerTitle: {
    color: GRAY_1,
  },
  perfRatingHeader: {
    marginBottom: theme.spacing(-2),
  },
  perfSubcopy: {
    marginTop: theme.spacing(2),
  },
  prefillContainer: {
    backgroundColor: WHITE,
    border: `solid 1px ${GRAY_5}`,
    borderRadius: 5,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  prefillCell: {
    padding: `${theme.spacing(3)}px 0 0 0`,
    cursor: "pointer",
  },
}));

type Props = {
  compCycle: CompCycle;
  refreshMetrics: () => void;
  shouldApplyGuidance: boolean;
};

export type CellLocation = {
  x: number | null;
  y: number | null;
  table: "budget" | "merit" | null;
};

export function MeritAndBudgetMatrices({
  compCycle,
  refreshMetrics,
  shouldApplyGuidance,
}: Props) {
  const [hoveredCell, setHoveredCell] = useState<CellLocation>({
    x: null,
    y: null,
    table: null,
  });
  const [focusedCell, setFocusedCell] = useState<CellLocation>({
    x: null,
    y: null,
    table: null,
  });

  const classes = useStyles();
  const tableClasses = useTableStyles();
  const updateMatrixGuide = useUpdateMatrixGuide();
  const updatePerfRatingOption = useUpdatePerfRatingOption();
  const addPerfRatingOption = useAddPerfRatingOption(compCycle.matrices[0].id);
  const addGuidanceRange = useAddGuidanceRange(compCycle.matrices[0].id);
  const deletePerfRatingOption = useDeletePerfRatingOption();
  const updateCompCycleGuidancePreferences =
    useUpdateCompCycleGuidancePreferences(compCycle.id);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const budgetMatrixRoot = compCycle.matrices?.find(
    (matrix) => matrix.type === "BUDGET"
  )?.matrixGuides;
  const budgetMatrix = buildMatrix(budgetMatrixRoot);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const meritMatrixRoot = compCycle.matrices?.find(
    (matrix) => matrix.type === "MERIT"
  )?.matrixGuides;
  const meritMatrix = buildMatrix(meritMatrixRoot);
  const orderedRanges = getOrderedRanges(budgetMatrixRoot);
  const { enqueueSnackbar } = useSnackbar();

  const toggleShouldApplyGuidance = async (shouldApply: boolean) => {
    await updateCompCycleGuidancePreferences(shouldApply);
  };

  const showAddRow =
    budgetMatrix != null && hoveredCell.y === budgetMatrix.length - 1;
  const showAddColumn =
    budgetMatrix[0] != null && hoveredCell.x === budgetMatrix[0].length - 1;

  const showError = (error: string) => {
    console.error(error);
    enqueueSnackbar("Something went wrong. Please try again.", {
      variant: "error",
    });
  };

  const onUpdateGuidance = (id: number, percent: number) => {
    updateMatrixGuide(
      id,
      percent,
      compCycle.matrices.find((matrix) => matrix.type === "BUDGET")
        ?.id as number
    )
      .then(() => {
        enqueueSnackbar("Guidance successfully updated.", {
          variant: "success",
          autoHideDuration: 2_000,
        });
        refreshMetrics();
      })
      .catch(showError);
  };

  const onAddPerfRatingOption = () => {
    addPerfRatingOption().then(refreshMetrics).catch(showError);
  };

  const onAddGuidanceRange = () => {
    addGuidanceRange().then(refreshMetrics).catch(showError);
  };

  const onUpdatePerfRatingOption = (id: number, newName: string) => {
    updatePerfRatingOption(id, newName).then(refreshMetrics).catch(showError);
  };

  const onDeletePerfRatingOption = (id: number) => {
    if (budgetMatrix.length === 1) {
      enqueueSnackbar("Must have at least one performance rating option", {
        variant: "error",
      });
    } else {
      deletePerfRatingOption(id).then(refreshMetrics).catch(showError);
    }
  };

  return (
    <div
      className={classes.container}
      onMouseLeave={() => setHoveredCell({ x: null, y: null, table: null })}
    >
      <Table>
        <TableRow>
          <TableCell className={tableClasses.muiCellOverride} />
          <TableCell
            className={tableClasses.muiCellOverride}
            colSpan={budgetMatrix.at(0)?.length ?? 1}
          >
            <AssembleTypography
              variant="productParagraphLarge"
              className={classes.headerTitle}
            >
              Compa-ratio Range
            </AssembleTypography>
            <AssembleTypography variant="productSmall">
              If a compa-ratio falls on a specific value in a column, it will be
              included in that column.
            </AssembleTypography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={clsx(
              tableClasses.muiCellOverride,
              tableClasses.shortCell
            )}
          >
            <AssembleTypography
              variant="productParagraphLarge"
              className={clsx(classes.headerTitle, classes.perfRatingHeader)}
            >
              Performance Rating
            </AssembleTypography>
            <AssembleTypography
              variant="productSmall"
              className={classes.perfSubcopy}
            >
              Ratings used in this cycle.
            </AssembleTypography>
          </TableCell>
          {orderedRanges.map((range, idx) => {
            return (
              <FocusableHeaderCell
                key={`header-cell-${range.id}`}
                idx={idx}
                orderedRangesCount={orderedRanges.length}
                range={range}
                hoveredCell={hoveredCell}
                setHoveredCell={setHoveredCell}
                refreshMetrics={refreshMetrics}
              />
            );
          })}
        </TableRow>
        <Matrix
          title="Merit&nbsp;Guidance&nbsp;Breakdown"
          cells={meritMatrix}
          onChange={onUpdateGuidance}
          onUpdatePerfRatingOption={(rowIdx, newName) => {
            const perfRatingOptionId = budgetMatrix[rowIdx][0].id;
            if (perfRatingOptionId) {
              onUpdatePerfRatingOption(perfRatingOptionId, newName);
            }
          }}
          onDeletePerfRatingOption={(rowIdx) => {
            const perfRatingOptionId = budgetMatrix[rowIdx][0].id;
            if (perfRatingOptionId) {
              onDeletePerfRatingOption(perfRatingOptionId);
            }
          }}
          matrixType="merit"
          onAddGuidanceRange={onAddGuidanceRange}
          setHoveredCell={setHoveredCell}
          showAddColumn={showAddColumn}
          hoveredCell={hoveredCell}
          focusedCell={focusedCell}
          setFocusedCell={setFocusedCell}
        />
        <TableRow>
          <TableCell
            className={clsx(
              tableClasses.muiCellOverride,
              tableClasses.shortCell
            )}
          />
          <TableCell
            className={clsx([
              tableClasses.cell,
              tableClasses.muiCellOverride,
              tableClasses.metricsCell,
            ])}
            colSpan={orderedRanges.length}
          >
            <div className={tableClasses.metricsContainer}>
              <div className={tableClasses.meritMetrics}>
                <span>Cost of Merit Guidance</span>
                <span
                  style={{
                    textAlign: "right",
                  }}
                >
                  {getSimpleCashLabel(compCycle.estimatedSalaryMerit)}
                </span>
              </div>
            </div>
          </TableCell>
        </TableRow>
        <TableRow
          onMouseEnter={() => setHoveredCell({ x: null, y: null, table: null })}
        >
          <TableCell className={tableClasses.muiCellOverride} />
          <TableCell
            onClick={() => toggleShouldApplyGuidance(!shouldApplyGuidance)}
            className={clsx(tableClasses.muiCellOverride, classes.prefillCell)}
            colSpan={orderedRanges.length}
          >
            <div className={classes.prefillContainer}>
              Pre-fill merit adjustments with provided guidance
              <Switch checked={shouldApplyGuidance} />
            </div>
          </TableCell>
        </TableRow>
        <Matrix
          title="Budget&nbsp;For&nbsp;Eligible&nbsp;Employees"
          cells={budgetMatrix}
          onChange={onUpdateGuidance}
          onUpdatePerfRatingOption={(rowIdx, newName) => {
            const perfRatingOptionId = budgetMatrix[rowIdx][0].id;
            if (perfRatingOptionId) {
              onUpdatePerfRatingOption(perfRatingOptionId, newName);
            }
          }}
          onDeletePerfRatingOption={(rowIdx) => {
            const perfRatingOptionId = budgetMatrix[rowIdx][0].id;
            if (perfRatingOptionId) {
              onDeletePerfRatingOption(perfRatingOptionId);
            }
          }}
          matrixType="budget"
          onAddPerfRatingOption={onAddPerfRatingOption}
          setHoveredCell={setHoveredCell}
          showAddRow={showAddRow}
          hoveredCell={hoveredCell}
          focusedCell={focusedCell}
          setFocusedCell={setFocusedCell}
        />
        <TableRow>
          <TableCell
            className={clsx(
              tableClasses.muiCellOverride,
              tableClasses.shortCell
            )}
          />
          <TableCell
            className={clsx([
              tableClasses.cell,
              tableClasses.muiCellOverride,
              tableClasses.metricsCell,
            ])}
            colSpan={orderedRanges.length}
          >
            <div className={tableClasses.metricsContainer}>
              <div className={tableClasses.totalCurrentSpend}>
                <span>Total Salary Spend</span>
                <span
                  style={{
                    textAlign: "right",
                  }}
                >
                  {getSimpleCashLabel(compCycle.totalSpend)}
                </span>
              </div>
              <div className={tableClasses.totalSalaryBudget}>
                <span>Proposed Salary Budget</span>
                <span
                  style={{
                    textAlign: "right",
                  }}
                >
                  {getSimpleCashLabel(compCycle.estimatedSalaryBudget)}
                </span>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </Table>
    </div>
  );
}
