import { gql } from "@apollo/client";
import { subtract } from "@asmbl/shared/money";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Fragment, useState } from "react";
import {
  TargetRow_compCycleData as CompCycleData,
  TargetRow_employee as Employee,
  RecItemType,
} from "../../../../__generated__/graphql";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { formatBudget } from "../../../../models/Budget";
import {
  calculateRequestsFromReports,
  formatRequest,
  formatVariance,
} from "../../../../models/CompRecommendation";
import { DV_RED, GRAY_1, GRAY_7, GRAY_8 } from "../../../../theme";
import { BudgetNameCell } from "../../Budgets/Table/BudgetNameCell";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { LazyReportingTargetTier } from "./LazyReportingTargetTier";

const useStyles = makeStyles(() => ({
  reportCell: {
    width: "6rem",
    backgroundColor: GRAY_8,
  },
  reportCellText: {
    color: GRAY_1,
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.55",
    letterSpacing: "-0.25px",
  },
  alertText: { color: DV_RED },
  directAndIndirectReportsCell: { backgroundColor: GRAY_7 },
}));

type Props = {
  employee: Employee;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  expandable: boolean;
  compCycleData: CompCycleData;
  indentation: IndentationCells;
  isLastRow: boolean;
};

export function TargetRow({
  employee,
  organizationName,
  varianceDisplay,
  expandable,
  compCycleData,
  indentation,
  isLastRow,
}: Props): JSX.Element {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const reportingIndentation: IndentationCells =
    indentation.length > 0 && isLastRow
      ? [...indentation.slice(0, -1), "hidden", "visible"]
      : indentation.concat("visible");

  const reports = [...employee.directReports, ...employee.indirectReports];

  const commission = {
    budgetKey: "targetCommission",
    budget: employee.compCycleBudget?.targetCommission ?? null,
    requests: calculateRequestsFromReports(
      reports,
      RecItemType.TARGET_COMMISSION
    ),
  };

  const recurringBonus = {
    budgetKey: "targetRecurringBonus",
    budget: employee.compCycleBudget?.targetRecurringBonus ?? null,
    requests: calculateRequestsFromReports(
      reports,
      RecItemType.TARGET_RECURRING_BONUS
    ),
  };

  return (
    <>
      <TableRow>
        <BudgetNameCell
          compCycleId={compCycleData.id}
          employee={employee}
          indentation={indentation}
          expanded={isExpanded}
          expandable={expandable}
          setExpanded={setIsExpanded}
          type="compare"
          isLastRowCell={isLastRow}
        />
        <TableCell
          align="right"
          width="5%"
          className={clsx(
            classes.reportCell,
            classes.directAndIndirectReportsCell
          )}
        >
          {employee.directReports.length}
        </TableCell>
        <TableCell
          align="right"
          width="5%"
          className={clsx(
            classes.reportCell,
            classes.directAndIndirectReportsCell
          )}
        >
          {employee.indirectReports.length}
        </TableCell>

        {[
          ...(compCycleData.allowTargetCommission ? [commission] : []),
          ...(compCycleData.allowTargetRecurringBonus ? [recurringBonus] : []),
        ].map(({ budgetKey, budget, requests }) => {
          const variance =
            budget && requests ? subtract(requests, budget) : null;

          return (
            <Fragment key={budgetKey}>
              {/* budget */}
              <TableCell
                align="right"
                className={classes.reportCell}
                width="20%"
              >
                <Typography className={classes.reportCellText}>
                  {formatBudget(budget)}
                </Typography>
              </TableCell>

              {/* request */}
              <TableCell
                align="right"
                className={classes.reportCell}
                width="20%"
              >
                <Typography className={classes.reportCellText}>
                  {formatRequest(requests)}
                </Typography>
              </TableCell>

              {/* variance */}
              <TableCell
                align="right"
                className={classes.reportCell}
                width="20%"
              >
                <Typography
                  className={clsx(classes.reportCellText, {
                    [classes.alertText]: variance && variance.value > 0,
                  })}
                >
                  {formatVariance(varianceDisplay, budget, variance)}
                </Typography>
              </TableCell>
            </Fragment>
          );
        })}
      </TableRow>

      {isExpanded && (
        <LazyReportingTargetTier
          compCycleData={compCycleData}
          employee={employee}
          organizationName={organizationName}
          varianceDisplay={varianceDisplay}
          indentation={reportingIndentation}
        />
      )}
    </>
  );
}

TargetRow.fragments = {
  employee: gql`
    fragment TargetRow_employee on Employee {
      id
      displayName
      user {
        id
        photoURL
      }
      activeEmployment {
        id
        jobTitle
      }
      compCycleBudget(compCycleId: $compCycleId) {
        compCycleId
        employeeId
        targetCommission
        targetRecurringBonus
      }
      directReports {
        id
        compRecommendation(compCycleId: $compCycleId) {
          subjectId
          compCycleId
          latestSubmittedItems {
            id
            recommendationType
            cashEquivalent(currencyCode: $currencyCode)
          }
        }
      }
      indirectReports {
        id
        compRecommendation(compCycleId: $compCycleId) {
          subjectId
          compCycleId
          latestSubmittedItems {
            id
            recommendationType
            cashEquivalent(currencyCode: $currencyCode)
          }
        }
      }
    }
  `,
  compCycleData: gql`
    fragment TargetRow_compCycleData on CompCycle {
      id
      allowTargetCommission
      allowTargetRecurringBonus
    }
  `,
};
