import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { CondensedTableActivityCell2_participant as Participant } from "src/__generated__/graphql";
import { theme } from "src/theme";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { ActivityLogButton } from "./Actions/ActivityLogButton";
import { SMALL_COL_WIDTH } from "./dimensions";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    padding: theme.spacing(1, 0),
    justifyContent: "center",
  },
}));

type Props = {
  row: { original: Participant };
};

export function CondensedTableActivityCell2({
  row,
}: Props): JSX.Element | null {
  const employee = row.original;
  const classes = useStyles();
  const { compCycleId } = useTableData();

  return (
    <div className={classes.root}>
      <ActivityLogButton
        employeeId={employee.subject.id}
        compCycleId={compCycleId}
        lastActivityAt={employee.lastActivity?.submittedAt ?? null}
      />
    </div>
  );
}

CondensedTableActivityCell2.fragments = {
  participant: gql`
    fragment CondensedTableActivityCell2_participant on CompCycleParticipant {
      subject {
        id
      }
      lastActivity {
        submittedAt
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        reviewStatus
      }
      phaseTimeline {
        id
        phaseId
        status
        phase {
          id
          phaseOrder
        }
      }
    }
  `,
  compCycle2: gql`
    fragment CondensedTableActivityCell2_compCycle2 on CompCycle2 {
      id
      currentPhase {
        id
        phaseOrder
        startDate
      }
    }
  `,
};

CondensedTableActivityCell2.Header = ColumnIdsToHeaders.get(ColumnIds.ACTIVITY);
CondensedTableActivityCell2.id = ColumnIds.ACTIVITY;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableActivityCell2,
  Header: CondensedTableActivityCell2.Header,
  id: CondensedTableActivityCell2.id,
  width: SMALL_COL_WIDTH,
  defaultCanSort: false,
};

CondensedTableActivityCell2.column = column;
