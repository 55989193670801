import { gql, useQuery } from "@apollo/client";
import uniqBy from "lodash/uniqBy";
import { useState } from "react";
import { useAuth } from "src/components/Auth/AuthContext";
import { getGuidance } from "src/models/MeritGuidance";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { useTableData } from "src/pages/CompCycle/Plan/Contexts/TableDataContext2";
import {
  CompCycleSingleRecModal_participant,
  CompCycleSingleRecQuery,
  CompCycleSingleRecQueryVariables,
  CurrencyCode,
  MatrixTypeEnum,
} from "../../../__generated__/graphql";
import { CompCycleSingleRecModal } from "./CompCycleSingleRecModal";

type Props = {
  compCycleId: number;
  employeeId: number;
  isIndirectReport: boolean;
  isPhaseConfigurationPeriod: boolean;
  onClose: () => unknown;
  employeeCurrency: string | null;
};

export function CompCycleSingleRecLoadingBoundary({
  compCycleId,
  employeeId,
  isIndirectReport,
  isPhaseConfigurationPeriod,
  employeeCurrency,
  onClose,
}: Props): JSX.Element | null {
  const tableData = useTableData();
  const { permissions } = useAuth();
  const urlSearchParams = useURLSearchParams();
  const managerId = urlSearchParams.get("manager");
  const actingManagerEmployeeId =
    managerId != null && permissions.isHRBP()
      ? Number.parseInt(managerId)
      : null;
  const selectedCurrency = employeeCurrency as CurrencyCode;
  const [revisedPerfRating, setRevisedPerfRating] = useState<string | null>(
    tableData.employees.find((p) => p.subjectId === employeeId)?.perfRating ??
      null
  );
  const { data, loading } = useQuery<
    CompCycleSingleRecQuery,
    CompCycleSingleRecQueryVariables
  >(CompCycleSingleRecLoadingBoundary.query, {
    variables: {
      compCycleId,
      employeeId,
      currencyCode: selectedCurrency,
      actingManagerEmployeeId,
      filter: null,
    },
    onCompleted: (data) => {
      setRevisedPerfRating(data.compCycle2?.participant?.perfRating ?? null);
    },
  });

  const participant = data?.compCycle2?.participant ?? {
    __typename: "CompCycleParticipant",
    compCycleId: tableData.compCycleId,
    compRecommendation: {
      latestSubmittedItems: [],
      allSubmittedItems: [],
      allSubmittedReviews: [],
    },
    subject: {
      minimalManager: {
        displayName: "Loading...",
      },
      adjustedCashBands: [],
      activeEmployment: null,
      activeCashCompensation: null,
      ...(tableData.employees.find((p) => p.subjectId === employeeId)
        ?.subject ?? null),
    },
    subjectId: employeeId,
    managerCompCycleBudget: null,
    perfRating: revisedPerfRating,
  };

  const meritMatrices = tableData.matrices.filter(
    (matrix) => matrix.type === MatrixTypeEnum.MERIT
  );

  const meritMatrix =
    meritMatrices.length > 1
      ? meritMatrices.find((matrix) => {
          return (matrix.eligibleParticipants as number[]).includes(employeeId);
        })
      : meritMatrices.at(0);

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
    })) ?? [];

  const perfRatingOptions = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const guidance = getGuidance(
    {
      ...participant.subject,
      perfRating: revisedPerfRating ?? participant.perfRating,
    },
    matrixGuides,
    matrixRanges,
    perfRatingOptions
  );

  if (!tableData.valuation) return null;

  return (
    <CompCycleSingleRecModal
      selectedCurrency={selectedCurrency}
      handleClose={onClose}
      compCycleId={compCycleId}
      employee={participant as CompCycleSingleRecModal_participant}
      isIndirectReport={isIndirectReport}
      isPhaseConfigurationPeriod={isPhaseConfigurationPeriod}
      positions={tableData.availablePositions}
      currentValuation={tableData.valuation}
      compCycle={data?.compCycle2 ?? tableData.compCycle!}
      guidance={guidance}
      perfRatingOptions={perfRatingOptions}
      revisedPerfRating={revisedPerfRating}
      setRevisedPerfRating={setRevisedPerfRating}
      budget={tableData.budget ?? null}
      loading={loading}
      shouldApplyGuidance={data?.compCycle2?.recommendationsPreFill}
    />
  );
}

CompCycleSingleRecLoadingBoundary.query = gql`
  ${CompCycleSingleRecModal.fragments.participant}
  ${CompCycleSingleRecModal.fragments.compCycle}

  query CompCycleSingleRecQuery(
    $compCycleId: Int!
    $employeeId: Int!
    $currencyCode: CurrencyCode
    $skipEligibility: Boolean = true
    $actingManagerEmployeeId: Int
    $filter: GetParticipantsInput
  ) {
    compCycle2(id: $compCycleId) {
      id
      recommendationsPreFill
      ...CompCycleSingleRecModal_compCycle
      participant(id: $employeeId) {
        subjectId
        compCycleId
        ...CompCycleSingleRecModal_participant
        subject {
          activeEmployment {
            id
            positionId
          }
          adjustedCashBands {
            id
            name
            bandPoints {
              id
              bandName
              name
              value {
                ... on CashValue {
                  annualRate
                  hourlyRate
                  currencyCode
                }
              }
            }
          }
          activeCashCompensation(currencyCode: $currencyCode) {
            type
            annualCashEquivalent
            hourlyCashEquivalent
            unit
            employeeId
          }
        }
      }
    }
  }
`;
