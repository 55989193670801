import { gql } from "@apollo/client";
import { cleanEmail } from "@asmbl/shared/email";
import { JobStructureScope, PrimaryRoleName } from "@asmbl/shared/permissions";
import { isEmptyString } from "@asmbl/shared/utils";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  ListSubheader,
  MenuItem,
  TextField,
  debounce,
  makeStyles,
} from "@material-ui/core";
import { capitalCase } from "change-case";
import { useState } from "react";
import {
  UserInviteModal_departments as Department,
  UserInviteModal_organization as Organization,
} from "../../../../__generated__/graphql";
import { SelectField } from "../../../../components/Form/SelectField";
import { EditorStatus } from "../../../../constants";
import { useEmailValidator } from "../../../../models/Email";
import { useInviteUserToAssemble } from "../../../../mutations/User";
import { useEmplaceCompensationUserInvitationAccessGrant } from "../../../../mutations/UserInvitation";
import { GRAY_2 } from "../../../../theme";
import { AssembleTypography } from "../../../AssembleTypography";
import { ControlledSaveButton } from "../../../Form/SaveButton";
import {
  AccessCardGoodFor,
  AccessCardRow,
  roleDescriptions,
} from "../../UserAccessControl/PrimaryRoleForm";
import { SupportedManagersLoadingBoundary } from "./SupportedManagersLoadingBoundary";
import { UserInviteCompBandAccess } from "./UserInviteCompBandAccess";

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(4) },
  context: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  detailRowContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(0.5),
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    columnGap: theme.spacing(2),
  },
  textFieldWrapper: { height: theme.spacing(3), marginBottom: "2rem" },
  checkBoxWrapper: { marginLeft: "unset" },
  checkBoxText: { marginLeft: theme.spacing(1) },
  selectMenuItem: {
    paddingLeft: theme.spacing(4),
  },
}));

type Props = {
  isOpen: boolean;
  toggleUserInviteModal: () => void;
  setLatestUserInvitationId: (id: number) => void;
  organization: Organization;
  departments: Department[];
};

export function UserInviteModal({
  isOpen,
  toggleUserInviteModal,
  setLatestUserInvitationId,
  organization,
  departments,
}: Props): JSX.Element {
  const classes = useStyles();
  const emplaceCompensationUserInvitationAccessGrant =
    useEmplaceCompensationUserInvitationAccessGrant();

  const [editorStatus, setEditorState] = useState(EditorStatus.EDITING);
  const [workEmail, setWorkEmail] = useState("");
  const [name, setName] = useState("");
  const [supportedManagerIds, setSupportedManagerIds] = useState<
    number[] | null
  >(null);
  const [workEmailError, setWorkEmailError] = useState<string | null>(null);
  const [userInviteError, setUserInviteError] = useState("");
  const [primaryAccessGrant, setPrimaryAccessGrant] = useState(
    PrimaryRoleName.FULL_ACCESS
  );

  const [compBandAccess, setCompBandAccess] = useState<{
    [market: string]: JobStructureScope;
  } | null>(null);

  const emailValidation = useEmailValidator();
  const inviteUserToAssemble = useInviteUserToAssemble();

  const debouncedValidator = debounce(async (email: string) => {
    if (isEmptyString(email)) {
      return;
    }

    const result = await emailValidation(email);

    if (!result.valid) {
      setWorkEmailError(result.message);
    } else {
      setWorkEmailError(null);
    }
  }, 200);

  const onNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setName(e.target.value);
  };

  const onEmailChange = async (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): Promise<void> => {
    const email = e.target.value;
    setWorkEmail(email);
    await debouncedValidator(email);
  };

  const onSubmit = async () => {
    setEditorState(EditorStatus.SAVING);

    const result = await inviteUserToAssemble({
      email: cleanEmail(workEmail),
      roleName: primaryAccessGrant,
      name,
      supportedManagerIds,
    });

    // if the result is a string then its an error message
    // if it is not, then the result is the user invite
    if (typeof result === "string") {
      setEditorState(EditorStatus.ERROR);
      setUserInviteError(result);
      setTimeout(() => setEditorState(EditorStatus.EDITING), 3_000);
    } else {
      setWorkEmail("");
      setName("");
      setSupportedManagerIds(null);
      setWorkEmailError(null);
      setPrimaryAccessGrant(PrimaryRoleName.FULL_ACCESS);
      setEditorState(EditorStatus.EDITING);

      const invitationId = result.inviteUserToAssemble.invitation?.id;

      if (invitationId != null) {
        // need these comp band changes to be done sequentially so that each
        // change builds off of the last. If they were to all run in parallel
        // then there would be race conditions where the last function to run
        // would only be correct if the first two functions had already
        // resolved
        for (const market in compBandAccess) {
          const scope = compBandAccess[market];

          const marketId =
            market === "allMarkets" ? null : parseInt(market, 10);

          await emplaceCompensationUserInvitationAccessGrant(
            invitationId,
            marketId,
            scope
          );
        }

        setCompBandAccess(null);
        setLatestUserInvitationId(invitationId);
      }

      toggleUserInviteModal();
    }
  };

  const onCancel = () => {
    setWorkEmail("");
    setName("");
    setWorkEmailError(null);
    setPrimaryAccessGrant(PrimaryRoleName.FULL_ACCESS);
    setEditorState(EditorStatus.EDITING);
    setCompBandAccess(null);
    toggleUserInviteModal();
  };

  const isEmailInvalid = !isEmptyString(workEmailError?.trim());

  return (
    <Dialog className={classes.root} open={isOpen} maxWidth="sm">
      <DialogTitle>
        <AssembleTypography variant="h4">
          Invite a Collaborator
        </AssembleTypography>
      </DialogTitle>
      <DialogContent className={classes.context}>
        <AssembleTypography variant="productParagraphLarge" textColor={GRAY_2}>
          Invite a colleague to collaborate on integrations, correcting employee
          levels or viewing benchmarking data.
        </AssembleTypography>

        <TextField
          fullWidth={true}
          variant="outlined"
          name="name"
          label="Name"
          value={name}
          onChange={onNameChange}
        />

        <div className={classes.textFieldWrapper}>
          <TextField
            fullWidth={true}
            variant="outlined"
            name="workEmail"
            label="Work Email Address*"
            value={workEmail}
            onChange={onEmailChange}
            error={isEmailInvalid}
            helperText={workEmailError}
          />
        </div>

        <AssembleTypography variant="productHeader">
          Primary Access Role & Permissions
        </AssembleTypography>

        <SelectField
          id="primary-grant-select"
          label="Access Label*"
          value={primaryAccessGrant}
          onChange={setPrimaryAccessGrant}
        >
          <ListSubheader>Administrative</ListSubheader>
          <MenuItem
            className={classes.selectMenuItem}
            value={PrimaryRoleName.FULL_ACCESS}
          >
            {capitalCase(PrimaryRoleName.FULL_ACCESS)}
          </MenuItem>
          <MenuItem
            className={classes.selectMenuItem}
            value={PrimaryRoleName.SYSTEM_ADMIN}
          >
            {capitalCase(PrimaryRoleName.SYSTEM_ADMIN)}
          </MenuItem>
          <ListSubheader>Scoped Access</ListSubheader>
          <MenuItem
            className={classes.selectMenuItem}
            value={PrimaryRoleName.BASIC_VIEWER}
          >
            {capitalCase(PrimaryRoleName.BASIC_VIEWER)}
          </MenuItem>
          <MenuItem
            className={classes.selectMenuItem}
            value={PrimaryRoleName.HRBP}
          >
            {PrimaryRoleName.HRBP}
          </MenuItem>
          <MenuItem
            className={classes.selectMenuItem}
            value={PrimaryRoleName.RECRUITER}
          >
            {capitalCase(PrimaryRoleName.RECRUITER)}
          </MenuItem>
        </SelectField>

        <div className={classes.detailsContainer}>
          <div className={classes.detailRowContainer}>
            {roleDescriptions[primaryAccessGrant].check.map((text, idx) => (
              <AccessCardRow key={idx} isCheck text={text} />
            ))}
            {roleDescriptions[primaryAccessGrant].noCheck.map((text, idx) => (
              <AccessCardRow key={idx} text={text} />
            ))}
          </div>
          <AccessCardGoodFor
            text={roleDescriptions[primaryAccessGrant].goodFor}
          />
        </div>

        {primaryAccessGrant !== PrimaryRoleName.FULL_ACCESS &&
          primaryAccessGrant !== PrimaryRoleName.SYSTEM_ADMIN &&
          primaryAccessGrant !== PrimaryRoleName.HRBP && (
            <UserInviteCompBandAccess
              organization={organization}
              departments={departments}
              compBandAccess={compBandAccess}
              setCompBandAccess={setCompBandAccess}
            />
          )}

        {primaryAccessGrant === PrimaryRoleName.HRBP && (
          <SupportedManagersLoadingBoundary
            userName={name}
            onSave={setSupportedManagerIds}
            supportedManagerIds={supportedManagerIds}
          />
        )}

        {!isEmptyString(userInviteError) && (
          <AssembleTypography variant="productParagraphLarge" color="error">
            {userInviteError}
          </AssembleTypography>
        )}

        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <ControlledSaveButton
            onSave={onSubmit}
            disabled={isEmailInvalid || isEmptyString(workEmail)}
            hideEndIcon
            labels={{ default: "Invite" }}
            editorStatus={editorStatus}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

UserInviteModal.query = gql`
  query ValidateEmail($email: String!) {
    isDomainValid(email: $email)
    isEmailNotTaken(email: $email)
  }
`;

UserInviteModal.fragments = {
  organization: gql`
    ${UserInviteCompBandAccess.fragments.organization}
    fragment UserInviteModal_organization on Organization {
      ...UserInviteCompBandAccess_organization
      id
    }
  `,
  departments: gql`
    ${UserInviteCompBandAccess.fragments.departments}
    fragment UserInviteModal_departments on Department {
      ...UserInviteCompBandAccess_departments
      id
    }
  `,
};
