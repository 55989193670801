import { gql } from "@apollo/client";
import { getSalaryCashComp } from "@asmbl/shared/compensation";
import { multiply, zero } from "@asmbl/shared/money";
import { mapMaybe } from "@asmbl/shared/utils";
import { useCompStructure } from "src/components/CompStructureContext";
import {
  CashCompType,
  CompUnit,
  RecItemType,
  SalaryChange_cashCompensation,
  SalaryChange_recItem,
} from "../../../__generated__/graphql";
import { BudgetType } from "../../../models/Budget";
import { getTotalCashAfterRecommendation } from "../../../models/CashCompensation";
import {
  CompItemRecommendationTypeTitle,
  whereType,
} from "../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../models/Currency";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { CompComponentContainer } from "./CompComponentContainer";
import { CompValue } from "./CompValue";
import { LabelValue } from "./LabelValue";

type RecItemWithValue = SalaryChange_recItem & {
  recommendedCashValue: GraphQL_Money;
};

type Props = {
  recItems: SalaryChange_recItem[];
  cashCompensations: SalaryChange_cashCompensation[];
};

export function SalaryChange({
  recItems,
  cashCompensations,
}: Props): JSX.Element | null {
  const { defaultCurrencyCode } = useCurrencies();
  const { compStructure } = useCompStructure();
  const salaryRecItems = mapMaybe(
    [RecItemType.PROMOTION, RecItemType.MARKET, RecItemType.MERIT_INCREASE],
    (type) =>
      recItems
        .filter(
          (item): item is RecItemWithValue =>
            item.recommendedCashValue !== null ||
            item.recommendedPercentValue !== null
        )
        .find(whereType(type))
  );

  if (salaryRecItems.length === 0) {
    return null;
  }

  const prevSalary = getSalaryCashComp(cashCompensations)?.annualCashEquivalent;
  const newSalary =
    getTotalCashAfterRecommendation(
      defaultCurrencyCode,
      cashCompensations,
      recItems,
      compStructure?.workingHoursPerYear
    )?.subcomponents.find((item) => item.type === CashCompType.SALARY)
      ?.annualCashEquivalent ??
    zero(salaryRecItems[0].recommendedCashValue.currency);

  return (
    <CompComponentContainer dashed>
      <AssembleTypography
        variant="productEyebrowSmall"
        color="textSecondary"
        gutterBottom
      >
        {BudgetType.SALARY}
      </AssembleTypography>
      {salaryRecItems.map((item) => (
        <LabelValue
          key={item.recommendationType}
          label={CompItemRecommendationTypeTitle[item.recommendationType]}
          compValue={
            <CompValue
              value={getSimpleCashLabel(
                item.recommendedPercentValue && prevSalary
                  ? multiply(prevSalary, item.recommendedPercentValue / 100)
                  : item.recommendedCashValue,
                false,
                item.unitType === CompUnit.HOURLY_CASH
              )}
            />
          }
        />
      ))}
      <LabelValue
        label="Salary Change"
        compValue={
          <CompValue
            previousValue={prevSalary && getSimpleCashLabel(prevSalary)}
            value={getSimpleCashLabel(newSalary)}
          />
        }
      />
    </CompComponentContainer>
  );
}

SalaryChange.fragments = {
  recItem: gql`
    fragment SalaryChange_recItem on RecItem {
      recommendationType
      recommendedCashValue
      recommendedPercentValue
      unitType
    }
  `,
  cashCompensation: gql`
    fragment SalaryChange_cashCompensation on CashCompensation {
      type
      annualCashEquivalent
      hourlyCashEquivalent
      unit
      percentOfSalary
    }
  `,
};
