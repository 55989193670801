export enum FeatureFlag {
  Default = "Default", // We need to have at least one enum value for typechecking
  AdHocCompRecommendations = "ad_hoc_comp_recommendations",
  AutomaticLeveling = "automatic_leveling",
  CompCycleBonusDashboard = "comp_cycle_bonus_dashboard",
  CompCycleCompaRatioDashboard = "comp_cycle_compa_ratio_dashboard",
  CompCycleDashboard = "comp_cycle_dashboard",
  DisableCustomerDataWarningBanner = "disable_customer_warning_banner",
  EmployeePortal = "employee_portal",
  HideLastCompChange = "hide_last_comp_change",
  HourlyEmployees = "hourly_employees",
  HourlyEmployeesInCompCycles = "hourly_employees_in_comp_cycles",
  LeakyBandsCsvExport = "leaky_bands_csv_export",
  OffersModule = "offers_module",
  OrderCompCyclePlanPage = "order_plan_page",
  OrganizationHierarchyModule = "organization_hierarchy_module",
  PerformanceRatingDropdown = "performance_rating_dropdown",
  SequentialApprovalsManagerFlow = "sequential_approvals_manager_flow",
  SsoOnly = "sso_only",
  IndividualAccessToCompCycleDashboard = "individual_access_to_comp_cycle_dashboard",
  CompCycleConfigurationV2 = "comp_cycle_configuration_v2",
  CompCycleBulkActions = "comp_cycle_bulk_actions",
  CompCycleProgressPage = "comp_cycle_progress_page",
  BandBuilder = "band_builder",
  MultipleMeritMatrices = "multiple_merit_matrices",
}

export enum FeatureTextFlag {
  OfferApprovalJobCodeModal = "offer_approval_job_code_modal_url",
}
