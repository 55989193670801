import { dollars } from "@asmbl/shared/money";
import { createContext, ReactNode, useContext } from "react";
import {
  AllocateBudgets_compCycleSettings as CompCycleSettings,
  AllocateBudgets_valuation as Valuation,
} from "src/__generated__/graphql";
import { useAuth } from "src/components/Auth/AuthContext";

interface AllocateBudgetsContextProps {
  cycleSettings: CompCycleSettings;
  organizationName: string;
  valuation: Valuation;
}

const AllocateBudgetsContext = createContext<AllocateBudgetsContextProps>({
  cycleSettings: {
    __typename: "CompCycle",
    id: 0,
    name: "",
    endedAt: null,
    allowSalary: false,
    allowEquity: false,
    allowBonus: false,
    allowTargetCommission: false,
    allowTargetRecurringBonus: false,
    allowActualRecurringBonus: false,
    allowSalaryMarket: false,
    allowSalaryPromotion: false,
    allowSalaryMerit: false,
    matrices: [],
  },
  organizationName: "",
  valuation: {
    __typename: "Valuation",
    id: 0,
    fdso: 0,
    valuation: dollars(0),
  },
});

type Props = {
  children: ReactNode;
  cycleSettings: CompCycleSettings;
  valuation: Valuation;
};

export const AllocateBudgetsProvider = ({
  children,
  cycleSettings,
  valuation,
}: Props) => {
  const { organization } = useAuth();
  const organizationName = organization?.name ?? "";
  return (
    <AllocateBudgetsContext.Provider
      value={{ cycleSettings, organizationName, valuation }}
    >
      {children}
    </AllocateBudgetsContext.Provider>
  );
};

export const useAllocateBudgetsContext = (): AllocateBudgetsContextProps => {
  return useContext(AllocateBudgetsContext);
};
