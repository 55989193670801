import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { zero } from "@asmbl/shared/money";
import {
  CondensedTableNewTargetRecurringBonusCell2_participant as Participant,
  RecItemType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = { row: { original: Participant } };

export function CondensedTableNewTargetRecurringBonusCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();

  const bonus = employee.compRecommendation?.latestSubmittedItems.find(
    (item) => item.recommendationType === RecItemType.TARGET_RECURRING_BONUS
  )?.recommendedCashValue;

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        bonus ??
          zero(
            (employee.subject.activeEmployment?.payCurrencyCode ??
              defaultCurrencyCode) as CurrencyCode
          )
      )}{" "}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewTargetRecurringBonusCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewTargetRecurringBonusCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          payCurrencyCode
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableNewTargetRecurringBonusCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_TARGET_RECURRING_BONUS
);
CondensedTableNewTargetRecurringBonusCell2.id =
  ColumnIds.NEW_TARGET_RECURRING_BONUS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewTargetRecurringBonusCell2,
  Header: CondensedTableNewTargetRecurringBonusCell2.Header,
  id: CondensedTableNewTargetRecurringBonusCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableNewTargetRecurringBonusCell2.column = column;
