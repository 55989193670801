import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const LocationGroupsIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Location Groups"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M8 6C6.89543 6 6 6.89543 6 8C6 8.44593 6.14594 8.85778 6.39267 9.19038"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.8194 3.87068C9.99445 3.30745 9.01307 3 8 3C6.67392 3 5.40215 3.52678 4.46447 4.46447C3.52678 5.40215 3 6.67392 3 8C3 10.8653 5.02721 13.3252 6.5 14.7343"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M15 14.0625C16.2426 14.0625 17.25 13.0551 17.25 11.8125C17.25 10.5699 16.2426 9.5625 15 9.5625C13.7574 9.5625 12.75 10.5699 12.75 11.8125C12.75 13.0551 13.7574 14.0625 15 14.0625Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M20.625 11.8125C20.625 16.875 15 20.8125 15 20.8125C15 20.8125 9.375 16.875 9.375 11.8125C9.375 10.3207 9.96763 8.88992 11.0225 7.83502C12.0774 6.78013 13.5082 6.1875 15 6.1875C16.4918 6.1875 17.9226 6.78013 18.9775 7.83502C20.0324 8.88992 20.625 10.3207 20.625 11.8125V11.8125Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
