import { useLazyQuery } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { usePagination } from "src/pages/CompCycle/Plan/Contexts/PaginationContext";
import { GetEmployeeRecommendationsCsvExport } from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import { GET_EMPLOYEE_RECOMMENDATIONS_CSV_EXPORT } from "../../queries";
import { AssembleButton } from "../AssembleButton/AssembleButton";
import { AssembleTypography } from "../AssembleTypography";
import { CsvDownloader } from "./CsvDownloader";
import { CsvExport } from "./CsvExport";

const useStyles = makeStyles((theme) => ({
  spacer: { width: "1rem" },
  secondary: { background: theme.palette.secondary.main },
  cancel: { marginTop: "1rem", marginRight: "1rem" },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
    width: "100%",
  },
}));

type CsvExportProps = {
  compCycleId: number;
  compCycleName: string;
  variant: "icon" | "button" | "tableButton";
};

export function EmployeeRecsCsvExportWrapper({
  compCycleId,
  compCycleName,
  variant,
}: CsvExportProps): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const { currentEmployeeIds: employeeIds } = usePagination();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [getEmployeeRecExport, { loading }] =
    useLazyQuery<GetEmployeeRecommendationsCsvExport>(
      GET_EMPLOYEE_RECOMMENDATIONS_CSV_EXPORT,
      {
        variables: {
          compCycleId,
          employeeIds,
        },
        fetchPolicy: "no-cache",
      }
    );

  const handleClick = async (all: boolean) => {
    trackEvent({ object: "Review Requests", action: "Exported" });

    enqueueSnackbar("Exporting CSV, your file will be available shortly.", {
      variant: "info",
      autoHideDuration: 10_000,
    });

    return getEmployeeRecExport({
      variables: {
        compCycleId,
        employeeIds: all ? null : employeeIds,
      },
    }).then((result) => result.data?.exportEmployeeRecommendationsToCsv.data);
  };

  return (
    <>
      <CsvDownloader
        errorMessage="There are no reviews to export."
        fetch={(all) => handleClick(all as boolean)}
        filename={`${compCycleName} Employee Requests`}
      >
        {(onDownload) => (
          <>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <div className={classes.flex}>
                <DialogTitle>
                  <AssembleTypography>Export Selection</AssembleTypography>
                </DialogTitle>
                <AssembleButton
                  variant="outlined"
                  className={classes.cancel}
                  size="small"
                  label={"Cancel"}
                  onClick={() => setDialogOpen(false)}
                />
              </div>
              <DialogContent>
                <DialogActions className={classes.flex}>
                  <AssembleButton
                    variant="contained"
                    size="medium"
                    label={"Export all"}
                    onClick={() => {
                      onDownload(true);
                      setDialogOpen(false);
                    }}
                  />
                  <AssembleButton
                    variant="contained"
                    className={classes.secondary}
                    size="medium"
                    label="Export current page"
                    disabled={loading}
                    onClick={() => {
                      onDownload(false);
                      setDialogOpen(false);
                    }}
                  />
                </DialogActions>
              </DialogContent>
            </Dialog>
            <CsvExport
              variant={variant}
              onClick={() => setDialogOpen(true)}
              loading={loading}
            />
          </>
        )}
      </CsvDownloader>
    </>
  );
}
