import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Money, zero } from "@asmbl/shared/money";
import {
  CashCompType,
  CondensedTableCurrentTargetRecurringBonusCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { activeComp, cashValueOf } from "../../../../models/CashCompensation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

function getBonus(
  employee: Participant,
  defaultCurrencyCode: CurrencyCode
): Money {
  const activeCashComp = activeComp(
    employee.subject.activeCashCompensation ?? [],
    new Date()
  );
  return (
    cashValueOf(CashCompType.RECURRING_BONUS, activeCashComp) ??
    zero(defaultCurrencyCode)
  );
}

export function CondensedTableCurrentTargetRecurringBonusCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        getBonus(
          employee,
          (employee.subject.activeEmployment?.payCurrencyCode ??
            defaultCurrencyCode) as CurrencyCode
        )
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentTargetRecurringBonusCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentTargetRecurringBonusCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          payCurrencyCode
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          unit
          annualCashEquivalent
          hourlyCashEquivalent
          percentOfSalary
        }
      }
    }
  `,
};

CondensedTableCurrentTargetRecurringBonusCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_TARGET_RECURRING_BONUS
);
CondensedTableCurrentTargetRecurringBonusCell2.id =
  ColumnIds.CURRENT_TARGET_RECURRING_BONUS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentTargetRecurringBonusCell2,
  Header: CondensedTableCurrentTargetRecurringBonusCell2.Header,
  id: CondensedTableCurrentTargetRecurringBonusCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableCurrentTargetRecurringBonusCell2.column = column;
