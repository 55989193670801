import { useSnackbar } from "notistack";
import { useRef } from "react";

type CsvDownloaderProps = {
  fetch: (...args: unknown[]) => Promise<string | undefined | null>;
  filename: string;
  children: (onDownload: (...args: unknown[]) => unknown) => JSX.Element;
  errorMessage?: string;
};

export function CsvDownloader({
  fetch,
  filename,
  children,
  errorMessage = "Error downloading CSV.",
}: CsvDownloaderProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const aRef = useRef<HTMLAnchorElement>(null);

  const download = async (data: Promise<string | undefined | null>) => {
    const csv = await data;
    const element = aRef.current;

    if (
      csv === undefined ||
      csv === null ||
      element === null ||
      csv.length === 0
    ) {
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
      return;
    }

    const file = new Blob([csv], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = filename.replaceAll(".", "_");
    element.click();
  };

  return (
    <>
      <a ref={aRef} hidden />
      {children((...args) => download(fetch(...args)))}
    </>
  );
}
