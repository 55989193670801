import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTrack } from "src/analytics";
import {
  OveragesSelector_budgetOverage as BudgetOverage,
  AllocateBudgets_compCycleBudget as RootBudget,
  AllocateBudgets_compCycleBudgetDraft as RootBudgetDraft,
} from "../../../__generated__/graphql";
import {
  CompleteReviewDialog,
  useCompleteReviewDialogState,
} from "../../../components/CompCycle/Dialogs/CompleteReviewDialog";
import { CompCycleGrouping } from "../../../components/CompCycle/types";
import { AllocationUnit } from "../../../models/Budget";
import { defaultComponent } from "../../../models/CompCycle";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import {
  DraftBudgetInput,
  useDraftBudget,
  useGenerateBudgetFromMatrix,
  usePublishBudgets,
} from "../../../mutations/CompCycleBudget";
import { useAllocateBudgetsContext } from "./Context/AllocateBudgetsContext";
import { AllocateBudgetsHeader } from "./Header/AllocateBudgetsHeader";
import { BudgetAllocationTable } from "./Table/BudgetAllocationTable";
import { TableHeader } from "./TableHeader/TableHeader";

type Props = {
  rootBudgetDraft: RootBudgetDraft;
  rootBudget: RootBudget | null;
  overages: BudgetOverage[] | undefined;
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 5rem)", // Don't scroll, let the table do it
  },
}));

export function AllocateBudgets({
  rootBudgetDraft,
  rootBudget,
  overages,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();
  const { cycleSettings } = useAllocateBudgetsContext();
  const [loading, setLoading] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [pageEditCount, setPageEditCount] = useState(0);
  const [equityDisplay, setEquityDisplay] = useState(AllocationUnit.CASH);
  const [dialogState, setDialogState] = useCompleteReviewDialogState(
    cycleSettings.endedAt
  );

  const { trackEvent } = useTrack();

  const draftBudget = useDraftBudget(rootBudgetDraft.compCycleId);

  const handleDraftBudget = useCallback(
    async (employeeId: number | null, budget: DraftBudgetInput) => {
      setLoading(true);
      setPageEditCount((count) => count + 1);
      return draftBudget(employeeId, budget).finally(() => setLoading(false));
    },
    [draftBudget, setPageEditCount]
  );

  const publishBudgets = usePublishBudgets(rootBudgetDraft.compCycleId);
  const generateBudgetFromMatrix = useGenerateBudgetFromMatrix(
    rootBudgetDraft.compCycleId
  );

  const handleGenerateBudget = () => {
    trackEvent({
      object: "Budget Allocation",
      action: "Rule Applied",
      compCycleId: cycleSettings.id,
    });
    setBudgetLoading(true);
    // We poll for new budget info every second, so no need to fetch the
    // results immediately in a `then` block
    generateBudgetFromMatrix()
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setBudgetLoading(false);
      });
  };

  const subPage = (urlSearchParams.get("subPage") ??
    defaultComponent(cycleSettings)) as CompCycleGrouping;
  const setTableSubPage = (subPage: CompCycleGrouping) =>
    navigate(`?${urlSearchParams.set("subPage", subPage).toString()}`, {
      replace: true,
    });

  return (
    <>
      {dialogState !== "open" && (
        <CompleteReviewDialog
          compCycle={cycleSettings}
          dialogState={dialogState}
          setDialogState={setDialogState}
        />
      )}
      <div className={classes.root}>
        <AllocateBudgetsHeader
          compCycleBudgetDraft={rootBudgetDraft}
          loading={loading}
          pageEditCount={pageEditCount}
          publishBudgets={publishBudgets}
          overages={overages}
          generateBudgetFromMatrix={handleGenerateBudget}
          budgetLoading={budgetLoading}
        />
        <TableHeader
          employee={rootBudgetDraft.employee}
          selected={subPage}
          equityDisplay={equityDisplay}
          onSetEquityDisplay={setEquityDisplay}
          onChange={setTableSubPage}
        />
        <BudgetAllocationTable
          key={subPage}
          compComponent={subPage}
          rootBudgetDraft={rootBudgetDraft}
          rootBudget={rootBudget}
          equityDisplay={equityDisplay}
          onSaveDraft={handleDraftBudget}
        />
      </div>
    </>
  );
}

AllocateBudgets.fragments = {
  compCycleBudgetDraft: gql`
    ${TableHeader.fragments.compCycleBudgetDraft}
    ${BudgetAllocationTable.fragments.compCycleBudgetDraft}
    fragment AllocateBudgets_compCycleBudgetDraft on CompCycleBudgetDraft {
      ...TableHeader_compCycleBudgetDraft
      ...BudgetAllocationTable_compCycleBudgetDraft
    }
  `,
  compCycleBudget: gql`
    ${BudgetAllocationTable.fragments.compCycleBudget}
    fragment AllocateBudgets_compCycleBudget on CompCycleBudget {
      ...BudgetAllocationTable_compCycleBudget
    }
  `,
  compCycleSettings: gql`
    ${TableHeader.fragments.compCycleSettings}
    ${BudgetAllocationTable.fragments.compCycleSettings}
    ${CompleteReviewDialog.fragments.compCycleSettings}
    ${AllocateBudgetsHeader.fragments.compCycleSettings}
    fragment AllocateBudgets_compCycleSettings on CompCycle {
      ...CompleteReviewDialog_compCycleSettings
      ...TableHeader_compCycleSettings
      ...BudgetAllocationTable_compCycleSettings
      ...AllocateBudgetsHeader_compCycleSettings
    }
  `,
  valuation: gql`
    ${BudgetAllocationTable.fragments.valuation}
    fragment AllocateBudgets_valuation on Valuation {
      ...BudgetAllocationTable_valuation
    }
  `,
};
