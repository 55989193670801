import { Currency, exchangeCurrency } from "@asmbl/shared/currency";
import { map, Money, multiply } from "@asmbl/shared/money";
import { cx } from "@emotion/css";
import {
  makeStyles,
  TableCell,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { EquityGrantMethod } from "../../../__generated__/graphql";
import { currencySymbol } from "../../../models/Currency";
import { calculateUnitCount } from "../../../models/Money";
import { GRAY_4, GRAY_6, GRAY_8, PURPLE_2 } from "../../../theme";
import { useCompStructure } from "../../CompStructureContext";
import { useCurrencies } from "../../CurrenciesContext";
import { DogEarWithTooltip } from "./DogEarWithTooltip";

interface Props {
  onChange: (newGrant: Money) => unknown;
  equityGrant: Money | null;
  sharePrice: Money;
  localCurrency: Currency;
  hasUnpublishedChanges?: boolean;
}

const useStyles = makeStyles(() => ({
  inputCell: {
    position: "relative",
    "&$inputCell": {
      padding: 0,
    },
  },
  inputComponent: {
    borderRadius: 0,
    border: `1px solid ${GRAY_6}`,
    borderBottom: "none",
    boxShadow: "none",
    transition: "none",
    height: "2.5rem",
    fontSize: "0.8125rem",

    "&:hover": {
      background: GRAY_8,
      borderColor: GRAY_6,
      borderBottom: "none",
      transition: "none",
    },

    "&:focus-within, &.Mui-focused": {
      borderColor: "transparent",
      borderBottom: "none",
      boxShadow: "none",
      outline: `2px solid ${PURPLE_2}`,
      outlineOffset: "-2px",
      transition: "none",

      "& $symbolAdornment": {
        color: PURPLE_2,
      },
    },
  },
  inputContainer: { display: "flex", justifyContent: "flex-end" },
  valueRoot: {
    flex: "1 0 0",
  },
  shareRoot: {
    flex: "1 0 0",
  },
  shareInputComponent: {
    borderLeft: "none",
  },
  input: {
    paddingBlock: 0,
    textAlign: "right",
  },
  symbolAdornment: {
    color: GRAY_4,
    fontSize: "0.875rem",
  },
  emptyCell: {
    border: `1px solid ${GRAY_6}`,
    borderLeft: "none",
    borderBottom: "none",
  },
}));

export function EquityGrantInputCell({
  equityGrant,
  sharePrice,
  localCurrency,
  onChange,
  hasUnpublishedChanges = false,
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { defaultCurrency } = useCurrencies();
  const classes = useStyles();
  const absolute = equityGrant?.value;
  const [absoluteInput, setAbsoluteInput] = useState(absolute ?? 0);

  const [shareInput, setShareInput] = useState(
    equityGrant
      ? calculateUnitCount(
          defaultCurrency,
          sharePrice,
          equityGrant,
          localCurrency
        )
      : 0
  );
  const error = absoluteInput % 1 !== 0;

  // Handle situations where the value prop changes after the component mounts
  useEffect(() => {
    if (absolute) {
      setAbsoluteInput(equityGrant.value);
      setShareInput(
        calculateUnitCount(
          defaultCurrency,
          sharePrice,
          equityGrant,
          localCurrency
        )
      );
    }
  }, [absolute, defaultCurrency, equityGrant, localCurrency, sharePrice]);

  const handleAbsolute = ({ floatValue = 0 }: NumberFormatValues) => {
    setAbsoluteInput(floatValue);
    setShareInput(
      calculateUnitCount(
        defaultCurrency,
        sharePrice,
        { currency: localCurrency.code, value: floatValue },
        localCurrency
      )
    );

    if (floatValue !== equityGrant?.value) {
      onChange({ value: floatValue, currency: localCurrency.code });
    }
  };

  const handleShares = ({ floatValue = 0 }: NumberFormatValues) => {
    setShareInput(floatValue);

    const totalGrantValue = map(Math.round, multiply(sharePrice, floatValue));
    const newTotalGrant = exchangeCurrency(totalGrantValue, localCurrency);

    setAbsoluteInput(newTotalGrant.value);
    if (newTotalGrant.value !== equityGrant?.value) {
      onChange(newTotalGrant);
    }
  };

  return (
    <TableCell role="gridcell" className={classes.inputCell} colSpan={3}>
      <div className={classes.inputContainer}>
        {hasUnpublishedChanges && <DogEarWithTooltip />}
        <NumberFormat
          customInput={TextField}
          variant="outlined"
          onValueChange={handleAbsolute}
          value={absoluteInput === 0 ? null : absoluteInput}
          error={error}
          placeholder="0"
          className={classes.valueRoot}
          InputProps={{
            className: classes.inputComponent,
            startAdornment: (
              <Typography className={classes.symbolAdornment}>
                {currencySymbol(localCurrency.code)}
              </Typography>
            ),
          }}
          inputProps={{
            className: classes.input,
            "data-cy": "equity-input-cell",
          }}
          thousandSeparator
          isAllowed={(value) => !value.value.includes(".")}
        />
        {compStructure?.equityGrantMethod !== EquityGrantMethod.CASH ? (
          <NumberFormat
            customInput={TextField}
            variant="outlined"
            onValueChange={handleShares}
            value={shareInput === 0 ? null : shareInput}
            placeholder="0"
            className={classes.shareRoot}
            InputProps={{
              className: `${classes.inputComponent} ${classes.shareInputComponent}`,
              startAdornment: (
                <Typography className={classes.symbolAdornment}>
                  &nbsp;#
                </Typography>
              ),
            }}
            inputProps={{ className: classes.input }}
            thousandSeparator
          />
        ) : (
          <div className={cx([classes.shareRoot, classes.emptyCell])} />
        )}
      </div>
    </TableCell>
  );
}
