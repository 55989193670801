import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useDeleteMatrix } from "src/mutations/Matrix";
import { GRAY_4, RED } from "src/theme";
import { useMultipleMeritMatrices } from "../useMultipleMatrices";

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "30rem",
    padding: theme.spacing(3),
    gap: theme.spacing(3),
  },
  contentRoot: {
    paddingLeft: theme.spacing(0),
  },
  title: {
    padding: 0,
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  actions: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
  deleteButton: {
    border: "1px solid transparent !important",

    "&> span > span": {
      color: `${RED} !important`,
    },

    "&.Mui-disabled": {
      "&> span > span": {
        color: `${GRAY_4} !important`,
      },
    },

    "&:hover, &:focus": {
      border: `1px solid ${RED} !important`,

      background: "none",
      boxShadow: "none",
    },
  },
}));

type Props = { handleClose: () => void };

export function EditGuidancePopulationModal({
  handleClose,
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    matrices: { selectedMatrix, meritMatrices, updateName },
    persistDelete,
  } = useMultipleMeritMatrices();

  const deleteMatrix = useDeleteMatrix();
  const [guidancePopulationName, setGuidancePopulationName] = useState(
    selectedMatrix?.name ?? ""
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGuidancePopulationName(event.target.value);
  };

  const handleDelete = () => {
    void persistDelete(deleteMatrix);
    handleClose();
  };

  const handleNameSave = () => {
    updateName(guidancePopulationName);
    handleClose();
  };

  return (
    <Dialog open={true} classes={{ paper: classes.paper }} maxWidth="md">
      <DialogTitle className={classes.title}>
        <AssembleTypography variant="productHeader">
          Edit this Guidance Population
        </AssembleTypography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <div className={classes.content}>
          <TextField
            fullWidth={true}
            variant="outlined"
            name="guidancePopulationName"
            label="Population Name"
            value={guidancePopulationName}
            onChange={handleChange}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <AssembleButton
          className={classes.deleteButton}
          variant="text"
          size="small"
          label="Delete Guidance Population"
          onClick={handleDelete}
          disabled={meritMatrices.length === 1}
        />
        <div className={classes.buttonGroup}>
          <AssembleButton
            variant="text"
            size="small"
            label="Cancel"
            onClick={handleClose}
          />
          <AssembleButton
            variant="contained"
            size="small"
            label="Save"
            onClick={handleNameSave}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
