import { gql, useQuery } from "@apollo/client";
import {
  SalaryRow_compCycleData as CompCycleData,
  GetLazyReportingSalaryTierData,
  SalaryRow_employee,
} from "../../../../__generated__/graphql";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { SkeletonRow } from "../../Budgets/Table/SkeletonRow";
import { ReportingSalaryTier } from "./ReportingSalaryTier";

type Props = {
  employee: SalaryRow_employee;
  compCycleData: CompCycleData;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  indentation: IndentationCells;
  subComponentCount: number;
};

export function LazyReportingSalaryTier({
  employee,
  compCycleData,
  organizationName,
  varianceDisplay,
  indentation,
  subComponentCount,
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();

  const { data, loading } = useQuery<GetLazyReportingSalaryTierData>(
    LazyReportingSalaryTier.query(),
    {
      variables: {
        compCycleId: compCycleData.id,
        employeeId: employee.id,
        currencyCode: defaultCurrencyCode,
      },
      pollInterval: 10_000,
    }
  );

  // subComponentCount + 1 because the subComponentCount does not include the
  // total column
  // subComponentCount * 3 = the number of sub-component columns (since each
  // sub-component has 3 columns)
  if (loading) {
    return (
      <SkeletonRow
        subComponents={(subComponentCount + 1) * 3}
        indentation={indentation}
      />
    );
  }
  if (!data || !data.compCycle) {
    return (
      <tr>
        <td>Error</td>
      </tr>
    );
  }

  return (
    <ReportingSalaryTier
      rootBudget={data.compCycleBudget}
      manager={employee}
      reports={data.employees}
      organizationName={organizationName}
      varianceDisplay={varianceDisplay}
      indentation={indentation}
      subComponentCount={subComponentCount}
      compCycleData={compCycleData}
    />
  );
}

LazyReportingSalaryTier.query = () => gql`
  ${ReportingSalaryTier.fragments.reports}
  ${ReportingSalaryTier.fragments.compCycleBudget}
  ${ReportingSalaryTier.fragments.compCycleData}
  query GetLazyReportingSalaryTierData(
    $compCycleId: Int!
    $employeeId: Int!
    $currencyCode: CurrencyCode!
  ) {
    compCycleBudget(compCycleId: $compCycleId, employeeId: $employeeId) {
      compCycleId
      employeeId
      ...ReportingSalaryTier_compCycleBudget
    }
    employees(managerId: $employeeId) {
      ...ReportingSalaryTier_reports
    }
    compCycle(id: $compCycleId) {
      id
      ...ReportingSalaryTier_compCycleData
    }
  }
`;
