import { gql } from "@apollo/client";
import { add, Money, subtract } from "@asmbl/shared/money";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Fragment, useState } from "react";
import {
  SalaryRow_compCycleData as CompCycleData,
  SalaryRow_employee as Employee,
  RecItemType,
  SalaryRow_report as Report,
} from "../../../../__generated__/graphql";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { formatBudget } from "../../../../models/Budget";
import {
  calculateRequestsFromReports,
  formatRequest,
  formatVariance,
} from "../../../../models/CompRecommendation";
import { DV_RED, GRAY_1, GRAY_7, GRAY_8 } from "../../../../theme";
import { BudgetNameCell } from "../../Budgets/Table/BudgetNameCell";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { LazyReportingSalaryTier } from "./LazyReportingSalaryTier";

const useStyles = makeStyles(() => ({
  reportCell: { backgroundColor: GRAY_8 },
  reportCellText: {
    color: GRAY_1,
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.55",
    letterSpacing: "-0.25px",
  },
  alertText: { color: DV_RED },
  directAndIndirectReportsCell: { backgroundColor: GRAY_7 },
}));

type Props = {
  employee: Employee;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  expandable: boolean;
  indentation: IndentationCells;
  compCycleData: CompCycleData;
  subComponentCount: number;
  isLastRow: boolean;
};

type BudgetKey = "salaryMerit" | "salaryMarket" | "salaryPromotion" | "salary";

// helper function used to call `calculateRequestsFromReports` for salary type
// requests. This is needed because the `salaryTotal` budget / requests are
// composed of salaryMerit + salaryMarket + salaryPromotion (when applicable)
// so we need to sum up those requests to get the total salary requests.
export function calculateRequestsFromReportsForSalary(
  reports: Report[],
  requestTypes: RecItemType[]
): Money | null {
  const requests = mapMaybe(
    [
      ...requestTypes.map((type) =>
        calculateRequestsFromReports(reports, type)
      ),
    ],
    (request) => request
  );

  if (requests.length === 0) return null;

  // For Budgets vs Requests, we request the cashEquivalent all to
  // be in the org's default currency. So, these are safe to add together.
  // Also, the list is non-empty, so we can safely omit a starting value.
  return requests.reduce(add);
}

export function SalaryRow({
  employee,
  organizationName,
  varianceDisplay,
  expandable,
  indentation,
  compCycleData,
  subComponentCount,
  isLastRow,
}: Props): JSX.Element {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const compComponents = getSalaryCompComponents(compCycleData);

  const reportingIndentation: IndentationCells =
    indentation.length > 0 && isLastRow
      ? [...indentation.slice(0, -1), "hidden", "visible"]
      : indentation.concat("visible");

  const reports = [...employee.directReports, ...employee.indirectReports];

  return (
    <>
      <TableRow>
        <BudgetNameCell
          compCycleId={compCycleData.id}
          employee={employee}
          indentation={indentation}
          expanded={isExpanded}
          expandable={expandable}
          setExpanded={setIsExpanded}
          type="compare"
          isLastRowCell={isLastRow}
        />
        <TableCell
          className={clsx(
            classes.reportCell,
            classes.directAndIndirectReportsCell
          )}
          align="right"
        >
          {employee.directReports.length}
        </TableCell>
        <TableCell
          className={clsx(
            classes.reportCell,
            classes.directAndIndirectReportsCell
          )}
          align="right"
        >
          {employee.indirectReports.length}
        </TableCell>

        {compComponents
          .map(({ budgetKey, requestType }) => ({
            budgetKey,
            budget: employee.compCycleBudget?.[budgetKey] ?? null,
            requests: calculateRequestsFromReportsForSalary(
              reports,
              requestType
            ),
          }))
          .map(({ budgetKey, budget, requests }) => {
            const variance =
              budget && requests ? subtract(requests, budget) : null;

            return (
              <Fragment key={budgetKey}>
                {/* budget */}
                <TableCell align="right" className={classes.reportCell}>
                  <Typography className={classes.reportCellText}>
                    {formatBudget(budget)}
                  </Typography>
                </TableCell>

                {/* request */}
                <TableCell align="right" className={classes.reportCell}>
                  <Typography className={classes.reportCellText}>
                    {formatRequest(requests)}
                  </Typography>
                </TableCell>

                {/* variance */}
                <TableCell align="right" className={classes.reportCell}>
                  <Typography
                    className={clsx(classes.reportCellText, {
                      [classes.alertText]: variance && variance.value > 0,
                    })}
                  >
                    {formatVariance(varianceDisplay, budget, variance)}
                  </Typography>
                </TableCell>
              </Fragment>
            );
          })}
      </TableRow>

      {isExpanded && (
        <LazyReportingSalaryTier
          employee={employee}
          organizationName={organizationName}
          varianceDisplay={varianceDisplay}
          indentation={reportingIndentation}
          subComponentCount={subComponentCount}
          compCycleData={compCycleData}
        />
      )}
    </>
  );
}

export function getSalaryCompComponents(
  compCycleData: CompCycleData
): { budgetKey: BudgetKey; requestType: RecItemType[] }[] {
  const compComponents = [];

  if (compCycleData.allowSalaryMarket) {
    compComponents.push({
      budgetKey: "salaryMarket",
      requestType: [RecItemType.MARKET],
    });
  }

  if (compCycleData.allowSalaryPromotion) {
    compComponents.push({
      budgetKey: "salaryPromotion",
      requestType: [RecItemType.PROMOTION],
    });
  }

  if (compCycleData.allowSalaryMerit) {
    compComponents.push({
      budgetKey: "salaryMerit",
      requestType: [RecItemType.MERIT_INCREASE],
    });
  }

  if (compCycleData.allowSalary) {
    const types = [];

    if (compCycleData.allowSalaryMarket) {
      types.push(RecItemType.MARKET);
    }

    if (compCycleData.allowSalaryPromotion) {
      types.push(RecItemType.PROMOTION);
    }

    if (compCycleData.allowSalaryMerit) {
      types.push(RecItemType.MERIT_INCREASE);
    }

    compComponents.push({
      budgetKey: "salary",
      requestType: types,
    });
  }

  return compComponents as {
    budgetKey: BudgetKey;
    requestType: RecItemType[];
  }[];
}

const report = gql`
  fragment SalaryRow_report on Employee {
    id
    compRecommendation(compCycleId: $compCycleId) {
      subjectId
      compCycleId
      latestSubmittedItems {
        id
        recommendationType
        cashEquivalent(currencyCode: $currencyCode)
      }
    }
  }
`;
SalaryRow.fragments = {
  employee: gql`
    ${report}
    fragment SalaryRow_employee on Employee {
      id
      displayName
      user {
        id
        photoURL
      }
      activeEmployment {
        id
        jobTitle
      }
      compCycleBudget(compCycleId: $compCycleId) {
        compCycleId
        employeeId
        salary
        salaryMarket
        salaryMerit
        salaryPromotion
      }

      directReports {
        id
        ...SalaryRow_report
      }

      indirectReports {
        id
        ...SalaryRow_report
      }
    }
  `,
  compCycleData: gql`
    fragment SalaryRow_compCycleData on CompCycle {
      id
      allowSalary
      allowSalaryMarket
      allowSalaryMerit
      allowSalaryPromotion
    }
  `,
};
