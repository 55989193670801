import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Money } from "@asmbl/shared/money";
import { formatNumeral, roundNumber } from "@asmbl/shared/utils";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { ExplanatoryTooltip } from "src/components/ExplanatoryTooltip";
import {
  CompComponent,
  CompUnit,
  RecItemType,
  SalaryIncreaseRow_employee,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { CompSubComponent } from "../../../../models/Budget";
import { hasUnpublishedChanges } from "../../../../models/CompRecommendation";
import { SalaryInputCell } from "../../Cells/SalaryInputCell";
import { CompCycleLineItemRowButton } from "../CompCycleLineItemRowButton";
import { useConnectorStyles, useRecItemRowStyles } from "./styles";
import { CompCycleLineItemRowProps } from "./types";

interface Props extends CompCycleLineItemRowProps {
  employee: SalaryIncreaseRow_employee;
  label: string;
  basePay: { annualCashEquivalent: Money; hourlyCashEquivalent: Money } | null;
  payCurrencyCode: CurrencyCode | null;
  recommendationType: RecItemType;
  subComponent: CompSubComponent;
  isHourly: boolean;
  guidance?: number | { message: string };
  shouldApplyGuidance?: boolean;
}

export function SalaryIncreaseRow({
  employee,
  label,
  basePay,
  payCurrencyCode,
  revisedRecommendation,
  submittedRecommendation,
  recommendationType,
  onChangeRecommendationItem,
  subComponent,
  isHourly,
  guidance,
  shouldApplyGuidance = false,
}: Props): JSX.Element {
  const { Track } = useTrack({
    compensationComponent: CompComponent.SALARY,
    compensationSubComponent: subComponent,
    employeeId: employee.id,
  });
  const classes = useRecItemRowStyles();
  const connectorStyle = useConnectorStyles();

  const [submitted, revised] = [
    submittedRecommendation,
    revisedRecommendation,
  ].map((x) => {
    return x?.items.get(recommendationType);
  });

  const payIncrease =
    revised ??
    submitted ??
    (shouldApplyGuidance && guidance != null
      ? {
          recommendationType: RecItemType.MERIT_INCREASE,
          recommendedPercentValue: roundNumber(Number(guidance) * 100),
        }
      : undefined);

  const applyGuidance = () => {
    if (typeof guidance !== "number" || basePay == null) return;
    onChangeRecommendationItem({
      note: "Applied from guidance suggestion",
      recommendationType: RecItemType.MERIT_INCREASE,
      // guidance is a percentage, so to avoid incorrect floats being stored,
      // we multiply by 10_000, round, and then divide by 100
      recommendedPercentValue: Math.round(guidance * 10_000) / 100,
      unitType: CompUnit.PERCENT_OF_SALARY,
    });
  };

  const isGuidanceConfigured =
    typeof guidance === "number" ||
    guidance?.message !== "Guidance not configured";

  const hasGuidance = typeof guidance === "number";

  const currentIncreaseIsGuidance =
    payIncrease?.note === "Applied from guidance suggestion";

  return (
    <Track>
      <TableRow aria-level={3} className={classes.root}>
        <TableCell role="gridcell" padding="none">
          <div className={connectorStyle.line} />
        </TableCell>
        <TableCell
          role="gridcell"
          colSpan={5}
          padding="none"
          className={classes.textCell}
        >
          <Typography
            className={clsx(classes.rowLabel, {
              [classes.inline]: true,
            })}
          >
            {label}
          </Typography>

          {isGuidanceConfigured && guidance != null ? (
            <Typography
              className={clsx(
                classes.rowLabel,
                classes.guidanceLabel,
                classes.inline
              )}
            >
              Guidance:{" "}
              {hasGuidance
                ? formatNumeral(guidance, {
                    style: "percent",
                    maximumFractionDigits: 4,
                  })
                : "N/A"}
              <ExplanatoryTooltip
                body={typeof guidance !== "number" ? guidance.message : ""}
                disabled={hasGuidance}
              >
                <span>
                  <AssembleButton
                    className={clsx(classes.applyElement, {
                      [classes.noGuidance]: !hasGuidance,
                    })}
                    size="small"
                    variant="text"
                    label={hasGuidance ? "Apply" : "Why not?"}
                    onClick={applyGuidance}
                    disabled={!hasGuidance}
                  />
                </span>
              </ExplanatoryTooltip>
            </Typography>
          ) : null}
        </TableCell>
        <SalaryInputCell
          value={payIncrease}
          isHourly={isHourly}
          basePay={basePay}
          hasUnpublishedChanges={hasUnpublishedChanges(submitted, revised)}
          onChange={(newSalary, unitType) => {
            onChangeRecommendationItem({
              recommendationType,
              recommendedCashValue: newSalary,
              unitType,
              note: currentIncreaseIsGuidance ? undefined : payIncrease?.note,
            });
          }}
          payCurrencyCode={payCurrencyCode}
        />
        <TableCell role="gridcell" className={classes.noteTextCell} colSpan={4}>
          <CompCycleLineItemRowButton
            disabled={
              payIncrease?.recommendedCashValue === undefined &&
              payIncrease?.recommendedPercentValue === undefined
            }
            employee={employee}
            note={payIncrease?.note ?? null}
            onClick={(note: string) =>
              onChangeRecommendationItem({
                ...payIncrease,
                recommendationType,
                note,
              })
            }
          />
        </TableCell>
        <TableCell role="gridcell" />
      </TableRow>
    </Track>
  );
}

SalaryIncreaseRow.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    fragment SalaryIncreaseRow_employee on Employee2 {
      ...CompCycleLineItemRowButton_employee
      id
      activeEmployment {
        id
        salary
        payPeriod
        payRate
        payCurrencyCode
      }
      activeCashCompensation(currencyCode: $currencyCode) {
        type
        annualCashEquivalent
        hourlyCashEquivalent
        unit
      }
    }
  `,
};
