import { gql, useQuery } from "@apollo/client";
import { GetCompCycleBudgetDraftAndSettings } from "../../../__generated__/graphql";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { AllocateBudgets } from "./AllocateBudgets";
import { AllocateBudgetsProvider } from "./Context/AllocateBudgetsContext";
import { OveragesSelector } from "./Header/OveragesSelector";

type Props = {
  compCycleId: number;
  employeeId: number | null;
};

export function BudgetLoadingBoundary({
  compCycleId,
  employeeId,
}: Props): JSX.Element {
  const { data, loading } = useQuery<GetCompCycleBudgetDraftAndSettings>(
    BudgetLoadingBoundary.query,
    {
      variables: { compCycleId: compCycleId, employeeId },
      pollInterval: 1_000,
    }
  );

  if (!data || !data.compCycle || !data.valuation) {
    return loading ? <LoadingSpinner /> : <div>An error has occurred.</div>;
  }

  return (
    <AllocateBudgetsProvider
      cycleSettings={data.compCycle}
      valuation={data.valuation}
    >
      <AllocateBudgets
        rootBudgetDraft={data.compCycleBudgetDraft}
        rootBudget={data.compCycleBudget}
        overages={data.compCycle.budgetOverages}
      />
    </AllocateBudgetsProvider>
  );
}

// We explicitly add the key fields (compCycleId, employeeId) to the query,
// even though we don't use them here. They are important for Apollo caching.
BudgetLoadingBoundary.query = gql`
  ${AllocateBudgets.fragments.compCycleBudgetDraft}
  ${AllocateBudgets.fragments.compCycleBudget}
  ${AllocateBudgets.fragments.compCycleSettings}
  ${AllocateBudgets.fragments.valuation}
  ${OveragesSelector.fragments.budgetOverage}

  query GetCompCycleBudgetDraftAndSettings(
    $compCycleId: Int!
    $employeeId: Int
  ) {
    compCycleBudgetDraft(compCycleId: $compCycleId, employeeId: $employeeId) {
      compCycleId
      employeeId
      ...AllocateBudgets_compCycleBudgetDraft
    }
    compCycleBudget(compCycleId: $compCycleId, employeeId: $employeeId) {
      compCycleId
      employeeId
      ...AllocateBudgets_compCycleBudget
    }
    compCycle(id: $compCycleId) {
      id
      budgetOverages {
        ...OveragesSelector_budgetOverage
      }
      ...AllocateBudgets_compCycleSettings
    }
    valuation {
      ...AllocateBudgets_valuation
    }
  }
`;

BudgetLoadingBoundary.overages = gql`
  ${OveragesSelector.fragments.budgetOverage}
  query GetBudgetOverages($compCycleId: Int!) {
    compCycle(id: $compCycleId) {
      id
      budgetOverages {
        ...OveragesSelector_budgetOverage
      }
    }
  }
`;
