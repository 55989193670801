import { gql, useQuery } from "@apollo/client";
import { mapMaybe } from "@asmbl/shared/utils";
import {
  FetchCompCycleProgress,
  FetchCompCycleProgressVariables,
} from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { ProgressPageContextProvider } from "./Context/ProgressPageContext";
import { ProgressTable } from "./ProgressTable";
import { ProgressTableHeader } from "./ProgressTableHeader";

type Props = {
  compCycleId: number;
};

export function ProgressPageLoadingBoundary({ compCycleId }: Props) {
  const { loading, data, error } = useQuery<
    FetchCompCycleProgress,
    FetchCompCycleProgressVariables
  >(ProgressPageLoadingBoundary.queries.fetchCompCycleProgress, {
    variables: { compCycleId },
  });

  if (loading || !data) {
    return !error ? <LoadingSpinner /> : <ErrorView text={error.message} />;
  }

  const phases = data.compCycle2?.phases ?? [];

  return (
    <ProgressPageContextProvider
      participants={data.compCycle2?.participants.participants ?? []}
      reviewers={
        data.compCycle2?.phases.flatMap((phase) =>
          mapMaybe(phase.compCyclePhaseAssignments, (assignment) => assignment)
        ) ?? []
      }
      phases={phases}
      currentPhase={data.compCycle2?.currentPhase ?? null}
      compCycleId={compCycleId}
    >
      <ProgressTableHeader />
      <ProgressTable />
    </ProgressPageContextProvider>
  );
}

const participantFragment = gql`
  fragment ProgressPageLoadingBoundary_participant on CompCycleParticipant {
    compCycleId
    subjectId
    phaseTimeline {
      id
      phaseId
      assigneeId
      status
    }
    compRecommendation {
      compCycleId
      subjectId
      latestSubmittedItems {
        id
        author {
          id
          name
        }
      }
      latestSubmittedReviews {
        id
        author {
          id
          name
        }
      }
    }
  }
`;

ProgressPageLoadingBoundary.queries = {
  fetchCompCycleProgress: gql`
    ${participantFragment}
    ${ProgressTable.fragments.phases}
    ${ProgressTableHeader.fragments.phases}
    query FetchCompCycleProgress($compCycleId: Int!) {
      compCycle2(id: $compCycleId) {
        id
        currentPhase {
          ...ProgressTableHeader_phase
        }
        phases {
          ...ProgressTable_phase
        }
        participants {
          participants {
            ...ProgressPageLoadingBoundary_participant
          }
        }
      }
    }
  `,
};
