import { gql } from "@apollo/client";
import { makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BudgetsVsRequests_compCycleBudget as CompCycleBudget,
  BudgetsVsRequests_compCycleData as CompCycleData,
  BudgetsVsRequests_manager as Manager,
  BudgetsVsRequests_reports as Report,
  BudgetsVsRequests_valuation as Valuation,
} from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { useAuth } from "../../../components/Auth/AuthContext";
import {
  CompleteReviewDialog,
  useCompleteReviewDialogState,
} from "../../../components/CompCycle/Dialogs/CompleteReviewDialog";
import {
  CompCycleDisplay,
  CompCycleGrouping,
} from "../../../components/CompCycle/types";
import { ActionBar } from "../../../components/Layout/ActionBar";
import { defaultComponent } from "../../../models/CompCycle";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { GRAY_1 } from "../../../theme";
import { Actual } from "./Table/Actual";
import { Equity } from "./Table/Equity";
import { Salary } from "./Table/Salary";
import { TableHeader } from "./Table/TableHeader";
import { Target } from "./Table/Target";

type Props = {
  manager: Manager | null;
  reports: Report[];
  compCycleBudget: CompCycleBudget | null;
  compCycleData: CompCycleData;
  currentValuation: Valuation;
};

export type Equity = "cash" | "units";

const useStyles = makeStyles(() => ({
  pageHeaderTitle: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: "-0.5px",
    color: GRAY_1,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 5rem)", // Don't scroll, let the table do it
  },
}));

export function BudgetsVsRequests({
  manager,
  reports,
  compCycleBudget,
  compCycleData,
  currentValuation,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const { organization } = useAuth();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();

  const organizationName = organization?.name ?? "";
  const [subPage, setSubPage] = useState(defaultComponent(compCycleData));

  const varianceDisplay = (urlSearchParams.get("display") ??
    "absolute") as CompCycleDisplay;

  const [equityDisplay, setEquityDisplay] = useState<Equity>("cash");
  const [dialogState, setDialogState] = useCompleteReviewDialogState(
    compCycleData.endedAt
  );

  const handleVarianceDisplayChange = (
    updatedVarianceDisplay: CompCycleDisplay
  ) => {
    trackEvent({
      object: "Absolute vs Percent Selector",
      action: "Toggled",
      selectedOption: updatedVarianceDisplay,
    });
    navigate(
      {
        search: urlSearchParams
          .set("display", updatedVarianceDisplay)
          .toString(),
      },
      {
        replace: true,
      }
    );
  };

  const handleSubPageChange = (value: CompCycleGrouping) => {
    setSubPage(value);
    trackEvent({
      object: "Comp Component Selector",
      action: "Toggled",
      compensationComponent: value,
    });
  };

  const classes = useStyles();
  const managerName = manager?.displayName ?? null;

  return (
    <>
      {dialogState !== "open" && (
        <CompleteReviewDialog
          compCycle={compCycleData}
          dialogState={dialogState}
          setDialogState={setDialogState}
        />
      )}
      <div className={classes.root}>
        <ActionBar
          slotLeft={
            <Typography className={classes.pageHeaderTitle}>
              {`Reviewing budgets and requests for ${
                managerName !== null
                  ? `${managerName} and reports`
                  : `${organizationName}`
              }`}
            </Typography>
          }
          slotRight={<></>}
        />
        <TableHeader
          subPage={subPage}
          onSubPageChange={handleSubPageChange}
          compCycleData={compCycleData}
          varianceDisplay={varianceDisplay}
          setVarianceDisplay={handleVarianceDisplayChange}
          equityDisplay={equityDisplay}
          setEquityDisplay={setEquityDisplay}
          compCycleId={compCycleData.id}
          manager={manager}
        />
        {subPage === "salary" && (
          <Salary
            rootBudget={compCycleBudget}
            manager={manager}
            reports={reports}
            compCycleData={compCycleData}
            organizationName={organizationName}
            varianceDisplay={varianceDisplay}
          />
        )}
        {subPage === "equity" && (
          <Equity
            rootBudget={compCycleBudget}
            manager={manager}
            reports={reports}
            organizationName={organizationName}
            varianceDisplay={varianceDisplay}
            currentValuation={currentValuation}
            equityDisplay={equityDisplay}
            compCycleData={compCycleData}
          />
        )}
        {subPage === "target" && (
          <Target
            rootBudget={compCycleBudget}
            manager={manager}
            reports={reports}
            organizationName={organizationName}
            varianceDisplay={varianceDisplay}
            compCycleData={compCycleData}
          />
        )}
        {subPage === "actual" && (
          <Actual
            rootBudget={compCycleBudget}
            manager={manager}
            reports={reports}
            organizationName={organizationName}
            varianceDisplay={varianceDisplay}
            compCycleData={compCycleData}
          />
        )}
      </div>
    </>
  );
}

BudgetsVsRequests.fragments = {
  manager: gql`
    ${Actual.fragments.manager}
    ${Equity.fragments.manager}
    ${Salary.fragments.manager}
    ${Target.fragments.manager}
    fragment BudgetsVsRequests_manager on Employee {
      id
      ...Actual_manager
      ...Equity_manager
      ...Salary_manager
      ...Target_manager
    }
  `,
  reports: gql`
    ${Actual.fragments.reports}
    ${Equity.fragments.reports}
    ${Salary.fragments.reports}
    ${Target.fragments.reports}
    fragment BudgetsVsRequests_reports on Employee {
      id
      ...Actual_reports
      ...Equity_reports
      ...Salary_reports
      ...Target_reports
    }
  `,
  compCycleBudget: gql`
    ${Salary.fragments.compCycleBudget}
    ${Equity.fragments.compCycleBudget}
    ${Actual.fragments.compCycleBudget}
    ${Target.fragments.compCycleBudget}

    fragment BudgetsVsRequests_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId

      ...Salary_compCycleBudget
      ...Equity_compCycleBudget
      ...Actual_compCycleBudget
      ...Target_compCycleBudget
    }
  `,
  compCycleData: gql`
    ${Salary.fragments.compCycleData}
    ${Actual.fragments.compCycleData}
    ${Equity.fragments.compCycleData}
    ${Target.fragments.compCycleData}
    ${TableHeader.fragments.compCycleData}
    ${CompleteReviewDialog.fragments.compCycleSettings}
    fragment BudgetsVsRequests_compCycleData on CompCycle {
      id
      allowSalary
      allowBonus
      allowEquity

      ...CompleteReviewDialog_compCycleSettings
      ...Salary_compCycleData
      ...Actual_compCycleData
      ...Equity_compCycleData
      ...Target_compCycleData
      ...TableHeader_compCycleData
    }
  `,
  valuation: gql`
    ${Equity.fragments.valuation}
    fragment BudgetsVsRequests_valuation on Valuation {
      id
      ...Equity_valuation
    }
  `,
};
