import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { GetGuidance, GetGuidanceVariables } from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import { ErrorView } from "src/components/ErrorView";
import {
  useApplyGuidanceToAll,
  useBuildEmptyMatrix,
} from "src/mutations/Matrix";
import { MeritGuidance } from "./MeritGuidance";

type Props = {
  compCycleId: number;
};

export function MeritGuidanceLoadingBoundary({ compCycleId }: Props) {
  const { trackEvent } = useTrack();
  useEffect(() => {
    trackEvent({
      object: "Merit Guidance",
      action: "Viewed",
      compCycleId,
    });
  }, [trackEvent, compCycleId]);

  const buildEmptyMatrix = useBuildEmptyMatrix(compCycleId);
  const applyGuidanceToAll = useApplyGuidanceToAll(compCycleId);

  const { loading, data, refetch } = useQuery<
    GetGuidance,
    GetGuidanceVariables
  >(MeritGuidanceLoadingBoundary.query, {
    variables: {
      compCycleId,
    },
    onCompleted: (data) => {
      if (
        data.compCycle?.matrices.length == null ||
        data.compCycle.matrices.length === 0
      ) {
        buildEmptyMatrix()
          .then(() => refetch())
          .catch(console.error);
      }
    },
    onError: (error) => {
      if (error.message === "No Matrix found") {
        buildEmptyMatrix()
          .then(() => refetch())
          .catch(console.error);
      }
    },
  });

  if (loading) return <CircularProgress />;
  if (data == null || data.compCycle == null)
    return (
      <ErrorView text="Something went wrong. Please refresh the page to try again" />
    );

  return (
    <MeritGuidance
      compCycle={data.compCycle}
      refreshMetrics={refetch}
      applyGuidanceToAll={applyGuidanceToAll}
    />
  );
}

const MATRIX_RANGE = gql`
  fragment GuidanceMatrixRange_matrixRange on MatrixRange {
    id
    rangeStart
  }
`;

const MATRIX_PERF_RATING_OPTION = gql`
  fragment GuidanceMatrixPerfRatingOption_matrixPerfRatingOption on MatrixPerfRatingOption {
    id
    name
    rank
  }
`;

const MATRIX_GUIDE = gql`
  ${MATRIX_RANGE}
  ${MATRIX_PERF_RATING_OPTION}
  fragment GuidanceMatrixGuide_matrixGuide on MatrixGuide {
    id
    percent
    matrixRange {
      id
      ...GuidanceMatrixRange_matrixRange
    }
    matrixPerfRatingOption {
      id
      ...GuidanceMatrixPerfRatingOption_matrixPerfRatingOption
    }
    matrixId
  }
`;

const MATRIX = gql`
  ${MATRIX_RANGE}
  ${MATRIX_PERF_RATING_OPTION}
  ${MATRIX_GUIDE}
  fragment GuidanceMatrix_matrix on Matrix {
    id
    type
    siblingMatrixId
    matrixGuides {
      id
      ...GuidanceMatrixGuide_matrixGuide
    }
  }
`;

const COMP_CYCLE = gql`
  ${MATRIX}
  fragment Guidance_compCycle on CompCycle {
    id
    estimatedSalaryBudget
    estimatedSalaryMerit
    recommendationsPreFill
    totalSpend
    matrices {
      id
      ...GuidanceMatrix_matrix
    }
  }
`;

MeritGuidanceLoadingBoundary.query = gql`
  ${COMP_CYCLE}
  query GetGuidance($compCycleId: Int!) {
    compCycle(id: $compCycleId) {
      ...Guidance_compCycle
    }
  }
`;
