import { IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { GRAY_4, PURPLE_2 } from "src/theme";
import { EditIcon } from "./AssembleIcons/Small/EditIcon";
import {
  AssembleTypography,
  AssembleTypographyProps,
} from "./AssembleTypography";

type Props = AssembleTypographyProps & {
  onClick: () => void;
  icon: {
    width?: string;
    height?: string;
    positionRelative: { top: string; right: string };
  };
};

const useStyles = makeStyles((theme) => ({
  root: { position: "relative" },
  exposeButtonWrapper: {
    opacity: "1 !important",
  },
  editButtonWrapper: {
    position: "absolute",
    padding: theme.spacing(2),
    opacity: 0,
    transition: "opacity 300ms",
    top: (props: Props["icon"]["positionRelative"]) => props.top,
    right: (props: Props["icon"]["positionRelative"]) => props.right,
  },
}));

export function AssembleEditableText(props: Props): JSX.Element {
  const classes = useStyles({
    top: props.icon.positionRelative.top,
    right: props.icon.positionRelative.right,
  });

  const [isWithinContainer, setIsWithinContainer] = useState(false);
  const [isWithinButtonElement, setIsWithinButtonElement] = useState(false);

  const showEditButton = isWithinContainer || isWithinButtonElement;

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setIsWithinContainer(true)}
      onMouseLeave={() => setIsWithinContainer(false)}
    >
      <AssembleTypography {...props} />
      <div
        className={clsx(classes.editButtonWrapper, {
          [classes.exposeButtonWrapper]: showEditButton,
        })}
        onMouseEnter={() => setIsWithinButtonElement(true)}
        onMouseLeave={() => setIsWithinButtonElement(false)}
      >
        <IconButton onClick={props.onClick}>
          <EditIcon
            color={GRAY_4}
            hoverColor={PURPLE_2}
            width={props.icon.width}
            height={props.icon.height}
          />
        </IconButton>
      </div>
    </div>
  );
}
