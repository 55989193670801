import { gql } from "@apollo/client";
import { makeStyles, Tooltip } from "@material-ui/core";
import { CheckCircleSolidIcon } from "src/components/AssembleIcons/Brand/CheckCircleSolidIcon";
import { useAuth } from "src/components/Auth/AuthContext";
import { GRAY_6, GREEN_2 } from "src/theme";
import {
  CompRecommendationActivityType,
  CondensedTableLastActivityCell_participant as Participant,
} from "../../../../__generated__/graphql";
import { UserAvatar } from "../../../../components/UserAvatar";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { SMALL_COL_WIDTH } from "./dimensions";

const useStyles = makeStyles(() => ({
  managerCell: {
    display: "flex",
    justifyContent: "center",
  },
  successIcon: {
    border: `1px solid ${GRAY_6}`,
    borderRadius: "50%",
  },
}));

type Props = {
  row: { original: Participant };
};

export function CondensedTableLastActivityCell({
  row: { original: participant },
}: Props): JSX.Element {
  const classes = useStyles();
  const { userId } = useAuth();
  const currUserIsAuthor = participant.lastActivity?.author.id === userId;
  return (
    <div className={classes.managerCell}>
      {participant.lastActivity === null ? (
        <UserAvatar
          displayName="-"
          photoURL={null}
          grayscale
          tooltipTitle="No recommendations"
          showTooltip
          size="mini"
        />
      ) : currUserIsAuthor ? (
        <Tooltip
          title={`Last ${participant.lastActivity.type === CompRecommendationActivityType.SUBMISSION ? "recommendation from" : "reviewed by"} you`}
          placement="top"
        >
          <span>
            <CheckCircleSolidIcon
              color={GREEN_2}
              width="24px"
              height="24px"
              className={classes.successIcon}
            />
          </span>
        </Tooltip>
      ) : (
        <UserAvatar
          displayName={participant.lastActivity.author.name}
          photoURL={participant.lastActivity.author.photoURL}
          grayscale={currUserIsAuthor}
          tooltipTitle={`Last ${participant.lastActivity.type === CompRecommendationActivityType.SUBMISSION ? "recommendation from" : "reviewed by"} ${participant.lastActivity.author.name}`}
          showTooltip
          size="mini"
        />
      )}
    </div>
  );
}

CondensedTableLastActivityCell.fragments = {
  participant: gql`
    fragment CondensedTableLastActivityCell_participant on CompCycleParticipant {
      compCycleId
      subjectId
      lastActivity {
        submittedAt
        type
        author {
          id
          name
          photoURL
        }
      }
    }
  `,
};

CondensedTableLastActivityCell.Header = ColumnIdsToHeaders.get(
  ColumnIds.LAST_ACTIVITY
);
CondensedTableLastActivityCell.id = ColumnIds.LAST_ACTIVITY;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableLastActivityCell,
  Header: CondensedTableLastActivityCell.Header,
  id: CondensedTableLastActivityCell.id,
  width: SMALL_COL_WIDTH,
};
CondensedTableLastActivityCell.column = column;
