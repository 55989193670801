import { gql } from "@apollo/client";
import { isEmailRule } from "@asmbl/shared/eligibility";
import { Card, LinearProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import {
  GuidanceDetailedView_compCycle as CompCycle,
  GuidanceDetailedView_organization as Organization,
} from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleEditableText } from "src/components/AssembleEditableText";
import { PeopleIcon } from "src/components/AssembleIcons/Small/PeopleIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { EligibilityEditor } from "src/pages/CompCycle/Eligibility/EligibilityEditor";
import { EmailRuleEditor } from "src/pages/CompCycle/Eligibility/EmailRuleEditor";
import { RuleEditor } from "src/pages/CompCycle/Eligibility/RuleEditor";
import { GRAY_1, GRAY_3, GRAY_4, GRAY_6, PURPLE_2 } from "src/theme";
import { EditGuidancePopulationModal } from "../Modals/EditGuidancePopulationModal";

import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { ChevronRightIcon } from "src/components/AssembleIcons/Brand/ChevronRightIcon";
import { InfoBanner } from "src/components/InfoBanner";
import { EligiblePeopleModal } from "../Modals/EligiblePeopleModal";
import { useMultipleMeritMatrices } from "../useMultipleMatrices";
import { ConflictBanner } from "./ConflictBanner";
import { MatrixCard } from "./MatrixCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    "&:hover": {
      border: "1px solid transparent",
      boxShadow: "none",
    },
  },
  card: {
    padding: theme.spacing(2),

    boxShadow: "none",
    border: `1px solid ${GRAY_6}`,

    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardHeaderGroupInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(0.5),
  },
  cardHeaderGroupAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1.5),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  rules: {
    border: `1px solid ${GRAY_6}`,
    borderRadius: "8px",
    padding: theme.spacing(1.5),
  },
  rulesHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rule: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  orText: { marginLeft: "1rem" },
  warningBanner: {
    padding: theme.spacing(1, 2),
  },
  warningBannerInner: {
    margin: -theme.spacing(0.75),
  },
  spacer: {
    height: theme.spacing(0.5),
  },
  pointer: {
    cursor: "pointer",
  },
}));

type Props = {
  organization: Organization;
  compCycle: CompCycle;
};

export function GuidanceDetailedView({
  organization,
  compCycle,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showGuidancePopulationModal, setShowGuidancePopulationModal] =
    useState(false);
  const {
    meta: { loading },
    allEligible,
    matrices: { selectedMatrix },
    rules: { addRule, addEmailRule, updateRule, removeRule },
  } = useMultipleMeritMatrices();

  if (selectedMatrix == null) return null;

  const rules = selectedMatrix.eligibilityRules ?? [];

  const departments = organization.departments.map((d) => ({
    label: d.name,
    value: d.id,
  }));

  const ladders = organization.departments
    .flatMap((d) => d.ladders)
    .map((l) => ({ label: l.name, value: l.id }));

  const levels = (organization.compStructure?.levels ?? []).map((l) => ({
    label: l.toString(),
    value: l,
  }));

  const perfRatings = compCycle.perfRatings.map((pr) => ({
    label: pr,
    value: pr,
  }));

  const locationGroups = organization.locationGroups.map((lg) => ({
    label: lg.name,
    value: lg.id,
  }));

  const markets = organization.locationGroups.flatMap((lg) => ({
    value: lg.market.id,
    label: lg.market.name,
  }));

  const showWarningBanner =
    selectedMatrix.eligibilityRules == null ||
    selectedMatrix.eligibilityRules.length === 0 ||
    selectedMatrix.eligibilityRules.every((rule) => rule.length === 0);

  const addRuleHandler = () => {
    setIsOpen(true);
    addRule();
  };

  const addEmailRuleHandler = () => {
    setIsOpen(true);
    addEmailRule();
  };

  return (
    <div className={classes.root}>
      <EligiblePeopleModal
        type="guidance-population"
        isOpen={showGuidancePopulationModal}
        compCycle={compCycle}
        handleClose={() => setShowGuidancePopulationModal(false)}
        employeeIds={selectedMatrix.eligibleParticipants ?? []}
      />

      {showEditModal && (
        <EditGuidancePopulationModal
          handleClose={() => setShowEditModal(false)}
        />
      )}
      <div className={classes.header}>
        <AssembleEditableText
          variant="h4"
          textColor={GRAY_1}
          onClick={() => setShowEditModal(true)}
          icon={{
            positionRelative: {
              top: "-20px",
              right: "-50px",
            },
          }}
        >
          {selectedMatrix.name}
        </AssembleEditableText>

        <AssembleButton
          size="small"
          variant="text"
          startIcon={<PeopleIcon color={PURPLE_2} />}
          label={`Eligible People (${selectedMatrix.eligibleParticipants?.length ?? allEligible.length})`}
          onClick={() => setShowGuidancePopulationModal(true)}
        />
      </div>
      <Card
        className={clsx(classes.card, { [classes.pointer]: !isOpen })}
        onClick={() => {
          if (!isOpen) {
            setIsOpen(true);
          }
        }}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardHeaderGroupInfo}>
            <AssembleButton
              className={classes.button}
              size="small"
              variant="text"
              startIcon={
                isOpen ? (
                  <ChevronDownIcon color={GRAY_4} />
                ) : (
                  <ChevronRightIcon color={GRAY_4} />
                )
              }
              onClick={() => setIsOpen(!isOpen)}
            />
            <AssembleTypography variant="productSectionHeader">
              Eligibility
            </AssembleTypography>
          </div>

          {showWarningBanner && (
            <InfoBanner
              className={classes.warningBanner}
              level="warning"
              align="center"
              noMargin
            >
              <div className={classes.warningBannerInner}>
                <AssembleTypography variant="productParagraphMedium">
                  Having no rules applied to a guidance population will make
                  everyone eligible
                </AssembleTypography>
              </div>
            </InfoBanner>
          )}

          <div className={classes.cardHeaderGroupAction}>
            <AssembleButton
              size="small"
              variant="outlined"
              label="Filter by Email"
              onClick={addEmailRuleHandler}
            />
            <AssembleButton
              size="small"
              variant="contained"
              label="Add Rule"
              onClick={addRuleHandler}
            />
          </div>
        </div>

        {loading ? <LinearProgress /> : <div className={classes.spacer} />}

        {isOpen && (
          <>
            {rules.map((r, i) => {
              return (
                <div key={i} className={classes.rule}>
                  {isEmailRule(r) ? (
                    <EmailRuleEditor
                      rule={r}
                      onChange={updateRule(i)}
                      onDelete={() => removeRule(i)}
                      employees={compCycle.participants.participants.map(
                        (p) => ({ ...p.subject, __typename: "Employee" })
                      )}
                    />
                  ) : (
                    <RuleEditor
                      key={i}
                      title={`Rule ${i + 1}`}
                      rule={r}
                      onChange={updateRule(i)}
                      onDelete={() => removeRule(i)}
                      departmentOptions={departments}
                      ladderOptions={ladders}
                      levelOptions={levels}
                      perfRatingOptions={perfRatings}
                      locationGroupOptions={locationGroups}
                      marketOptions={markets}
                    />
                  )}
                  {i !== rules.length - 1 && (
                    <AssembleTypography
                      variant="productEyebrowSmall"
                      textColor={GRAY_3}
                      className={classes.orText}
                    >
                      OR
                    </AssembleTypography>
                  )}
                </div>
              );
            })}
            <ConflictBanner />
          </>
        )}
      </Card>

      <MatrixCard
        label="Merit Guidance"
        matrix={selectedMatrix.guidance}
        defaultOpen={true}
      />

      <MatrixCard
        label="Budget"
        matrix={selectedMatrix.budget}
        defaultOpen={false}
      />
    </div>
  );
}

GuidanceDetailedView.fragments = {
  organization: gql`
    ${EligibilityEditor.fragments.organization}
    fragment GuidanceDetailedView_organization on Organization {
      id
      ...EligibilityEditor_organization
    }
  `,
  compCycle: gql`
    ${EligibilityEditor.fragments.compCycle2}
    ${MatrixCard.fragments.matrix}
    ${EligiblePeopleModal.fragments.compCycle}
    fragment GuidanceDetailedView_compCycle on CompCycle2 {
      id
      matrices {
        id
        ...GuidanceDetailedView_matrix
      }
      participants {
        participants {
          compCycleId
          subjectId
          subject {
            id
            displayName
            email
          }
        }
      }
      ...EligibilityEditor_compCycle2
      ...EligiblePeopleModal_compCycle
    }
  `,
};
