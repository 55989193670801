import { Box, Link } from "@material-ui/core";
import React, { ReactNode } from "react";
import { useIntercom } from "react-use-intercom";
import NoEmployeeSvg from "../../assets/svgs/illustrations/employee-portal.svg?react";
import NoOrgAccessSvg from "../../assets/svgs/illustrations/empty-no-access-building.svg?react";
import NoCompCyclesSvg from "../../assets/svgs/illustrations/empty-state-comp-cycles.svg?react";
import NoDataSvg from "../../assets/svgs/illustrations/empty-state-no-data.svg?react";
import NoOffersCreatedSvg from "../../assets/svgs/illustrations/empty-state-no-offers.svg?react";
import NoOffersAccessSvg from "../../assets/svgs/illustrations/no-offer-letter-access.svg?react";
import NoOrgHierarchySvg from "../../assets/svgs/illustrations/no-org-hierarchy.svg?react";
import SequentialApprovalsSvg from "../../assets/svgs/illustrations/sequential-approvals.svg?react";
import { AssembleLink } from "../AssembleLink";
import {
  AssembleTypography,
  AssembleTypographyProps,
} from "../AssembleTypography";
import { EmptyTableView } from "../EmptyTableView";

const Illustrations = {
  "no-offers-created": <NoOffersCreatedSvg />,
  "no-offers-access": <NoOffersAccessSvg />,
  "no-org-access": <NoOrgAccessSvg />,
  "no-comp-cycles": <NoCompCyclesSvg />,
  "no-employee-found": <NoEmployeeSvg />,
  "no-data": <NoDataSvg />,
  "no-sequential-approvals": <SequentialApprovalsSvg />,
  "no-org-hierarchy": <NoOrgHierarchySvg />,
};

function EmptyStateTypography(props: AssembleTypographyProps) {
  return (
    <AssembleTypography
      color="textSecondary"
      variant="h4"
      align="center"
      {...props}
    />
  );
}

export function FullPageEmptyState({
  illustration,
  compact = false,
  children,
}: {
  illustration: keyof typeof Illustrations;
  compact?: boolean;
  children: ReactNode;
}): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      margin="auto"
      marginTop="20vh"
      marginBottom="20vh"
    >
      {Illustrations[illustration]}
      {!compact && <Box m={3} />}
      {children}
    </Box>
  );
}

export function ContactUs(): JSX.Element {
  const intercom = useIntercom();
  return (
    <Link href="#" onClick={intercom.show}>
      send us a message
    </Link>
  );
}

export function NoOfferSettingsConfigured(): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-offers-access">
      <EmptyStateTypography>
        It appears we haven't collected details about your organization's equity
        and compensation.
      </EmptyStateTypography>
      <EmptyStateTypography>
        Please
        <AssembleLink to="/settings/equity" variant="h4">
          visit the Settings
        </AssembleLink>
        area or contact your compensation manager.
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}

export function NoFeatureAccess({
  feature,
}: {
  feature?: string;
}): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-org-access">
      <EmptyStateTypography>
        We're sorry, it appears you don't have access to{" "}
        {feature ?? "this area"}.
      </EmptyStateTypography>
      <EmptyStateTypography>
        If you feel this is an error, please contact your compensation manager
        or {<ContactUs />}.
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}

export function NoOrgAccess(): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-org-access">
      <EmptyStateTypography>
        We're sorry, it appears you don't have access to this organization.
      </EmptyStateTypography>
      <EmptyStateTypography>
        If you feel this is an error, please contact your compensation manager
        or {<ContactUs />} .
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}

export function NoCompPhilosophy(): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-org-access">
      <EmptyStateTypography>
        Your organization hasn't published its compensation philosophy yet.
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}

export function NoOffersCreated({
  inTableContainer = false,
}: {
  inTableContainer?: boolean;
}): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-offers-created">
      <EmptyStateTypography>
        {inTableContainer ? (
          <>There are no Offers to view.</>
        ) : (
          <>
            You haven't created any Offers yet.
            {<br />}
            To get started, click the button above.
          </>
        )}
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}

export function NoCompStructure(): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-org-access">
      <EmptyStateTypography>
        Your organization hasn't set up any band points yet. Please{" "}
        {<ContactUs />} to set them up.
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}

export function CompCycleNoCycles(): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-comp-cycles">
      <EmptyStateTypography>
        We couldn't find a Comp Cycle here.
        <br />
        If you believe something else is wrong, please {<ContactUs />}.
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}
export function CompCycleNoPhases(): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-sequential-approvals">
      <EmptyStateTypography>
        This comp cycle was not set up with approval phases.
        <br />
        If you believe something else is wrong, please {<ContactUs />}.
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}

export function NoEmployeeFound(): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-employee-found">
      <EmptyStateTypography>
        This account isn't linked to an employee in your HR system.
      </EmptyStateTypography>
      <EmptyStateTypography>
        If you feel this is an error, please contact your compensation manager
        or {<ContactUs />} .
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}

export function NoTableData(): JSX.Element {
  return (
    <EmptyTableView
      message="We can’t find any results that match your filter."
      svg={<NoDataSvg />}
      width="30%"
    />
  );
}

export function NoPhaseReviewerData({
  message,
}: {
  message?: string;
}): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-data" compact>
      <EmptyStateTypography variant="productLongForm">
        {message
          ? `We can't find anyone who ${message}.`
          : "We can't find anyone."}
      </EmptyStateTypography>
    </FullPageEmptyState>
  );
}

export function NoSearchResults({
  noSearchCopy,
}: {
  noSearchCopy?: React.ReactNode;
}): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-data" compact>
      <EmptyStateTypography variant="productLongForm">
        We can't find any results that match your search criteria.
      </EmptyStateTypography>
      {noSearchCopy !== undefined && (
        // make sure the copy matches roughly the width of the
        // copy from above
        <div style={{ width: "500px" }}>
          <EmptyStateTypography variant="productLongForm">
            {noSearchCopy}
          </EmptyStateTypography>
        </div>
      )}
    </FullPageEmptyState>
  );
}

export function NoOrgHierarchy({
  missingCopy,
  content,
}: {
  missingCopy: string;
  content?: React.ReactNode;
}): JSX.Element {
  return (
    <FullPageEmptyState illustration="no-org-hierarchy" compact>
      <div style={{ width: "500px" }}>
        <EmptyStateTypography variant="productLongForm">
          We're having a hard time building your org's hierarchy due to some
          missing information from your HRIS related to the following Employee
          IDs: {missingCopy}. Please contact Customer Support for additional
          information.
        </EmptyStateTypography>
        {content}
      </div>
    </FullPageEmptyState>
  );
}

export function EmptyEmployeeDetailView({
  url,
  errorMessage,
}: {
  url: string;
  errorMessage?: string;
}): JSX.Element {
  return (
    <Box mt={12}>
      <EmptyTableView
        svg={<NoOrgAccessSvg />}
        message={
          <>
            {errorMessage !== undefined
              ? `Sorry, an error ocurred and we can't load this person's details for you. \n Error: ${errorMessage}`
              : "Sorry, we can't load this person's details for you."}
            <br />
            This person may not exist in your organization, or you may not have
            access to view their information.
            <AssembleLink to={url}>Go Back</AssembleLink>
          </>
        }
        width="30rem"
      />
    </Box>
  );
}
