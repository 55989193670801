import { simpleNumSort } from "@asmbl/shared/sort";
import { mapMaybe } from "@asmbl/shared/utils";
import {
  ProgressTable_phase as Phase,
  ProgressTable_phaseAssignment as PhaseAssignment,
} from "src/__generated__/graphql";
import { ColumnCell, ReviewerMap } from "./Context/ProgressPageContext";

type ReviewerCellInfo = Map<
  number,
  {
    rowIndex: number | null;
    // note: since we sort in reverse, colIndex === 0 corresponds to last phase column
    columnIndex: number | null | undefined;
  }
>;

export const getOrderedColumns = (
  reviewerMap: ReviewerMap,
  phases: Phase[]
): Map<number, ColumnCell[][]> => {
  const tableColumnsMap: Map<number, ColumnCell[][]> = new Map();
  const reviewersByOrgHeirarchyLevel = new Map<number, number>();
  const reviewerCellInfo: ReviewerCellInfo = new Map();

  phases.forEach((phase) => {
    // filter null assignments and calculate hierarchy level in cycle
    const phaseAssignments = mapMaybe(
      phase.compCyclePhaseAssignments,
      (phaseAssignment) => {
        if (phaseAssignment?.employee.id) {
          // add the reviewer info to both maps
          reviewersByOrgHeirarchyLevel.set(
            phaseAssignment.employee.id,
            phaseAssignment.employee.managerIds?.filter((mgr) =>
              reviewerMap.get(mgr)
            ).length ?? 0
          );
          reviewerCellInfo.set(phaseAssignment.employee.id, {
            columnIndex: null,
            rowIndex: null,
          });
        }
        return phaseAssignment?.employee ? phaseAssignment : null;
      }
    );
    // set the phase column cell orders for each phase
    tableColumnsMap.set(
      phase.phaseOrder,
      getOrderedPhaseColumns(
        phaseAssignments,
        reviewersByOrgHeirarchyLevel,
        reviewerMap
      )
    );
  });
  Array.from(tableColumnsMap.entries()).forEach(([phaseOrder, columns]) => {
    // sort all of the phase columns in reverse so we can traverse the aggregated cell info and determine the order of the rows
    sortColumns(columns.toReversed(), reviewerCellInfo);

    // update the row spans for each cell in the columns
    // now that we know the column and cell order
    const updateColumns = columns.map((col) =>
      col.map((cell) => {
        return {
          ...cell,
          rowSpan: setCellRowSpan(cell, reviewerMap),
        };
      })
    );

    tableColumnsMap.set(phaseOrder, updateColumns);
  });

  return tableColumnsMap;
};

export const getOrderedPhaseColumns = (
  phaseAssignments: PhaseAssignment[],
  hierarchyLevelMap: Map<number, number>,
  reviewerMap: ReviewerMap
): ColumnCell[][] => {
  // levels denote depth in org chain, so sort desc to create
  // the number of columns needed for each phase to follow the org chain
  const managementLevels = new Set(hierarchyLevelMap.values());
  const tableColumns = new Map<number, ColumnCell[]>(
    [...managementLevels.keys()]
      .sort((a, b) => simpleNumSort(a, b, "desc"))
      .map((level) => [level, []])
  );

  // populate columns with reviewers
  phaseAssignments.forEach((assignment) => {
    const phaseReviewerCount =
      hierarchyLevelMap.get(assignment.employee.id) ?? 0;
    const currentReviewers = tableColumns.get(phaseReviewerCount) ?? [];
    const reviewer = reviewerMap.get(assignment.employee.id);

    if (reviewer) {
      tableColumns.set(phaseReviewerCount, [...currentReviewers, reviewer]);
    }
  });

  // columns are now populated with reviewers
  return Array.from(tableColumns.values()).filter((col) => col.length > 0);
};

function setReviewerDetails(
  assignment: PhaseAssignment,
  currentColumnIndex: number,
  reviewerCellInfo: ReviewerCellInfo,
  rowIndex: number
) {
  if (!assignment.employee.managerIds) {
    return;
  }

  const empCellInfo = reviewerCellInfo.get(assignment.employee.id);

  reviewerCellInfo.set(assignment.employee.id, {
    columnIndex: empCellInfo?.columnIndex ?? currentColumnIndex,
    rowIndex:
      empCellInfo?.rowIndex && empCellInfo.rowIndex !== -1
        ? empCellInfo.rowIndex
        : rowIndex,
  });
}

function sortColumns(
  reviewerColumns: ColumnCell[][],
  reviewerCellInfo: ReviewerCellInfo
) {
  // for each group of reversed phase columns
  reviewerColumns.forEach((columns, index) => {
    // sort the eventually last column by total reports count
    if (index === 0) {
      reviewerColumns[index].sort((a, b) => {
        return simpleNumSort(
          a.employee.totalReportsCount,
          b.employee.totalReportsCount,
          "desc"
        );
      });
    }

    // for each column in the phase
    reviewerColumns[index].sort((a, b) => {
      // get the reviewer info for each employee
      const aReviewer = reviewerCellInfo.get(a.employee.id);
      const bReviewer = reviewerCellInfo.get(b.employee.id);

      // sort by totalReportsCount if their info is somehow missing
      if (!aReviewer || !bReviewer) {
        return simpleNumSort(
          a.employee.totalReportsCount,
          b.employee.totalReportsCount
        );
      }

      // get the employees' respective manager indexes who are reviewing next
      const aMgrIndex = a.employee.managerIds
        ? a.employee.managerIds.find((mgrId) => reviewerCellInfo.has(mgrId))
        : null;
      const bMgrIndex = b.employee.managerIds
        ? b.employee.managerIds.find((mgrId) => reviewerCellInfo.has(mgrId))
        : null;

      // if the two share the next reviewer, sort by their total reports count
      const aMgrCell = aMgrIndex ? reviewerCellInfo.get(aMgrIndex) : null;
      const bMgrCell = bMgrIndex ? reviewerCellInfo.get(bMgrIndex) : null;
      if (aMgrIndex === bMgrIndex) {
        return simpleNumSort(
          a.employee.totalReportsCount,
          b.employee.totalReportsCount,
          "desc"
        );
      }

      // otherwise sort by the next reviewer's row index
      return simpleNumSort(aMgrCell?.rowIndex, bMgrCell?.rowIndex);
    });
    // set the reviewer details after sorting the column,
    // so that the next column has most up-to-date cell info
    columns.forEach((assignment, rowIndex) =>
      setReviewerDetails(assignment, index, reviewerCellInfo, rowIndex)
    );
  });
}

function setCellRowSpan(assignment: PhaseAssignment, reviewerMap: ReviewerMap) {
  const rowSpans =
    assignment.employee.directReportIds?.reduce((prevTotal, currId) => {
      const cycleReviewer = reviewerMap.get(currId);

      if (!cycleReviewer) {
        return prevTotal;
      }
      return (
        prevTotal + (cycleReviewer.rowSpan === 0 ? 1 : cycleReviewer.rowSpan)
      );
    }, 0) ?? 1;

  const cycleReviewer = reviewerMap.get(assignment.employee.id);
  // we need to update the map as well so we accumulate the spans properly
  if (cycleReviewer) {
    reviewerMap.set(assignment.employee.id, {
      ...cycleReviewer,
      rowSpan: rowSpans,
    });
  }
  return rowSpans === 0 ? 1 : rowSpans;
}
