import { gql } from "@apollo/client";
import { CondensedTableEquityValueCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getEquityGrantValue } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableEquityValueCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { selectedCurrency } = useTableData();
  const { defaultCurrencyCode } = useCurrencies();

  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        getEquityGrantValue(
          recItems,
          selectedCurrency === "all" ? defaultCurrencyCode : selectedCurrency
        )
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableEquityValueCell2.fragments = {
  participant: gql`
    fragment CondensedTableEquityValueCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableEquityValueCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.EQUITY_VALUE
);
CondensedTableEquityValueCell2.id = ColumnIds.EQUITY_VALUE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableEquityValueCell2,
  Header: CondensedTableEquityValueCell2.Header,
  id: CondensedTableEquityValueCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableEquityValueCell2.column = column;
