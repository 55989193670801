import { KeysOfUnion } from "./types";

export type RuleSet = Rule[];

export type Rule = Condition[];

export type Condition =
  | { startDateCutOff: Date | null }
  | { departmentIds: number[] }
  | { ladderIds: number[] }
  | { levels: number[] }
  | { perfRatings: string[] }
  | { alwaysIncludeIds: number[] }
  | { alwaysExcludeIds: number[] }
  | { lastCompChange: Date | null }
  | { locationGroupIds: number[] }
  | { marketIds: number[] };

/**
 * Sometimes it's easier to treat a `Rule` as a single object as opposed to a
 * list of Conditions, so we have this type.
 */
export type CollapsedRule = {
  startDateCutOff?: Date | null;
  departmentIds?: number[];
  ladderIds?: number[];
  levels?: number[];
  perfRatings?: string[];
  alwaysIncludeIds?: number[];
  alwaysExcludeIds?: number[];
  lastCompChange?: Date | null;
  locationGroupIds?: number[];
  marketIds?: number[];
};

// An EmailRule is a Rule that only contains these fields
export type EmailRule = (
  | { alwaysIncludeIds: number[] }
  | { alwaysExcludeIds: number[] }
)[];

const fields: KeysOfUnion<Condition>[] = [
  "startDateCutOff",
  "departmentIds",
  "ladderIds",
  "levels",
  "perfRatings",
  "alwaysIncludeIds",
  "alwaysExcludeIds",
  "lastCompChange",
  "locationGroupIds",
  "marketIds",
];

export function validateEligibility(
  rules: unknown[][] | null | undefined
): RuleSet {
  if (rules == null) return [];

  const validFields = new Set<string>(fields);

  const ruleSet = rules as object[][];

  const parsed = ruleSet.map((rule) =>
    rule.map((condition) => {
      const keys = [...Object.keys(condition)];
      if (!keys.every((key) => validFields.has(key))) {
        throw "Eligibility is improperly formatted";
      }
      return maybeParseDate(condition);
    })
  );
  return parsed;
}

/**
 * When you JSON.stringify a Date object, and then JSON.parse that string,
 * the date stays a string. This will convert it back to the correct type.
 */
function maybeParseDate(condition: object): Condition {
  if (!("startDateCutOff" in condition)) return condition as Condition;

  const castedRule = condition as { startDateCutOff: string | null };

  const date =
    castedRule.startDateCutOff === null
      ? null
      : new Date(castedRule.startDateCutOff);

  return { startDateCutOff: date };
}

/**
 * Why does this function exist?
 * @see CollapsedRule
 */
export function collapseRule(rule: Rule): CollapsedRule {
  let object: CollapsedRule = {};
  rule.forEach((condition) => (object = { ...object, ...condition }));
  return object;
}

export function isConditionEmpty(condition: Condition): boolean {
  return Object.values(condition).every((v) =>
    Array.isArray(v) ? v.length === 0 : v == null
  );
}

export function isEmailRule(rule: Rule): rule is EmailRule {
  return rule.some(
    (condition) =>
      "alwaysIncludeIds" in condition || "alwaysExcludeIds" in condition
  );
}
