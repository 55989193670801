import { gql } from "@apollo/client";
import { getPercentChangeBonusToSalary } from "@asmbl/shared/comp-recommendation";
import { getSalaryCashComp } from "@asmbl/shared/compensation";
import { CondensedTableBonusPercentCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableBonusPercentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { currencies } = useCurrencies();
  const currentPay = getSalaryCashComp(employee.subject.activeCashCompensation);

  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  return (
    <AssembleTruncatedTypography align="right">
      {getPercentChangeBonusToSalary(recItems, currentPay, currencies)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableBonusPercentCell2.fragments = {
  participant: gql`
    fragment CondensedTableBonusPercentCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          annualCashEquivalent
          hourlyCashEquivalent
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableBonusPercentCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.BONUS_PERCENT
);
CondensedTableBonusPercentCell2.id = ColumnIds.BONUS_PERCENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableBonusPercentCell2,
  Header: CondensedTableBonusPercentCell2.Header,
  id: CondensedTableBonusPercentCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableBonusPercentCell2.column = column;
