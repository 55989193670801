import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Noun } from "@asmbl/shared/permissions";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { memo } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../components/Auth/AuthContext";
import { useFeatureFlags } from "../../../components/FeatureContext";
import { LinkTab } from "../../../components/Layout/LinkTab";
import { GRAY_1, GRAY_4 } from "../../../theme";

type Props = {
  compCycleId: number;
  hideTabs: boolean;
  hasPhases: boolean;
};

export type LinkTabProps = {
  title: string;
  route: string;
  hidden?: boolean;
};

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  tabBar: {
    display: "flex",
    flexGrow: 1,
    minHeight: theme.spacing(4.4),
  },
  tabsContainer: {
    display: "flex",
    gap: theme.spacing(3),
  },
  tab: {
    color: GRAY_4,
    textDecoration: "none",

    "&:hover": {
      color: GRAY_1,
      opacity: 1,
    },
  },
  activeTab: {
    color: GRAY_1,
  },
  tabHeaderRoot: {
    padding: "0px",
  },
  disabledLink: {
    pointerEvents: "none",
  },
}));

export enum CompCycleTabs {
  Home = "home",
  AllocateBudgets = "budget",
  CompChanges = "recommendations",
  Progress = "progress",
  Compare = "compare",
  CompRequests = "requests",
  PayEquityAnalysis = "pay-equity",
  PerformanceAnalysis = "performance",
  BonusAnalysis = "bonus",
  CompaRatioAnalysis = "compa-ratio",
}

export const NavigationTabBar = memo(function NavigationTabBar({
  compCycleId,
  hideTabs,
  hasPhases,
}: Props): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const { permissions } = useAuth();
  const featureFlags = useFeatureFlags();

  const isFullAccess = permissions.canEditGlobal(Noun.CompRecommendation);

  const isManagerOfManagers = permissions.isManagerOfManagers();

  const match =
    Object.values(CompCycleTabs).find((value) =>
      location.pathname.includes(value as string)
    ) ?? CompCycleTabs.Home;

  const dashboardAccess =
    (isFullAccess && featureFlags.isEnabled(FeatureFlag.CompCycleDashboard)) ||
    featureFlags.isEnabled(FeatureFlag.IndividualAccessToCompCycleDashboard);
  return (
    <div className={classes.root}>
      <Tabs
        value={match}
        className={classes.tabBar}
        classes={{ flexContainer: classes.tabsContainer }}
      >
        {isFullAccess && (
          <Tab
            component={LinkTab}
            route={{
              pathname: `/comp-cycles/${compCycleId}`,
              search: location.search,
            }}
            title="Home"
            classes={{
              selected: classes.activeTab,
              textColorInherit: classes.tab,
            }}
            value={CompCycleTabs.Home}
          />
        )}
        <Tab
          component={LinkTab}
          route={{
            pathname: `/comp-cycles/${compCycleId}/${CompCycleTabs.CompRequests}`,
            search: location.search,
          }}
          title="Plan"
          classes={{
            selected: classes.activeTab,
            textColorInherit: classes.tab,
          }}
          value={CompCycleTabs.CompRequests}
        />
        {featureFlags.isEnabled(FeatureFlag.CompCycleProgressPage) &&
          isFullAccess &&
          hasPhases && (
            <Tab
              component={LinkTab}
              route={{
                pathname: `/comp-cycles/${compCycleId}/${CompCycleTabs.Progress}`,
                search: location.search,
              }}
              title="Progress"
              classes={{
                selected: classes.activeTab,
                textColorInherit: classes.tab,
              }}
              value={CompCycleTabs.Progress}
              hidden={hideTabs}
            />
          )}
        {isFullAccess && (
          <Tab
            component={LinkTab}
            route={{
              pathname: `/comp-cycles/${compCycleId}/${CompCycleTabs.AllocateBudgets}`,
              search: location.search,
            }}
            title="Allocate Budgets"
            classes={{
              selected: classes.activeTab,
              textColorInherit: classes.tab,
            }}
            value={CompCycleTabs.AllocateBudgets}
            hidden={hideTabs}
          />
        )}
        {(isFullAccess || isManagerOfManagers) && (
          <Tab
            component={LinkTab}
            route={{
              pathname: `/comp-cycles/${compCycleId}/${CompCycleTabs.Compare}`,
              search: location.search,
            }}
            title="View Budgets vs. Requests"
            classes={{
              selected: classes.activeTab,
              textColorInherit: classes.tab,
            }}
            value={CompCycleTabs.Compare}
            hidden={hideTabs}
          />
        )}
        <Tab
          component={LinkTab}
          route={{
            pathname: `/comp-cycles/${compCycleId}/analysis/${CompCycleTabs.PayEquityAnalysis}`,
            search: location.search,
          }}
          title="Pay Equity Analysis"
          classes={{
            selected: classes.activeTab,
            textColorInherit: classes.tab,
          }}
          value={CompCycleTabs.PayEquityAnalysis}
          hidden={featureFlags.loading || !dashboardAccess}
        />
        <Tab
          component={LinkTab}
          route={{
            pathname: `/comp-cycles/${compCycleId}/analysis/${CompCycleTabs.PerformanceAnalysis}`,
            search: location.search,
          }}
          title="Performance Analysis"
          classes={{
            selected: classes.activeTab,
            textColorInherit: classes.tab,
          }}
          value={CompCycleTabs.PerformanceAnalysis}
          hidden={featureFlags.loading || !dashboardAccess}
        />
        <Tab
          component={LinkTab}
          route={{
            pathname: `/comp-cycles/${compCycleId}/analysis/${CompCycleTabs.BonusAnalysis}`,
            search: location.search,
          }}
          title="Bonus Analysis"
          classes={{
            selected: classes.activeTab,
            textColorInherit: classes.tab,
          }}
          value={CompCycleTabs.BonusAnalysis}
          hidden={
            !isFullAccess ||
            featureFlags.loading ||
            !featureFlags.isEnabled(FeatureFlag.CompCycleDashboard) ||
            !featureFlags.isEnabled(FeatureFlag.CompCycleBonusDashboard)
          }
        />
        <Tab
          component={LinkTab}
          route={{
            pathname: `/comp-cycles/${compCycleId}/analysis/${CompCycleTabs.CompaRatioAnalysis}`,
            search: location.search,
          }}
          title="Compa-Ratio Analysis"
          classes={{
            selected: classes.activeTab,
            textColorInherit: classes.tab,
          }}
          value={CompCycleTabs.CompaRatioAnalysis}
          hidden={
            !isFullAccess ||
            featureFlags.loading ||
            !featureFlags.isEnabled(FeatureFlag.CompCycleDashboard) ||
            !featureFlags.isEnabled(FeatureFlag.CompCycleCompaRatioDashboard)
          }
        />
      </Tabs>
    </div>
  );
});
