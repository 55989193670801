import { gql, useQuery } from "@apollo/client";
import { GetReportingTier } from "../../../../__generated__/graphql";
import { CompCycleGrouping } from "../../../../components/CompCycle/types";
import { AllocationUnit } from "../../../../models/Budget";
import { DraftBudgetInput } from "../../../../mutations/CompCycleBudget";
import { IndentationCells } from "./Connectors";
import { ReportingTier } from "./ReportingTier";
import { SkeletonRow } from "./SkeletonRow";

type Props = {
  compCycleId: number;
  employeeId: number;
  compComponent: CompCycleGrouping;
  equityDisplay: AllocationUnit;
  subComponentCount: number;
  onSaveDraft: (
    employeeId: number | null,
    budget: DraftBudgetInput
  ) => Promise<unknown>;
  indentation: IndentationCells;
};

export function LazyReportingTier({
  compCycleId,
  employeeId,
  compComponent,
  equityDisplay,
  subComponentCount,
  onSaveDraft,
  indentation,
}: Props): JSX.Element {
  const { data, loading } = useQuery<GetReportingTier>(
    LazyReportingTier.query(),
    { variables: { compCycleId, employeeId }, pollInterval: 10_000 }
  );
  if (!data) {
    // subComponentCount + 1 because the subComponentCount does not include the
    // total column
    return loading ? (
      <SkeletonRow
        subComponents={subComponentCount + 1}
        indentation={indentation}
      />
    ) : (
      <tr>
        <td>Error</td>
      </tr>
    );
  }

  return (
    <ReportingTier
      budgetDraft={data.compCycleBudgetDraft}
      budget={data.compCycleBudget}
      onSaveDraft={onSaveDraft}
      indentation={indentation}
      compComponent={compComponent}
      equityDisplay={equityDisplay}
    />
  );
}

LazyReportingTier.query = () => gql`
  ${ReportingTier.fragments.compCycleBudgetDraft}
  ${ReportingTier.fragments.compCycleBudget}

  query GetReportingTier($compCycleId: Int!, $employeeId: Int!) {
    compCycleBudgetDraft(compCycleId: $compCycleId, employeeId: $employeeId) {
      compCycleId
      employeeId
      ...ReportingTier_compCycleBudgetDraft
    }
    compCycleBudget(compCycleId: $compCycleId, employeeId: $employeeId) {
      compCycleId
      employeeId
      ...ReportingTier_compCycleBudget
    }
  }
`;
