/**
 *
 * @param list the list of items to englishify
 * @param join the string to join the list with
 * @param compareFn the function to use to compare the items for sorting
 * @returns a sorted englishified list
 *
 * @example
 * englishifyList(["apple", "banana", "cherry"], ", ",
 *  (a, b) => a.localeCompare(b))
 *
 * // returns "apple, banana, and cherry"
 *
 * @example
 * englishifyList(["apple", "banana"], ", ",
 *  (a, b) => a.localeCompare(b))
 *
 * // returns "apple and banana"
 */

export function englishifyList(
  list: string[],
  join = ", ",
  compareFn?: ((a: string, b: string) => number) | undefined
): string {
  return list
    .join(join)
    .split(join)
    .sort(compareFn)
    .map((item, index, arr) => {
      if (arr.length > 1 && index === arr.length - 1) {
        return `and ${item}`;
      }

      return list.length > 2 ? `${item}, ` : `${item} `;
    })
    .join("")
    .trim();
}
