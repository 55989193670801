import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { PeopleIcon } from "src/components/AssembleIcons/Navigation/PeopleIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { DV_ORANGE, PURPLE_2 } from "src/theme";
import { useMultipleMeritMatrices } from "../useMultipleMatrices";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  unassignedButton: {
    "&:hover": {
      border: `1px solid ${DV_ORANGE}`,
    },

    "&:focus, &:active": {
      border: `1px solid ${DV_ORANGE}`,
      boxShadow: `0px 0px 0px 3px ${DV_ORANGE}33`,
    },
  },
}));

type Props = {
  handleEligiblePeopleModalOpen: () => void;
  handleUnassignedPeopleModalOpen: () => void;
};

export function ButtonBar({
  handleEligiblePeopleModalOpen,
  handleUnassignedPeopleModalOpen,
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    allEligible,
    matrices: { meritMatrices },
  } = useMultipleMeritMatrices();

  const totalEligible = allEligible.length;
  const totalAssigned = meritMatrices.reduce(
    (acc, matrix) => acc + (matrix.eligibleParticipants?.length ?? 0),
    0
  );

  const totalUnassigned =
    totalEligible - totalAssigned < 0 ? 0 : totalEligible - totalAssigned;

  return (
    <div className={classes.root}>
      <AssembleButton
        size="small"
        variant="text"
        startIcon={<PeopleIcon color={PURPLE_2} />}
        label={`Total Eligible (${totalEligible})`}
        onClick={handleEligiblePeopleModalOpen}
      />
      <AssembleButton
        className={classes.unassignedButton}
        color="inherit"
        size="small"
        variant="text"
        startIcon={<PeopleIcon color={DV_ORANGE} />}
        onClick={handleUnassignedPeopleModalOpen}
        label={
          <AssembleTypography
            variant="productButtonSmall"
            textColor={DV_ORANGE}
          >
            Unassigned People ({totalUnassigned})
          </AssembleTypography>
        }
      />
    </div>
  );
}

ButtonBar.fragments = {
  compCycle: gql`
    fragment ButtonBar_compCycle on CompCycle2 {
      id
      participants {
        participants {
          subjectId
          subject {
            id
            displayName
            activeEmployment {
              id
              jobTitle
            }
          }
        }
      }
    }
  `,
};
