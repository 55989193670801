import { gql, useQuery } from "@apollo/client";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { MultipleMeritMatricesLoadingBoundaryQuery } from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import Section from "src/components/Workflow/Section";
import { MultipleMeritMatrices } from "./MultipleMeritMatrices";
import { MultipleMeritMatricesProvider } from "./useMultipleMatrices";
import { groupMatrices } from "./utils";

const useStyles = makeStyles(() => ({
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

type Props = { compCycleId: number };

export function MultipleMeritMatricesLoadingBoundary({
  compCycleId,
}: Props): JSX.Element {
  const classes = useStyles();
  const { data, loading } = useQuery<MultipleMeritMatricesLoadingBoundaryQuery>(
    MultipleMeritMatricesLoadingBoundary.query,
    { variables: { compCycleId } }
  );

  if (data == null || data.compCycle2 == null)
    return loading ? (
      <Section active header="" description>
        <div className={classes.centered}>
          <CircularProgress />
        </div>
      </Section>
    ) : (
      <ErrorView text="Something went wrong. Please refresh the page to try again" />
    );

  return (
    <MultipleMeritMatricesProvider
      initValue={{
        allEligible: data.compCycle2.participants.participants,
        meritMatrices:
          data.compCycle2.matrices.length === 0
            ? undefined
            : groupMatrices(data.compCycle2.matrices),
        shouldApplyGuidance: data.compCycle2.recommendationsPreFill,
      }}
      compCycleId={compCycleId}
    >
      <MultipleMeritMatrices
        compCycle={data.compCycle2}
        organization={data.organization}
      />
    </MultipleMeritMatricesProvider>
  );
}

MultipleMeritMatricesLoadingBoundary.query = gql`
  ${MultipleMeritMatrices.fragments.organization}
  ${MultipleMeritMatrices.fragments.compCycle}
  ${MultipleMeritMatricesProvider.fragments.compCycle}
  ${groupMatrices.fragments.matrix}
  query MultipleMeritMatricesLoadingBoundaryQuery($compCycleId: Int!) {
    organization {
      id
      ...MultipleMeritMatrices_organization
    }
    compCycle2(id: $compCycleId) {
      id
      matrices {
        id
        ...groupMatrices_matrix
      }
      ...MultipleMeritMatrices_compCycle
      ...useMultipleMatrices_compCycle
    }
  }
`;
