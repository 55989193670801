import { gql } from "@apollo/client";
import { Box, makeStyles, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { useTrack } from "../../../../analytics";
import { AssembleLink } from "../../../../components/AssembleLink";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { UserAvatar } from "../../../../components/UserAvatar";
import { GRAY_6, WHITE } from "../../../../theme";
import { IndentationCells, NameCellConnectors } from "./Connectors";
import { RowIcon } from "./RowIcon";

const useStyles = makeStyles((theme) => ({
  flexRowContainer: {
    display: "flex",
    flexDirection: "row",
    height: "57px", // Hardcode this to be 1px taller because for some reason the row adds 1px height otherwise
  },
  flexCenterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    background: WHITE,
    flexGrow: 1,
    borderBottom: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
    borderLeft: `1px solid ${GRAY_6}`,
    paddingRight: theme.spacing(2),
  },
  AssembleTruncatedTypography: {
    lineHeight: 1.55,
  },
  managerOfManager: {
    fontWeight: "bold",
  },
}));

type Props = {
  compCycleId: number;
  employee: {
    id: number;
    displayName: string;
    user: {
      id: number;
      photoURL: string | null;
    } | null;
    activeEmployment: {
      id: number;
      jobTitle: string | null;
    } | null;
  };
  indentation: IndentationCells;
  expanded: boolean;
  isLastRowCell: boolean;
  setExpanded: (expanded: boolean) => void;
  expandable?: boolean;
  type?: "budget" | "compare";
};

export function BudgetNameCell({
  compCycleId,
  employee,
  indentation,
  expandable,
  expanded,
  isLastRowCell,
  setExpanded,
  type,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();

  const trackLink = () =>
    trackEvent({
      object: "Manager Row Name",
      action: "Clicked",
      managerId: employee.id,
    });

  return (
    <TableCell padding="none" style={{ border: "none" }}>
      <div className={classes.flexRowContainer}>
        {indentation.length > 0 && (
          <NameCellConnectors
            indentation={indentation}
            isLastRowCell={isLastRowCell}
            expanded={expanded}
            employeeName={employee.displayName}
          />
        )}
        <div className={classes.flexCenterContainer}>
          <RowIcon
            expandable={expandable}
            expanded={expanded}
            setExpanded={setExpanded}
          />
          <Box ml={0.5} mr={2}>
            <UserAvatar
              photoURL={employee.user?.photoURL}
              displayName={employee.displayName}
            />
          </Box>
          <Box my={-0.5}>
            {expandable !== undefined && expandable ? (
              <AssembleLink
                to={
                  `/comp-cycles/${compCycleId}/` +
                  `${type ? type : "budget"}?manager=${employee.id}`
                }
                newTab={true}
                whitespace={false}
                color="primary"
                underline="hover"
                onClick={trackLink}
              >
                <AssembleTruncatedTypography
                  className={clsx(
                    classes.AssembleTruncatedTypography,
                    classes.managerOfManager
                  )}
                >
                  {employee.displayName}
                </AssembleTruncatedTypography>
              </AssembleLink>
            ) : (
              <div>
                <AssembleTruncatedTypography
                  className={classes.AssembleTruncatedTypography}
                >
                  {employee.displayName}
                </AssembleTruncatedTypography>
              </div>
            )}
            <AssembleTruncatedTypography>
              {employee.activeEmployment?.jobTitle ?? "-"}
            </AssembleTruncatedTypography>
          </Box>
        </div>
      </div>
    </TableCell>
  );
}

BudgetNameCell.fragments = {
  employee: gql`
    fragment BudgetNameCell_employee on MinimalEmployee {
      id
      displayName
      user {
        id
        photoURL
      }
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
};
