import { gql } from "@apollo/client";
import { getFormattedDate } from "@asmbl/shared/time";
import {
  ColumnIds,
  ColumnIdsToHeaders,
} from "src/pages/CompCycle/Plan/Contexts/ColumnOrderContext";
import { CondensedTableLastSalaryChangeDateCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableLastSalaryChangeDateCell2({
  row: { original: employee },
}: Props): JSX.Element {
  return (
    <AssembleTruncatedTypography lines={2} align="right">
      {employee.subject.lastCompChangeDate !== null
        ? getFormattedDate(employee.subject.lastCompChangeDate)
        : "-"}
    </AssembleTruncatedTypography>
  );
}

CondensedTableLastSalaryChangeDateCell2.fragments = {
  participant: gql`
    fragment CondensedTableLastSalaryChangeDateCell2_participant on CompCycleParticipant {
      subject {
        id
        lastCompChangeDate
      }
    }
  `,
};

CondensedTableLastSalaryChangeDateCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.LAST_SALARY_CHANGE_DATE
);
CondensedTableLastSalaryChangeDateCell2.id = ColumnIds.LAST_SALARY_CHANGE_DATE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableLastSalaryChangeDateCell2,
  Header: CondensedTableLastSalaryChangeDateCell2.Header,
  id: CondensedTableLastSalaryChangeDateCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableLastSalaryChangeDateCell2.column = column;
