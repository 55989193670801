export type Comparator<T> = (a: T, b: T) => number;
export type ComparatorWithOrder<T> = (
  a: T,
  b: T,
  order: "asc" | "desc"
) => number;

/**
 * Compares two strings alphabetically ignoring case.
 */
export function caseInsensitiveComparator(a: string, b: string): number {
  return a.localeCompare(b, undefined, { sensitivity: "base" });
}

export function nativeComparator<T>(a: T, b: T): number {
  if (b < a) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
}

/**
 * Compares strings ignoring case. Compares other objects naively.
 */
export function basicComparator<T>(a: T, b: T): number {
  if (typeof a === "string" && typeof b === "string") {
    return caseInsensitiveComparator(a, b);
  }
  return nativeComparator(a, b);
}

type BasicSortable = string | number | Date | boolean;
export function contramap<A>(fn: (a: A) => BasicSortable): Comparator<A>;
export function contramap<A, Z>(
  fn: (a: A) => Z,
  zComparator: Comparator<Z>
): Comparator<A>;

/**
 * Creates a comparator using an attribute accessor function and an optional comparator.
 * Example: `departments.sort(contramap((department) => department.name));`
 * @param fn A function that returns a comparable attribute.
 * @param zComparator An optional comparator to use. If you don't pass this in,
 *        your `fn` MUST return a type that is natively sortable.
 */
export function contramap<A, Z>(
  fn: (a: A) => Z,
  zComparator: Comparator<Z> = basicComparator
): Comparator<A> {
  return (a: A, b: A) => zComparator(fn(a), fn(b));
}
export function contramapWithOrder<A, Z>(
  fn: (a: A) => Z,
  zComparator: ComparatorWithOrder<Z> = basicComparator
): ComparatorWithOrder<A> {
  return (a: A, b: A, order) => zComparator(fn(a), fn(b), order);
}

export function byDate<A>(fn: (a: A) => Date | string): Comparator<A> {
  return contramap((a) => new Date(fn(a)).getTime(), nativeComparator);
}

export function byDescendingDate<A>(
  fn: (a: A) => Date | string
): Comparator<A> {
  return contramap((a) => new Date(fn(a)).getTime(), reverseNativeComparator);
}

export function reverseNativeComparator<T>(a: T, b: T): number {
  if (b > a) {
    return 1;
  }
  if (b < a) {
    return -1;
  }
  return 0;
}

export function simpleStringSort(
  aKey: string | null | undefined,
  bKey: string | null | undefined,
  sortDir?: string
) {
  if (aKey == null || aKey === "") {
    return 1; // empty string comes last
  }
  if (bKey == null || bKey === "") {
    return -1; // empty string comes last
  }

  return sortDir === "asc"
    ? aKey?.localeCompare(bKey, "en", { sensitivity: "base" })
    : bKey?.localeCompare(aKey, "en", { sensitivity: "base" });
}

export function simpleNumSort(
  aKey: number | null | undefined,
  bKey: number | null | undefined,
  sortDir?: string
) {
  if (aKey == null) {
    return 1; // null comes last
  }
  if (bKey == null) {
    return -1; // null comes last
  }
  if (sortDir === "desc") {
    return aKey > bKey ? -1 : 1;
  }
  return aKey < bKey ? -1 : 1;
}

export function booleanSort(
  aKey: boolean | null,
  bKey: boolean | null,
  sortDir?: string
) {
  if (aKey === null) {
    return 1; // null comes last
  }
  if (bKey === null) {
    return -1; // null comes last
  }
  if (sortDir === "desc") {
    return aKey < bKey ? -1 : 1;
  }

  return aKey > bKey ? -1 : 1;
}
