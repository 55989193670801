import { gql } from "@apollo/client";
import { getSalaryCashComp } from "@asmbl/shared/compensation";
import { exchangeMoney } from "@asmbl/shared/currency";
import { isZero, zero } from "@asmbl/shared/money";
import { CondensedTableSalaryPercentChangeCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getPercentChangeInSalary } from "../../../../models/CompRecommendation";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { DefaultCell } from "./DefaultCell";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryPercentChangeCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode, currencies } = useCurrencies();
  const payIncrease =
    employee.compRecommendation?.latestSubmittedPayIncrease
      .annualCashEquivalent ?? zero(defaultCurrencyCode);
  const currentSalary = getSalaryCashComp(
    employee.subject.activeCashCompensation
  );

  if (!currentSalary) {
    return <DefaultCell />;
  }

  const exchangedSalaryIncrease = exchangeMoney(
    payIncrease,
    currentSalary.annualCashEquivalent.currency,
    {
      allCurrencies: currencies,
    }
  );

  return (
    <AssembleTruncatedTypography align="right">
      {isZero(exchangedSalaryIncrease)
        ? "-"
        : getPercentChangeInSalary(
            exchangedSalaryIncrease,
            currentSalary.annualCashEquivalent
          )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableSalaryPercentChangeCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryPercentChangeCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          employeeId
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedPayIncrease(currencyCode: $currencyCode) {
          annualCashEquivalent
          hourlyCashEquivalent
          unitType
        }
      }
    }
  `,
};

CondensedTableSalaryPercentChangeCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_PERCENT_CHANGE
);
CondensedTableSalaryPercentChangeCell2.id = ColumnIds.SALARY_PERCENT_CHANGE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryPercentChangeCell2,
  Header: CondensedTableSalaryPercentChangeCell2.Header,
  id: CondensedTableSalaryPercentChangeCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableSalaryPercentChangeCell2.column = column;
